/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect } from 'react';
import { Table, Loader } from '@/components';
import {
    columnTypes,
    removeRow,
    filters,
    removeAllRows,
} from '@/components/Table/index';
import { v4 as uuidv4 } from 'uuid';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';
import { updateRowGeneral } from '@/pages/bonusRuleGeneral/common';
import { validators } from '@/utils';

const columnSpecs = {
    operatingChain: columnTypes.select('Operating chain', 'operatingChain'),
    groupCode: columnTypes.select('Group', 'groupCode'),
    linkedGroupCode: columnTypes.select('Group', 'linkedGroupCode'),
};

const createRow = (dataRow, groupCodeOptions, operatingChainOptions) => {
    const row = {
        id: dataRow.id,
        operatingChain: columnSpecs.operatingChain.cell(
            dataRow.operatingChain,
            operatingChainOptions
        ),
        groupCode: columnSpecs.groupCode.cell(
            dataRow.groupCode,
            groupCodeOptions
        ),
        linkedGroupCode: columnSpecs.linkedGroupCode.cell(
            dataRow.linkedGroupCode,
            groupCodeOptions
        ),
    };
    return row;
};

const Filter = (props) => {
    return (
        <>
            <filters.OperatingChainFilter {...props} />
        </>
    );
};

const NegativeTransactions = () => {
    const { apiFactory } = useDependencies();
    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasData, setHasData] = useState(undefined);
    const [groupCodeOptions, setGroupCodeOptions] = useState([]);
    const [operatingChainOptions, setOperatingChainOptions] = useState([]);
    const [negativeTransactions, setNegativeTransactions] = useState([]);
    const [filterSpec, setFilterSpec] = useState({ operatingChain: null });
    const { bonusSettingsApi, articleMasterdataApi } = apiFactory;

    // Get data
    useEffect(() => {
        async function getData() {
            const [negativeTransactions, groupCodes, operatingChains] =
                await Promise.all([
                    bonusSettingsApi.getNegativeTransactions(),
                    articleMasterdataApi.getGroups(),
                    bonusSettingsApi.getOperatingChains(),
                ]);
            const groupCodeOptions = groupCodes.map((gc) => ({
                label: `${gc.id} - ${gc.description}`,
                value: gc.id,
            }));
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            setGroupCodeOptions(groupCodeOptions);
            setOperatingChainOptions(operatingChainOptions);
            setNegativeTransactions(negativeTransactions.data);
            setHasData((negativeTransactions && negativeTransactions.length > 0) ? true : false);
        }
        getData();
    }, [articleMasterdataApi, bonusSettingsApi]);

    // Transform data
    useEffect(() => {
        if (negativeTransactions) {
            const tableData = negativeTransactions
                .map((d) =>
                    createRow(d, groupCodeOptions, operatingChainOptions)
                )
                .filter((obj) =>
                    operatingChainOptions.find(
                        (o) => o.value === obj.operatingChain.value
                    )
                );
            setTableData(tableData);
        }
    }, [negativeTransactions, groupCodeOptions, operatingChainOptions]);

    // Set data loaded flag
    useEffect(() => {
        if ((hasData && tableData.length > 0) || hasData === false) {
            setIsLoaded(true);
        }
    }, [hasData, tableData]);

    // Create rows
    const rows = useMemo(() => {
        const filter = filters.composeFilters([filters.operatingChain]);
        const filtered = tableData.filter(filter(filterSpec));
        return [...filtered];
    }, [filterSpec, tableData]);

    // Create columns
    const columns = useMemo(() => {
        const fixedColumns = [
            columnSpecs.operatingChain.column(),
            columnSpecs.groupCode.column(),
            columnSpecs.linkedGroupCode.column(),
        ];
        return fixedColumns;
    }, []);

    const saveData = async () => {
        const data = tableData.map((row) => ({
            id: row.id,
            groupCode: columnSpecs.groupCode.value(row),
            linkedGroupCode: columnSpecs.linkedGroupCode.value(row),
            operatingChain: columnSpecs.operatingChain.value(row),
        }));
        const requestData = {
            data: data,
            simulationId: simulationIdState.get()[0].simulationId,
        };
        await bonusSettingsApi.saveNegativeTransactions(requestData);
        const updatedNegativeTransactions =
            await bonusSettingsApi.getNegativeTransactions(true);
        setNegativeTransactions(updatedNegativeTransactions.data);
        return {
            valid: true,
        };
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData, filterSpec.operatingChain));
    };

    const updateData = (id, columnName, value) =>
        setTableData(updateRowGeneral(id, columnName, value, tableData));

    const addRow = () => {
        const newRow = {
            id: uuidv4(),
            groupCode: '',
            linkedGroupCode: '',
            operatingChain: '',
        };
        const newTableData = [
            ...tableData,
            createRow(newRow, groupCodeOptions, operatingChainOptions),
        ];
        setTableData(newTableData);
    };

    const sampleCSV = () => `OCNOELK\t431\t592
OCSEELG\t431\t482`;

    const existValidator = (message, currentData) => async (row) => {
        const operatingChain = row[0];
        const groupCode = row[1];
        const linkedGroupCode = row[2];
        const exists =
            currentData.findIndex(
                (d) =>
                    d.operatingChain.value === operatingChain &&
                    d.groupCode.value === groupCode &&
                    d.linkedGroupCode.value === linkedGroupCode
            ) >= 0;
        return {
            isValid: true,
            text: exists ? message : '',
        };
    };

    const { optionValidator, composeValidators } = validators;
    const csvRowValidator =
        (currentData, operatingChains, groupCodeOptions) => async (row) => {
            const validators = [
                optionValidator('Invalid operating chain', operatingChains, 0),
                optionValidator('Invalid group code', groupCodeOptions, 1),
                optionValidator(
                    'Invalid linked group code',
                    groupCodeOptions,
                    2
                ),
                existValidator('Rule exists', currentData),
            ];
            const validator = composeValidators(validators);
            return await validator(row);
        };

    const addCsvData = (data) => {
        const structured = data.data.map((row) => ({
            id: uuidv4(),
            operatingChain: row.items[0],
            groupCode: row.items[1],
            linkedGroupCode: row.items[2],
        }));
        const filteredData = structured.filter((row) => {
            return (
                tableData.findIndex(
                    (d) =>
                        d.operatingChain.value === row.operatingChain &&
                        d.groupCode.value === row.groupCode &&
                        d.linkedGroupCode.value === row.linkedGroupCode
                ) < 0
            );
        });
        const newRows = filteredData.map((d) =>
            createRow(d, groupCodeOptions, operatingChainOptions)
        );
        const newTableData = [...tableData, ...newRows];
        setTableData(newTableData);
    };

    const copyOpChain = (fromOpChains, toOpChain) => {
        const negativeTransactionsCopy = [...negativeTransactions];

        const opChainValues = fromOpChains.map((foc) => foc.value);

        const filteredNewNegativeTransactions = negativeTransactionsCopy.filter(
            (nt) => opChainValues.includes(nt.operatingChain)
        );

        const copiedNegativeTransactions = filteredNewNegativeTransactions.map(
            (fnt) => {
                return {
                    ...fnt,
                    id: uuidv4(),
                    operatingChain: toOpChain.value,
                };
            }
        );

        setNegativeTransactions(
            negativeTransactions.concat(copiedNegativeTransactions)
        );
    };

    return (
        <div className="flex w-full h-full flex-col">
            <div className="content w-full flex-1 flex flex-row">
                {isLoaded ? (
                    <>
                        <Table
                            data={rows}
                            onNewRow={addRow}
                            onUpdateData={updateData}
                            columns={columns}
                            onSaveData={saveData}
                            onRemoveRow={deleteRow}
                            onRemoveAllRows={deleteAllRows}
                            componentName={'ModelTypes'}
                            components={{
                                filter: (
                                    <Filter
                                        filter={filterSpec}
                                        onChange={setFilterSpec}
                                        operatingChainOptions={
                                            operatingChainOptions
                                        }
                                    />
                                ),
                            }}
                            sampleCSV={sampleCSV}
                            csvRowValidator={csvRowValidator(
                                tableData,
                                operatingChainOptions,
                                groupCodeOptions
                            )}
                            addCsvData={addCsvData}
                            showCopyOpchain={true}
                            onCopyOpchainFrom={copyOpChain}
                        />
                    </>
                ) : (
                    <div className="w-full text-center">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
};

export default NegativeTransactions;
