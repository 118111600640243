import React from 'react';
import './reactTableCustomStyles.css';
import { DateTime } from 'luxon';

const inputFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <input
            className="custom-react-table-input"
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder="Search..."
        />
    );
};

const simulationName = {
    column: () => ({
        Header: 'Name',
        accessor: 'name',
        Filter: inputFilter,
        disableFilters: true,
        sortType: (nameA, nameB) => {
            if (
                nameA.original.name.toUpperCase() <
                nameB.original.name.toUpperCase()
            ) {
                return -1;
            }
            if (
                nameA.original.name.toUpperCase() >
                nameB.original.name.toUpperCase()
            ) {
                return 1;
            }
            return 0;
        },
    }),
};

const simulationOpChains = {
    column: () => ({
        Header: 'Last Run Operating chain',
        accessor: 'opchainCodes',
        Cell: (e) => (e.value ? e.value.sort().join(', ') : null),
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const simulationPeriods = {
    column: () => ({
        Header: 'Periods',
        accessor: 'periods',
        Cell: (e) => e.value.join(', '),
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const createdBy = {
    column: () => ({
        Header: 'Created by (username)',
        accessor: 'userName',
        Filter: inputFilter,
        disableFilters: true,
    }),
};

const createdAt = {
    column: () => ({
        Header: 'Created At',
        accessor: 'dateCreate',
        Cell: (e) => DateTime.fromISO(e.value, { zone: 'utc' }).toFormat('ff'),
        Filter: inputFilter,
        disableFilters: true,
    }),
};

const lastRanBy = {
    column: () => ({
        Header: 'Last ran by (username)',
        accessor: 'lastRunUsername',
        Filter: inputFilter,
        disableFilters: true,
        disableSortBy: true,
    }),
};

const lastRanAt = {
    column: () => ({
        Header: 'Last ran at',
        accessor: 'lastRunTimestamp',
        Cell: (e) =>
            e.value !== null
                ? DateTime.fromISO(e.value, { zone: 'utc' }).toFormat('ff')
                : '',
        Filter: inputFilter,
        disableFilters: true,
    }),
};

const lastRunStatus = {
    column: () => ({
        Header: 'Last Run Status',
        accessor: 'lastRunStatus', // accessor is the "key" in the data
        Filter: inputFilter,
        disableFilters: true,
    }),
};

const availableInDW = {
    column: () => ({
        Header: 'Available in DW',
        accessor: 'statusInDW', // accessor is the "key" in the data
        Filter: inputFilter,
        disableFilters: true,
        Cell: (e) => (e.value === 1 ? 'Yes' : 'No'),
    }),
};

const buttonColumn = {
    column: (id, button, isAuthorized = false, header = null) => ({
        Header: header || ' ',
        id: id, // E.g. "edit-button-column"
        Cell: ({ row }) => {
            return isAuthorized ? button(row) : '';
        },
    }),
};

export {
    inputFilter,
    simulationName,
    simulationOpChains,
    simulationPeriods,
    createdBy,
    createdAt,
    lastRanBy,
    lastRanAt,
    lastRunStatus,
    availableInDW,
    buttonColumn,
};
