/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect } from 'react';
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { Table, buttons, Loader } from '@/components';
import {
    columnTypes,
    removeRow,
    toggleRow,
    toggleAllRows,
    removeSelected,
    getName,
    getUserName,
    filters,
} from '@/components/Table/index';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';

const columnSpecs = {
    employeeId: columnTypes.employeeId,
    username: columnTypes.username,
    name: columnTypes.name,
    pointType: columnTypes.pointType,
    value: columnTypes.employeePoints,
    fiscalKeyFrom: columnTypes.fiscalKeyFrom,
    fiscalKeyTo: columnTypes.fiscalKeyTo,
    comment: columnTypes.comment,
    operatingChain: columnTypes.input('Operating chain', 'operatingChain'),
    storeId: columnTypes.storeId,
};

const pointTypes = [
    { label: 'Amount', value: 'amount' },
    { label: 'Fixed points', value: 'fixed' },
    { label: 'Factor points %', value: 'factor' },
];

const createRow = (dataRow, employees) => {
    const splittedRows = splitRow(dataRow);

    const rows = splittedRows.map((splittedRow, i) => {
        const row = {
            id: dataRow.id + '#' + i,
            employeeId: columnSpecs.employeeId.cell(dataRow.employeeId),
            username: columnSpecs.username.cell(
                getUserName(dataRow, employees)
            ),
            name: columnSpecs.name.cell(getName(dataRow, employees)),
            storeId: columnSpecs.storeId.cell(dataRow.storeId),
            operatingChain: columnSpecs.operatingChain.cell(
                dataRow.operatingChain
            ),
            pointType: columnSpecs.pointType.cell(
                splittedRow.pointType,
                pointTypes
            ),
            value: columnSpecs.value.cell(splittedRow.value),
            fiscalKeyFrom: columnSpecs.fiscalKeyFrom.cell(
                dataRow.fiscalKeyFrom
            ),
            fiscalKeyTo: columnSpecs.fiscalKeyTo.cell(dataRow.fiscalKeyTo),
            comment: columnSpecs.comment.cell(dataRow.comment),
        };
        return row;
    });
    return rows;
};

const splitRow = (row) => {
    const values = [
        { amount: row.amount, pointType: pointTypes[0].value },
        { fixed: row.fixed, pointType: pointTypes[1].value },
        { factor: row.factor, pointType: pointTypes[2].value },
    ].filter((r) => Object.values(r)[0]);

    let rows;
    if (values.length > 0) {
        rows = values.map((r) => {
            return {
                pointType: r.pointType,
                value: r[r.pointType],
            };
        });
    } else {
        rows = [
            {
                pointType: '',
                value: 0,
            },
        ];
    }
    return rows;
};

const formatEmployeeLabel = (props) => {
    const { value, label, username } = props;
    return (
        <div className="flex flex-row">
            <div>{value} </div>
            <div className="flex flex-1 flex-col">
                <div className="ml-2">{label}</div>
                <div className="ml-2">({username})</div>
            </div>
        </div>
    );
};

const formatStoreLabel = (props) => {
    const { value, label } = props;
    return (
        <div className="flex flex-row">
            <div>{value} </div>
            <div className="ml-2">{label}</div>
        </div>
    );
};

const selectStyles = {
    menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
    }),
};

const EmployeeValueLabel = (props) => {
    return <div>{props.data.label}</div>;
};

const isInPeriod = (period, start, end) => {
    return period >= start && period <= end;
};

const employeeIdValidator =
    (currentData, employees, employeeIdIndex, fromIndex, toIndex) => (row) => {
        const employeeId = row[employeeIdIndex];
        const fromPeriod = row[fromIndex];
        const toPeriod = row[toIndex];
        const employeeAlreadyExists =
            currentData.filter(
                (d) =>
                    d.employeeId.value === employeeId &&
                    (isInPeriod(
                        fromPeriod,
                        d.fiscalKeyFrom.value,
                        d.fiscalKeyTo.value
                    ) ||
                        isInPeriod(
                            toPeriod,
                            d.fiscalKeyFrom.value,
                            d.fiscalKeyTo.value
                        ))
            ).length > 0;
        if (employeeAlreadyExists) {
            return {
                isValid: true,
                errorMessage: 'Overlap in periods for employee',
            };
        }
        const isValid = !!employees.find(
            (employee) => employee.value === employeeId
        );
        return {
            isValid: true,
            errorMessage: isValid ? '' : 'Invalid employee id',
        };
    };

const storeIdValidator = (stores, storeIdIndex) => (row) => {
    const storeId = row[storeIdIndex];
    const isValid = !!stores.find((store) => store.value === storeId);

    if (isValid) {
        return {
            isValid: true,
            errorMessage: '',
        };
    } else {
        return {
            isValid: false,
            errorMessage: 'Invalid store id',
        };
    }
};

const periodValidator = (index) => async (row) => {
    const periodStr = row[index];
    const periodNumber = parseInt(periodStr, 10);
    const isValid =
        isNaN(periodNumber) === false && periodStr.length === 6 ? true : false;
    return {
        isValid: isValid,
        errorMessage: isValid ? '' : `Invalid period value ${periodStr}`,
    };
};

const relativePeriodValidator = (startIndex, endIndex) => async (row) => {
    const startValidation = await periodValidator(startIndex)(row);
    const endValidation = await periodValidator(endIndex)(row);
    if (startValidation.isValid && endValidation.isValid) {
        const startNumber = parseInt(row[startIndex], 10);
        const endNumber = parseInt(row[endIndex], 10);
        const isValid = endNumber >= startNumber;
        return {
            isValid: isValid,
            errorMessage: isValid
                ? ''
                : 'End period should be same or before as start period',
        };
    } else {
        // Return true since the format validation is done by another validator
        return {
            isValid: true,
            errorMessage: '',
        };
    }
};

const numberValidator = (index, field) => async (row) => {
    const value = row[index];
    const numberValue = parseFloat(value);
    const isValid = isNaN(numberValue) ? false : true;
    return {
        isValid: isValid,
        errorMessage: isValid ? '' : `${field} is not a valid number`,
    };
};

const typeValidator = (errorMessage, options, index) => (row) => {
    const type = row[index];
    let isValid = options.map((y) => y.value).indexOf(type) >= 0;
    (!type || type == 'null') && row[6] == '0' ? (isValid = true) : '';
    return {
        isValid: isValid,
        errorMessage: isValid ? '' : errorMessage,
    };
};

const csvRowValidator = (currentData, employees, stores) => async (row) => {
    const validators = [
        employeeIdValidator(currentData, employees, 0, 2, 3),
        storeIdValidator(stores, 1),
        periodValidator(2),
        periodValidator(3),
        relativePeriodValidator(2, 3),
        typeValidator('Invalid point type', pointTypes, 5),
        numberValidator(6, 'Value'),
    ];
    const validationResults = await Promise.all(validators.map((v) => v(row)));
    const result = validationResults.reduce(
        (acc, curr) => {
            return {
                isValid: acc.isValid && curr.isValid,
                text:
                    curr.errorMessage === ''
                        ? acc.text
                        : [...acc.text, curr.errorMessage],
            };
        },
        { isValid: true, text: [] }
    );
    const validationResult = {
        isValid: result.isValid,
        text: result.isValid ? result.text.filter((y) => y != '') : result.text,
    };
    return validationResult;
};

const AddEmployeeForm = ({
    onSave,
    onClose,
    employees,
    periodOptions,
    operatingChainOptions,
    storeOptions,
}) => {
    const [state, setState] = useState({});

    const handleInputChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (key) => (value) => {
        setState({ ...state, [key]: value });
    };

    const loadOptions = (inputValue) => {
        const lowerInput = inputValue.toLowerCase();
        const options = employees
            .filter(
                (employee) =>
                    employee.value.toLowerCase().includes(lowerInput) ||
                    employee.label.toLowerCase().includes(lowerInput) ||
                    employee.username.toLowerCase().includes(lowerInput)
            )
            .slice(0, 20);
        return new Promise((resolve) => resolve(options));
    };

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <form className="flex flex-col space-y-2 w-full">
                <div className="w-full">
                    <label className="text-gray-50">Employees</label>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        isMulti
                        onChange={handleSelectChange('employees')}
                        formatOptionLabel={formatEmployeeLabel}
                        components={{
                            MultiValueLabel: EmployeeValueLabel,
                        }}
                        styles={selectStyles}
                        name="employees"
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Operating chain</label>
                    <Select
                        options={operatingChainOptions}
                        styles={selectStyles}
                        onChange={handleSelectChange('operatingChain')}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Store Id</label>
                    <Select
                        isMulti
                        cacheOptions
                        formatOptionLabel={formatStoreLabel}
                        options={storeOptions}
                        styles={selectStyles}
                        onChange={handleSelectChange('storeId')}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Amount</label>
                    <input
                        name="amount"
                        type="number"
                        step=".01"
                        className="px-2 w-full rounded-md h-9 "
                        onBlur={handleInputChange}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Fixed Points</label>
                    <input
                        name="fixed"
                        type="number"
                        step=".01"
                        className="px-2 w-full rounded-md h-9 "
                        onBlur={handleInputChange}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Factor Points</label>
                    <input
                        name="factor"
                        type="number"
                        step=".01"
                        className="px-2 w-full rounded-md h-9 "
                        onBlur={handleInputChange}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Period from</label>
                    <Select
                        onChange={handleSelectChange('fiscalKeyFrom')}
                        options={periodOptions}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Period to</label>
                    <Select
                        onChange={handleSelectChange('fiscalKeyTo')}
                        options={periodOptions}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Comment</label>
                    <input
                        name="comment"
                        className="px-2 w-full rounded-md h-9 "
                        onBlur={handleInputChange}
                    />
                </div>
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Add
                        onAdd={(e) => {
                            e.preventDefault();
                            onSave(state);
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

const Filter = (props) => {
    return (
        <>
            <filters.OperatingChainFilter {...props} />
            <filters.ActiveFilter {...props} />
            <filters.PeriodFilter {...props} />
        </>
    );
};

const EmployeeFactors = () => {
    const { apiFactory } = useDependencies();
    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showAddEmployee, setShowAddEmployee] = useState(false);
    const [state, setState] = useState({});
    const [filterSpec, setFilterSpec] = useState({ period: null });
    const { employeeFactorsApi, masterdataApi, bonusSettingsApi } = apiFactory;
    const columns = useMemo(() => {
        const fixedColumns = [
            columnSpecs.employeeId.column(),
            columnSpecs.username.column(),
            columnSpecs.name.column(),
            columnSpecs.storeId.column(),
            columnSpecs.operatingChain.column(),
            columnSpecs.pointType.column(),
            columnSpecs.value.column(),
            columnSpecs.fiscalKeyFrom.column(),
            columnSpecs.fiscalKeyTo.column(),
            columnSpecs.comment.column(),
        ];
        return fixedColumns;
    }, []);

    useEffect(() => {
        if (state.employeeFactors) {
            const data = state.employeeFactors.flatMap((r) => r.data);
            const tableData = data.flatMap((d) =>
                createRow(d, state.employeeOptions)
            );
            setTableData(tableData);
        }
    }, [state.employeeFactors, state.employeeOptions]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const [
                employeeFactors,
                employees,
                periodOptions,
                stores,
                operatingChains,
            ] = await Promise.all([
                employeeFactorsApi.getEmployeeFactors(),
                masterdataApi.getEmployees(),
                masterdataApi.getBonusPeriods(),
                masterdataApi.getStores(),
                bonusSettingsApi.getOperatingChains(),
            ]);
            const employeeOptions = employees.map((employee) => ({
                ...employee,
                value: employee.employeeId,
                label: `${employee.firstname} ${employee.lastname}`,
            }));
            const storeOptions = stores.map((store) => ({
                label: store.departmentName,
                value: store.storeId,
            }));
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            if (mounted) {
                setState({
                    employeeFactors,
                    employeeOptions,
                    periodOptions,
                    storeOptions,
                    operatingChainOptions,
                });
                setIsLoaded(true);
            }
        }
        getData();
        return () => (mounted = false);
    }, [employeeFactorsApi, masterdataApi, bonusSettingsApi]);

    const openAddEmployee = () => setShowAddEmployee(true);
    const closeAddEmployee = () => setShowAddEmployee(false);
    const addEmployees = (data) => {
        const newRows = data.employees.map((employee) => {
            return {
                id: uuidv4(),
                employeeId: employee.value,
                username: employee.username,
                name: employee.label,
                fiscalKeyFrom: data.fiscalKeyFrom.value,
                fiscalKeyTo: data.fiscalKeyTo.value,
                fixed: data.fixed,
                factor: data.factor,
                comment: data.comment,
                amount: data.amount,
                storeId: data.storeId[0].value,
                operatingChain: data.operatingChain.value,
            };
        });
        const newTableData = [
            ...tableData,
            ...newRows.flatMap((d) => createRow(d, state.employeeOptions)),
        ];
        setTableData(newTableData);
        setShowAddEmployee(false);
    };

    const validateDataRow = (row) => {
        if (row.fixed != 0 && row.factor != 0) {
            return {
                valid: false,
                message: `Only one of amount and fixed can be not null. EmployeeId: ${row.employeeId}`,
            };
        }
        return { valid: true };
    };

    const validateData = (data) => {
        const invalidRows = data.map(validateDataRow).filter((r) => !r.valid);
        if (invalidRows.length > 0) {
            return {
                valid: false,
                message: invalidRows.map((r) => r.message).join('\n'),
            };
        }
        return { valid: true };
    };

    const selectCorrectPointType = (row) => {
        if (row.pointType.value) {
            const key = row.pointType.value;
            const points = {
                [key]: columnSpecs.value.value(row),
            };
            return points;
        }
    };

    const saveData = async () => {
        const transform = (tableData) => {
            const data = tableData.map((row) => {
                const points = selectCorrectPointType(row);
                return {
                    id: row.id,
                    comment: columnSpecs.comment.value(row),
                    employeeId: columnSpecs.employeeId.value(row),
                    storeId: columnSpecs.storeId.value(row),
                    operatingChain: columnSpecs.operatingChain.value(row),
                    amount: columnSpecs.value.value([]),
                    fixed: columnSpecs.value.value([]),
                    factor: columnSpecs.value.value([]),
                    fiscalKeyFrom: columnSpecs.fiscalKeyFrom.value(row),
                    fiscalKeyTo: columnSpecs.fiscalKeyTo.value(row),
                    username: columnSpecs.username.value(row),
                    ...points,
                };
            });
            return {
                data: data,
                simulationId: simulationIdState.get()[0].simulationId,
            };
        };
        const data = transform(tableData);
        const dataValidations = validateData(data.data);
        if (dataValidations.valid) {
            try {
                await employeeFactorsApi.saveEmployeeFactors(data);
                const savedData = await employeeFactorsApi.getEmployeeFactors(
                    true
                );
                setState({ ...state, employeeFactors: savedData });
                return dataValidations;
            } catch (ex) {
                return {
                    valid: false,
                    message: ex?.response?.data || ex.message,
                };
            }
        } else {
            return dataValidations;
        }
    };

    const addCsvData = (rows) => {
        const newRows = rows.data.map((row) => ({
            id: uuidv4(),
            employeeId: row.items[0],
            storeId: row.items[1],
            fiscalKeyFrom: row.items[2],
            fiscalKeyTo: row.items[3],
            operatingChain: row.items[4],
            amount:
                row.items[5] === 'amount' ? row.items[6].replace(',', '.') : 0,
            fixed:
                row.items[5] === 'fixed' ? row.items[6].replace(',', '.') : 0,
            factor:
                row.items[5] === 'factor' ? row.items[6].replace(',', '.') : 0,
            comment: row.items[7],
        }));
        const newTableData = [
            ...tableData,
            ...newRows.flatMap((d) => createRow(d, state.employeeOptions)),
        ];
        setTableData(newTableData);
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteSelected = () => setTableData(removeSelected(tableData));

    const toggleItem = (id) => setTableData(toggleRow(id, tableData));

    const toggleAll = (ids, shouldToggle) => {
        setTableData(toggleAllRows(ids, shouldToggle, tableData));
    };

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id === id);
        const row = { ...newTableData[rowIndex] };
        row[columnName] = { ...row[columnName], value };
        newTableData[rowIndex] = row;
        setTableData(newTableData);
    };

    const getCurrentPeriod = (periodOptions) => {
        const periods = periodOptions.flatMap((y) =>
            y.options.map((x) => x.period)
        );
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDay() - 1
        );
        const currentPeriod = periods.filter(
            (p) =>
                today >= new Date(p.startDate) && today <= new Date(p.endDate)
        )[0];
        return currentPeriod;
    };

    const activeFilter = (currentPeriod) => (filter, row) => {
        if (!filter.activeFilter || filter.activeFilter.value === '') {
            return true;
        }
        if (
            filter.activeFilter.value === 'Passed' &&
            row.fiscalKeyTo.value < currentPeriod.periodId
        ) {
            return true;
        }
        if (
            filter.activeFilter.value === 'Future' &&
            row.fiscalKeyFrom.value > currentPeriod.periodId
        ) {
            return true;
        }
        if (
            filter.activeFilter.value === 'Active' &&
            row.fiscalKeyFrom.value <= currentPeriod.periodId &&
            row.fiscalKeyTo.value >= currentPeriod.periodId
        ) {
            return true;
        }
        return false;
    };

    // Create rows
    const rows = useMemo(() => {
        if (state.periodOptions) {
            const currentPeriod = getCurrentPeriod(state.periodOptions);
            const filter = filters.composeFilters([
                filters.period,
                activeFilter(currentPeriod),
                filters.operatingChain,
            ]);
            const filtered = tableData.filter(filter(filterSpec));
            return [...filtered];
        } else {
            return tableData;
        }
    }, [filterSpec, tableData, state.periodOptions]);

    //Convert table data to string
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) =>
                    `${row.employeeId.value}\t${
                        '<StoreID>' && row.storeId.value
                    }\t${row.fiscalKeyFrom.value}\t${row.fiscalKeyTo.value}\t${
                        row.operatingChain.value
                    }\t${row.pointType.value}\t${row.value.value}\t${
                        row.comment.value
                    }\t${row.username.value}\t${row.name.value}`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    const sampleCSV = () => {
        return `112950\t1234\t202101\t202112\tOCNOELK\tfactor\t1.25\tSL Bonus x1,25
112950\t1234\t202101\t202112\tOCNOELK\tfactor\t1.25\tAftersales Bonus x1,25
247300\t1234\t202101\t202112\tOCNOELK\tfixed\t1000\tStellar seller 1000 fixed
247300\t1234\t202101\t202112\tOCNOELK\tamount\t500\tFixed amount in currency`;
    };

    const csvColumns = [
        'User id',
        'Store id',
        'Start period',
        'End period',
        'Operating chain',
        'Point type',
        'Value',
        'Comment',
    ];

    return (
        <div className="flex w-full h-full flex-col">
            <div className="content w-full flex-1 flex flex-row">
                {isLoaded ? (
                    <>
                        <Table
                            data={rows}
                            onNewRow={openAddEmployee}
                            columns={columns}
                            onSaveData={saveData}
                            csvRowValidator={csvRowValidator(
                                tableData,
                                state.employeeOptions,
                                state.storeOptions
                            )}
                            addCsvData={addCsvData}
                            onRemoveRow={deleteRow}
                            onUpdateData={updateData}
                            onToggleItem={toggleItem}
                            onToggleAll={toggleAll}
                            onDeleteSelected={deleteSelected}
                            sampleCSV={sampleCSV}
                            rowsCSV={rowsCSV}
                            csvColumns={csvColumns}
                            showDeleteAllBtn={false}
                            componentName={'EmployeeFactors'}
                            components={{
                                filter: (
                                    <Filter
                                        filter={filterSpec}
                                        onChange={setFilterSpec}
                                        periodOptions={state.periodOptions}
                                        operatingChainOptions={
                                            state.operatingChainOptions
                                        }
                                    />
                                ),
                            }}
                        />
                        <Modal
                            isOpen={showAddEmployee}
                            className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
                            overlayClassName="flex justify-center items-center fixed top-0 right-0 left-0 bottom-0 bg-gray-900 bg-opacity-80"
                            onRequestClose={(e) => {
                                e.stopPropagation();
                                setShowAddEmployee(false);
                            }}
                            shouldCloseOnOverlayClick={true}
                        >
                            <AddEmployeeForm
                                onClose={closeAddEmployee}
                                onSave={addEmployees}
                                employees={state.employeeOptions}
                                periodOptions={state.periodOptions}
                                operatingChainOptions={
                                    state.operatingChainOptions
                                }
                                storeOptions={state.storeOptions}
                            />
                        </Modal>
                    </>
                ) : (
                    <div className="w-full text-center">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmployeeFactors;
