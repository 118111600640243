import React from 'react';
import { filters } from '@/components/Table/index';

export const Filter = (props) => {
    return (
        <>
            <filters.OperatingChainFilter {...props} />
        </>
    );
};
