import  { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { getEnvVar } from './hostedEnvironmentResolver';

export const scopes = [getEnvVar('REACT_APP_AZURE_SCOPE')] || [];

const msalConfig = {
    auth: {
        clientId: getEnvVar('REACT_APP_AZURE_CLIENT_ID'),
        authority: getEnvVar("REACT_APP_AZURE_AUTHORITY"),
        redirectUri: window.origin + "/",
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
};

export const loginRequest = {
    scopes: scopes,
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const useAuth = () => {
    const [accessToken, setAccessToken] = useState();
    const [roles, setRoles] = useState([]);
    const [userName, setUserName] = useState("");
    const { instance, accounts } = useMsal();

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const getAccessToken = async () => {
            if (accounts && accounts.length > 0) {
                try {
                    const response = await instance.acquireTokenSilent({
                        ...loginRequest,
                        forceRefresh: false,
                        account: accounts[0],
                    });
                    const { accessToken, account, idTokenClaims} = response;
                    setAccessToken(accessToken);
                    setRoles(idTokenClaims?.roles);
                    setUserName(account?.username || idTokenClaims?.preferred_username || "");
                } catch (e) {
                    console.error(e);
                    try {
                        const response = await instance.acquireTokenPopup({
                            ...loginRequest,
                            account: accounts[0],
                        });
                        const { accessToken, account, idTokenClaims} = response;
                        setAccessToken(accessToken);
                        setRoles(idTokenClaims?.roles);
                        setUserName(account?.username || idTokenClaims?.preferred_username || "");
                    } catch (e) {
                        console.error(e);
                        try {
                            const response = await instance.acquireTokenRedirect({
                                ...loginRequest,
                                account: accounts[0],
                            });
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
            }
        };

        try {
          getAccessToken();
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) instance.loginRedirect();
            else console.error(e);
        }
    }, []);

    useEffect(() => {
        const getAccessToken = async () => {
            if (accounts && accounts.length > 0) {
                try {
                    const response = await instance.acquireTokenSilent({
                        ...loginRequest,
                        forceRefresh: false,
                        account: accounts[0],
                    });
                    const { accessToken, account, idTokenClaims} = response;
                    setAccessToken(accessToken);
                    setRoles(idTokenClaims?.roles);
                    setUserName(account?.username || idTokenClaims?.preferred_username || "");
                } catch (e) {
                    console.error(e);
                    try {
                        const response = await instance.acquireTokenPopup({
                            ...loginRequest,
                            account: accounts[0],
                        });
                        const { accessToken, account, idTokenClaims} = response;
                        setAccessToken(accessToken);
                        setRoles(idTokenClaims?.roles);
                        setUserName(account?.username || idTokenClaims?.preferred_username || "");
                    } catch (e) {
                        console.error(e);
                        try {
                            const response = await instance.acquireTokenRedirect({
                                ...loginRequest,
                                account: accounts[0],
                            });
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
            }
        };

        try {
          getAccessToken();
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) instance.loginRedirect();
            else console.error(e);
        }
    }, [accounts, instance, isAuthenticated]);

    return { accessToken, roles, userName };
};
