import React, { useState } from 'react';
import * as buttons from './buttons';
import Select from 'react-select';
import { customSelectDropdown } from './style';

const CopyFromSettingsStore = ({
    onCopy,
    onClose,
    fiscalKeyOptions,
    fiscalKey,
}) => {
    const [selectedFiscalKey, setSelectedFiscalKey] = useState('');

    const handleFiscalSelectChange = (e) => {
        setSelectedFiscalKey(e);
    };

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <form className="flex flex-col space-y-2 w-full">
                <h2 className="font-bold text-center h-10 text-xl text-gray-50">
                    Copy data into Period: {fiscalKey ? fiscalKey : 'General'}
                </h2>
                <div className="w-full flex flex-col">
                    <label className="text-gray-50">Period</label>
                    <Select
                        options={fiscalKeyOptions}
                        onChange={(e) => {
                            handleFiscalSelectChange(e);
                        }}
                        className="w-full inline-block text-gray-900"
                        classNamePrefix="select-sm"
                        styles={customSelectDropdown(32)}
                        value={selectedFiscalKey}
                        menuPortalTarget={document.body}
                    ></Select>
                </div>
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Copy
                        text="Copy"
                        onClick={(e) => {
                            e.preventDefault();
                            onCopy(selectedFiscalKey);
                            onClose();
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

export default CopyFromSettingsStore;
