import React from 'react';

const RadioButtonItemCompetition = ({ period, handlePeriodChange }) => {
    let periodData = period;

    if (period.value === 'custom') {
        periodData = 'custom';
    }

    const radioInputStyle =
        'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500';
    const radioLabelStyle =
        'py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300';

    return (
        <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div className="flex items-center pl-3">
                <input
                    id={`horizontal-list-radio-period-${period.value}`}
                    type="radio"
                    name="list-radio"
                    onChange={() => handlePeriodChange(periodData)}
                    className={radioInputStyle}
                />
                <label
                    htmlFor={`horizontal-list-radio-period-${period.value}`}
                    className={radioLabelStyle}
                >
                    {period.label}{' '}
                </label>
            </div>
        </li>
    );
};

export default RadioButtonItemCompetition;
