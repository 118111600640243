import React, { useState, useEffect, useMemo } from 'react';
import { Table, Loader } from '@/components';
import {
    columnTypes,
    removeRow,
    removeAllRows,
} from '@/components/Table/index';
import {
    saveBonusGeneral,
    updateRowGeneral,
    createRow,
    prepareTableData,
    prepareTableDataMerge,
    prepareRuleTypes,
    prepareCsvData,
    createNewRowsObject,
    sortByRuleValueAndOpChain,
    prepareDataForSave,
    newRowGeneral,
} from './common';
import { useDependencies } from '@/DependencyProvider';
import { validators } from '@/utils';
import { Filter } from './operatingChainFilter';
import { simulationIdState } from '@/simulationIdState';

export default function StoreOperations({ fiscalKey }) {
    const { apiFactory } = useDependencies();
    const { bonusSettingsApi, bonusRuleApi } = apiFactory;
    const [isLoaded, setIsLoaded] = useState(false);
    const [state, setState] = useState({});
    const [tableData, setTableData] = useState([]);
    const [filterSpec, setFilterSpec] = useState({
        operatingChain: { label: 'All' },
    });

    useEffect(() => {
        if (state.allStoreOperations) {
            const sortedByOperatingChain = sortByRuleValueAndOpChain(
                state.ruleTypes,
                state.operatingChainOptions,
                state.allStoreOperations,
                fiscalKey
            );
            setTableData(sortedByOperatingChain);
            setIsLoaded(true);
        }
    }, [fiscalKey, state]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const [measures, operatingChains, allStoreOperations] =
                await Promise.all([
                    bonusSettingsApi.getMeasures(),
                    bonusSettingsApi.getOperatingChains(),
                    bonusRuleApi.getStoreOperations(),
                ]);

            const ruleTypes = prepareRuleTypes(measures, 'storeOperations');
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            mounted ? setState({
                allStoreOperations,
                ruleTypes,
                operatingChains,
                operatingChainOptions,
            }) : '';
        }
        getData();
        return () => mounted = false;
    }, [bonusRuleApi, bonusSettingsApi]);

    const columns = useMemo(() => {
        const fixedColumns = [
            columnTypes.operatingChainType.column(),
            columnTypes.ruleType.column(),
            columnTypes.valueType.column(),
            columnTypes.operatingChainValueTwo.column(),
        ];

        return [...fixedColumns];
    }, []);

    const updateData = (id, columnName, value) => {
        setTableData(updateRowGeneral(id, columnName, value, tableData));
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData, filterSpec.operatingChain));
    };

    const saveData = async () => {
        await saveBonusGeneral(bonusRuleApi.saveStoreOperations)(
            prepareDataForSave(tableData),
            fiscalKey,
            simulationIdState.get()[0].simulationId
        );
        const savedData = await bonusRuleApi.getStoreOperations(true);
        setState({ ...state, allStoreOperations: savedData });
        return {
            valid: true,
        };
    };

    const rows = useMemo(() => {
        return filterSpec &&
            filterSpec.operatingChain &&
            filterSpec.operatingChain.value
            ? tableData.filter(
                  (el) =>
                      filterSpec.operatingChain.value ===
                      el.operatingChain.value
              )
            : tableData;
    }, [filterSpec, tableData]);

    const sampleCSV = () => `OCNOELK\tHON_SERVICE\tPERCENTAGE\t12
OCNOELK\tCOL\tPERCENTAGE\t22
OCNOELK\tAVS\tPERCENTAGE\t22`;

    // Convert table data to string
    const rowsCSV = useMemo(() => 
        {
            if (rows.length > 0) {
                const rowStrings = rows.map(
                    (row) => `${row.operatingChain.value}\t${row.key.value}\t${row.valueType.value}\t${row.value}`
                );
                return rowStrings.join('\n');
            }
        }, [rows]);

    const { optionValidator, composeValidators, ruleTypeExistValidator } =
        validators;
    const csvRowValidator = (currentData, ruleTypes) => async (row) => {
        const validators = [
            optionValidator('Invalid rule type', ruleTypes, 1),
            ruleTypeExistValidator(currentData, 0),
        ];
        const validator = composeValidators(validators);
        return await validator(row);
    };

    const addCsvData = (data) => {
        prepareCsvData(data, setTableData, tableData, state);
    };

    const copyFiscalKey = (fiscalKeyData, operatingChainData) => {
        const chosenOpChains = operatingChainData.map((oc) => oc.value);

        const newChosenObjects = createNewRowsObject(
            prepareTableData(state.allStoreOperations, fiscalKey)
        ); // Table we are copying INTO
        const newCopiedObjects = createNewRowsObject(
            prepareTableData(state.allStoreOperations, fiscalKeyData.value)
        ); // Data we are copying
        if (!chosenOpChains[0]) {
            state.operatingChainOptions.forEach((opChain) =>
                chosenOpChains.push(opChain.value)
            );
        }
        const copiedObjectsFilteredByOC = newCopiedObjects.filter((el) =>
            chosenOpChains.includes(el.operatingChain)
        );

        const chosenPeriodRows = newChosenObjects.map(
            createRow(state.ruleTypes, state.operatingChainOptions)
        );
        const copiedPeriodRows = copiedObjectsFilteredByOC.map(
            createRow(state.ruleTypes, state.operatingChainOptions)
        );
        setTableData(prepareTableDataMerge(chosenPeriodRows, copiedPeriodRows));
    };

    return isLoaded ? (
        <Table
            data={rows}
            columns={columns}
            onUpdateData={updateData}
            onRemoveRow={deleteRow}
            onRemoveAllRows={deleteAllRows}
            onNewRow={() => {
                newRowGeneral(setTableData, tableData, filterSpec, state);
            }}
            onSaveData={saveData}
            components={{
                filter: (
                    <Filter
                        filter={filterSpec}
                        onChange={setFilterSpec}
                        operatingChainOptions={state.operatingChainOptions}
                    />
                ),
            }}
            sampleCSV={sampleCSV}
            rowsCSV={rowsCSV}
            csvRowValidator={csvRowValidator(tableData, state.ruleTypes)}
            csvColumns={[
                'Operating Chain',
                'Rule Type',
                'Percentage/Fixed',
                'Value',
            ]}
            addCsvData={addCsvData}
            copyFiscalKey={copyFiscalKey}
            showCopyFiscal={true}
            onCopyFrom={copyFiscalKey}
            filterSpec={filterSpec}
            fiscalKey={fiscalKey}
        />
    ) : (
        <div className="w-full text-center">
            <Loader />
        </div>
    );
}
