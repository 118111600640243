import React from 'react';
import { MultiSelect } from '@/components';
import * as FaIcons from 'react-icons/fa';
import { copyText } from '@/utils';
import { toast } from 'react-toastify';

export default function MultiSelectCell(rowData) {
    const {
        value,
        row: {
            original: { id: rowId },
        },
        column: { id, containerClasses },
        onUpdateData,
    } = rowData;
    const defaultValue = value || { value: '' };
    const config = {
        ...{ options: [], isVisible: true, isDisabled: false },
        ...defaultValue,
    };
    const handleOnChange = (value) => {
        onUpdateData(
            rowId,
            id,
            value.map((v) => v.value)
        );
    };

    const copyValues = (evt) => {
        evt.preventDefault();
        const allLines = value.value.map((v) => v.value).join('\n');
        copyText(allLines, () => {
            toast.success('Values copied', {
                position: toast.POSITION.TOP_RIGHT,
            });
        });
    };

    const selectedValues = config.value.map((v) =>
        config.options.find((o) => o.value == v.value)
    );
    const copyElem = config.enableCopyValues ? (
        <button onClick={copyValues} className="flex-1 text-left">
            <FaIcons.FaRegCopy className="h-6 w-6 fill-current inline-block ml-2" />
        </button>
    ) : (
        <></>
    );
    return (
        <>
            <MultiSelect
                onChange={handleOnChange}
                options={config.options}
                allowSelectAll={true}
                value={selectedValues}
                formatOptionLabel={config.formatLabel}
                className={containerClasses}
            />
            {copyElem}
        </>
    );
}
