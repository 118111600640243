import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as buttons from './buttons';
import * as FaIcons from 'react-icons/fa';
import * as XLSX from 'xlsx';

const splitStr = (str, separators) => {
    const start = [str];
    return separators.reduce(
        (acc, curr) => acc.flatMap((r) => r.split(curr)),
        start
    );
};

const CSVForm = ({
    columns = [],
    onClose,
    onSave,
    validateRow,
    csvColumnsValidator,
    sampleCSV,
    hasHeader = false,
    participants = false,
}) => {
    const [state, setState] = useState({
        columns: hasHeader ? [] : columns,
        data: [],
        value: '',
        isValid: false,
    });

    const handleRawChanged = async (inputValue) => {
        const value = inputValue || '';
        const rows = splitStr(value, ['\n', '\r\n'])
            .map((r) => r.trim())
            .filter((r) => r !== '');
        const data = rows.map((r) => {
            const items = splitStr(r, [';', '\t']).map((v) => v.trim());
            return { items, raw: r, rowId: uuidv4() };
        });
        const [headerRow, ...dataRows] = hasHeader
            ? [data.length > 0 ? data[0].items : [], ...data.slice(1)]
            : [columns, ...data];
        if (data && data.length > 0) {
            setState({
                columns: headerRow,
                data: dataRows,
                value: value || '',
                isValid: false,
            });
        } else {
            setState({
                columns: headerRow,
                data: [],
                value: value || '',
                isValid: false,
            });
        }
    };

    const validateData = async () => {
        if (csvColumnsValidator) {
            csvColumnsValidator(state.columns);
        }

        const validatedData = await Promise.all(
            state.data.map(async (data) => {
                const validationResult = validateRow
                    ? await validateRow(data.items)
                    : { isValid: true, text: ['ok'] };
                return {
                    ...data,
                    validationResult: validationResult,
                };
            })
        );
        setState({
            ...state,
            data: validatedData,
            isValid:
                validatedData
                    .map((vd) => vd.validationResult.isValid)
                    .indexOf(false) < 0,
        });
    };

    const stop = (e) => {
        e.stopPropagation();
    };

    const downloadSample = (sample) => {
        const data = sample.split('\n').map((r) => r.split('\t'));
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sample');
        XLSX.writeFile(workbook, 'sample.xlsx');
    };

    const normalStyle = 'w-full p-4 bg-gray-700 space-y-4 flex flex-1 flex-col';
    const participantsStyle =
        'w-full bg-gray-700 space-y-4 flex flex-1 flex-col';

    return (
        <form
            className={participants ? participantsStyle : normalStyle}
            onKeyDown={stop}
        >
            <div className="flex flex-col w-full space-y-2 flex-0">
                {participants ? null : (
                    <label className="w-full text-gray-50">Raw data</label>
                )}
                <textarea
                    className="w-full max-h-96 h-60 p-1 border-gray-500 rounded-md"
                    onChange={(e) => handleRawChanged(e.target.value)}
                    onKeyDown={stop}
                    onKeyUp={stop}
                    value={state.value}
                />
                {sampleCSV ? (
                    <div>
                        <buttons.Copy
                            text="Add sample"
                            onClick={(e) => {
                                e.preventDefault();
                                handleRawChanged(sampleCSV());
                            }}
                        />
                        <buttons.Button
                            text="Download sample"
                            onClick={(e) => {
                                e.preventDefault();
                                downloadSample(sampleCSV());
                            }}
                            icon={<FaIcons.FaFileDownload />}
                            className="btn-add ml-2"
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className="w-full space-y-2 flex-1 overflow-x-auto overflow-y-auto">
                <table className="w-full h-auto">
                    <thead className="flex-0">
                        <tr className="text-gray-50">
                            {state.columns.map((c) => (
                                <th className="px-2" key={c}>
                                    {c}
                                </th>
                            ))}
                            <th className="px-2">Validation result</th>
                        </tr>
                    </thead>
                    <tbody className="flex-1">
                        {state.data.map((row) => (
                            <tr
                                key={row.rowId}
                                className={
                                    'border-solid border-b-2 border-gray-600 ' +
                                    (row.validationResult
                                        ? row.validationResult.isValid
                                            ? 'bg-green-300'
                                            : 'bg-red-300'
                                        : 'bg-gray-400')
                                }
                            >
                                {state.columns.map((c, colIndex) => (
                                    <td key={c}>{row.items[colIndex]}</td>
                                ))}
                                <td>
                                    {row.validationResult && (
                                        <ul>
                                            {row.validationResult.text.map(
                                                (msg) => (
                                                    <li key={uuidv4()}>
                                                        {msg}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="space-x-2 text-right w-full flex-0">
                {participants ? null : <buttons.Cancel onCancel={onClose} />}
                {state.isValid ? (
                    <buttons.Add
                        onAdd={(e) => {
                            e.preventDefault();
                            onSave(state);
                        }}
                    />
                ) : (
                    <buttons.Validate
                        onClick={(e) => {
                            e.preventDefault();
                            validateData();
                        }}
                        text="Validate"
                    />
                )}
            </div>
        </form>
    );
};

export default CSVForm;
