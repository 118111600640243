import React from 'react';

const TextCell = ({ value }) => {
    const className = `w-full p-1`;
    return (
        <div className="whitespace-nowrap w-full">
            <span className={className}>{value.value}</span>
        </div>
    );
};

export default TextCell;
