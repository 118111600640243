import { memoizedMakeReq, postRequest } from './apiCommon';

const competitionsApi = (axios) => ({
    getCompetitions: memoizedMakeReq(axios, 'get-competitions'),
    getParameters: memoizedMakeReq(axios, 'get-parameters'),
    getParticipantsPoints: postRequest(axios, 'get-participants-points'),
    createCompetition: postRequest(axios, 'create-competition'),
    deleteCompetition: postRequest(axios, 'delete-competition'),
    deleteCompetitionParticipants: postRequest(
        axios,
        'delete-competition-participant'
    ),
    updateCompetition: postRequest(axios, 'update-competition'),
});

export default competitionsApi;
