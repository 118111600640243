import { simulationIdState } from '@/simulationIdState';
import { constants } from '@/utils';

export const saveSimulationId = (id) => {
    simulationIdState.set([{ simulationId: id }]);
    localStorage.setItem('simId', id);
};

export const removeSimulationId = () => {
    simulationIdState.set([{ simulationId: constants.PRODUCTION }]);
    localStorage.removeItem('simId');
};
