import React from 'react';
import { Grid } from 'react-loader-spinner';

const Loader = ({ text = 'Loading...' }) => {
    return (
        <div className="flex mt-10">
            <div className="flex flex-nowrap mx-auto gap-4 items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600">
                <Grid
                    aria-hidden="true"
                    color="#00BFFF"
                    height={20}
                    width={20}
                />
                {text}
            </div>
        </div>
    );
};

export default Loader;
