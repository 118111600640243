import React from 'react';
import Select from 'react-select';

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
    }),
    control: (provided) => ({
        ...provided,
        minHeight: '26px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '2px 2px',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '2px',
    }),
    container: (provided) => ({
        ...provided,
        minWidth: '15em',
        flex: '1 1 0%',
    }),
};

export const ActiveFilter = ({ props, onChange, filter }) => {
    const activeChanged = (value) => {
        const newFilter = { ...filter, activeFilter: value };
        onChange(newFilter);
    };
    const options = ['All', 'Passed', 'Active', 'Future'].map((y) => ({
        label: y,
        value: y === 'All' ? '' : y,
    }));
    const value = filter.activeFilter ? filter.activeFilter : options[0];
    return (
        <div className="flex flex-row items-center">
            <label className="text-gray-100 text-lg font-bold pr-2">
                Filter active:{' '}
            </label>
            <Select
                styles={customStyles}
                options={options}
                value={value}
                menuPlace="auto"
                onChange={activeChanged}
                {...props}
            />
        </div>
    );
};

export const OperatingChainFilter = ({
    operatingChainOptions,
    onChange,
    filter,
    setCurrentPage,
}) => {
    const opChanged = (value) => {
        const newFilter = { ...filter, operatingChain: value };
        onChange(newFilter);
        setCurrentPage(0);
    };
    const options = [{ label: 'All', value: '' }, ...operatingChainOptions];
    const value = filter.operatingChain ? filter.operatingChain : options[0];

    return (
        <div className="flex flex-row items-center">
            <label className="text-gray-100 text-lg font-bold pr-2">
                Operating chain:{' '}
            </label>
            <Select
                styles={customStyles}
                options={options}
                onChange={opChanged}
                value={value}
                menuPlacement="auto"
                isMulti={false}
            />
        </div>
    );
};

export const operatingChain = (filter, row) => {
    if (filter.operatingChain && filter.operatingChain.value) {
        return filter.operatingChain.value === row.operatingChain.value;
    }
    return true;
};

export const PeriodFilter = ({ periodOptions, onChange, filter }) => {
    const opChanged = (value) => {
        const newFilter = { ...filter, period: value };
        onChange(newFilter);
    };
    const options = [{ label: 'All', value: '' }, ...periodOptions];
    const value = filter.period ? filter.period : options[0];

    return (
        <div className="flex flex-row items-center">
            <label className="text-gray-100 text-lg font-bold pr-2">
                Period:{' '}
            </label>
            <Select
                styles={customStyles}
                options={options}
                onChange={opChanged}
                value={value}
                menuPlacement="auto"
                isMulti={false}
            />
        </div>
    );
};

export const period = (filter, row) => {
    if (filter.period && filter.period.value) {
        const selectedPeriod = filter.period.value;
        const startPeriod = row.fiscalKeyFrom.value;
        const endPeriod = row.fiscalKeyTo.value;
        return selectedPeriod >= startPeriod && selectedPeriod <= endPeriod;
    }
    return true;
};

export const operatingChainTarget = (filter, row) => {
    if (filter.operatingChain && filter.operatingChain.value) {
        const operatingChainCells = Object.values(row).filter(
            (r) =>
                r.operatingChain &&
                r.operatingChain === filter.operatingChain.value
        );
        const value = operatingChainCells.reduce(
            (acc, curr) => acc + curr.value,
            0
        );
        return value > 0;
    }
    return true;
};

export const composeFilters = (filters) => (filterSpec) => {
    return filters.reduce(
        (acc, curr) => {
            return (row) => acc(row) && curr(filterSpec, row);
        },
        () => true
    );
};
