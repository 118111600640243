import React, { useState, useEffect, useMemo } from 'react';
import { Table, Loader } from '@/components';
import { v4 as uuidv4 } from 'uuid';
import { validators } from '@/utils';
import {
    columnTypes,
    removeRow,
    toggleRow,
    toggleAllRows,
    removeSelected,
    filters,
} from '@/components/Table/index';
import {
    hasSameDates,
    getDatesFromPeriod,
    getPeriodFromDates,
    existsPeriod,
    saveStackedBonusGeneral
} from './common';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';

const COLUMN_KEYS = {
    OPERATING_CHAIN: 'operatingChain',
    STAFF_TYPE: 'staffType',
    RULE_TYPE: 'ruleType',
    RULE_VALUE: 'ruleValue',
    RULE_METRIC: 'ruleMetric',
    LIMIT: 'limit',
    REWARD_VALUE: 'rewardValue',
    REWARD_TYPE: 'rewardType',
    FISCAL_KEY: 'fiscalKey',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    RULE_ID: 'ruleId',
};

const createKeyValueArgs = ({
    categories,
    searchArticles,
    searchModels,
    searchBrands,
    searchGroups,
    modelTypesItems,
}) => {
    return {
        getCategories: () => categories,
        getArticles: searchArticles,
        getModels: searchModels,
        getBrands: searchBrands,
        getGroups: searchGroups,
        getModelTypes: () => {
            return modelTypesItems;
        },
    };
};

const createRow =
    (ruleTypes, keyValueArgs, operatingChainOptions) =>
    (bonusEntry) => {
        const row = {
            id: bonusEntry.id,
            altId: bonusEntry.altId,
            selected: false,
            ruleType: columnTypes.ruleType.cell(bonusEntry.ruleType, ruleTypes),
            ruleValue: columnTypes.keyValue.cell(
                bonusEntry.ruleValue,
                bonusEntry.ruleType,
                keyValueArgs
            ),
            staffType: columnTypes.staffType.cell(bonusEntry.staffType),
            startDate: columnTypes.startDate.cell(
                bonusEntry.startDate
            ),
            endDate: columnTypes.endDate.cell(bonusEntry.endDate),
            fiscalKey: columnTypes.fiscalKey.cell(bonusEntry.fiscalKey),
            ruleMetric: columnTypes.ruleMetric.cell(bonusEntry.ruleMetric),
            limit: columnTypes.limit.cell(bonusEntry.limit),
            operatingChain: columnTypes.operatingChain.cell(
                bonusEntry.operatingChain,
                operatingChainOptions
            ),
            rewardType: columnTypes.rewardType.cell(
                bonusEntry.rewardType
            ),
            rewardValue: columnTypes.rewardValue.cell(
                bonusEntry.rewardValue
            ),
            ruleId: bonusEntry.ruleId,
        };
        return row;
    };

const Filter = (props) => {
    return (
        <>
            <filters.OperatingChainFilter {...props} />
            <filters.ActiveFilter {...props} />
        </>
    );
};

export default function StackedBonus() {
    const { apiFactory } = useDependencies();
    const [isLoaded, setIsLoaded] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [state, setState] = useState({});
    const [filterSpec, setFilterSpec] = useState({
        operatingChain: null,
        activeFilter: null,
    });
    const [bonusPeriods, setBonusPeriods] = useState([]);
    const [invalidRowIds, setInvalidRowIds] = useState([]);

    const getOptions = async (getter) => {
        const response = await getter();
        return response.map((c) => ({
            value: c.id,
            label: `${c.id} - ${c.description}`,
        }));
    };

    useEffect(() => {
        if (state.data && state.ruleTypes && state.keyValueArgs) {
            const data = state.data.flatMap((d) => d.data);
            const obj = createNewObject(data);
            const tableData = obj
                .map(
                    createRow(
                        state.ruleTypes,
                        state.keyValueArgs,
                        state.operatingChainOptions
                    )
                )
                .filter((obj) => (obj.operatingChain.label.length > 0))
            setTableData(tableData);
            setIsLoaded(true);
        }
    }, [state]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const {
                bonusSettingsApi,
                articleMasterdataApi,
                bonusRuleApi,
                searchApi,
                masterdataApi,
            } = apiFactory;

            const [
                measures,
                categories,
                operatingChains,
                models,
                brands,
                modelTypes,
                groups,
                data,
                periods,
            ] = await Promise.all([
                bonusSettingsApi.getMeasures(),
                getOptions(articleMasterdataApi.getCategories),
                bonusSettingsApi.getOperatingChains(),
                getOptions(articleMasterdataApi.getModels),
                getOptions(articleMasterdataApi.getBrands),
                articleMasterdataApi.getModelTypes(),
                getOptions(articleMasterdataApi.getGroups),
                bonusRuleApi.getStackedBonus(),
                masterdataApi.getBonusPeriods(),
            ]);

            const measureMap = measures.reduce((acc, m) => {
                acc[m.key] = m;
                return acc;
            }, {});
            const filteredMeasures = Object.values(measureMap);
            const ruleTypes = filteredMeasures
                .filter((m) => m.dateSpecific && m.key !== "CUSTOMER_RECRUITMENT")
                .map((m) => {
                    return {
                        value: m.key,
                        label: m.description,
                    };
                });

            const createFilteredOptions = (list, includeValue) => (input) => {
                const lowerInput = (
                    input && typeof input === 'string' ? input : ''
                ).toLowerCase();
                const filtered = list
                    .filter(
                        (b) =>
                            b.value.toLowerCase().includes(lowerInput) ||
                            b.label.toLowerCase().includes(lowerInput)
                    )
                    .sort((y) => y.value.length)
                    .slice(0, 10);
                if (
                    includeValue &&
                    filtered.map((y) => y.value).indexOf(input) < 0
                ) {
                    return [{ value: input, label: input }, ...filtered];
                }
                return filtered;
            };
            const searchBrands = createFilteredOptions(brands);
            const searchModels = createFilteredOptions(models, true);
            const searchGroups = createFilteredOptions(groups, true);
            const modelTypesItems = modelTypes.map((c) => ({
                value: c.id,
                label: `${c.id} - ${c.description}`,
            }));
            const keyValueArgs = createKeyValueArgs({
                categories,
                searchArticles: searchApi.searchArticles,
                searchModels,
                searchBrands,
                searchGroups,
                modelTypesItems,
            });
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            setBonusPeriods(periods);

            mounted
                ? setState({
                      keyValueArgs,
                      ruleTypes,
                      operatingChains,
                      data,
                      operatingChainOptions,
                  })
                : '';
        }
        getData();
        return () => (mounted = false);
    }, [apiFactory]);

    const columns = useMemo(() => {
        if (state.operatingChains) {
            const fixedColumns = [
                columnTypes.operatingChain.column(),
                columnTypes.staffType.column(COLUMN_KEYS.STAFF_TYPE),
                columnTypes.ruleType.column(COLUMN_KEYS.RULE_TYPE),
                columnTypes.keyValue.column(true, COLUMN_KEYS.RULE_VALUE),
                columnTypes.ruleMetric.column(),
                columnTypes.limit.column(),
                columnTypes.rewardType.column(),
                columnTypes.rewardValue.column(),
                columnTypes.fiscalKey.column(true),
                columnTypes.startDate.column(true),
                columnTypes.endDate.column(true),
            ];
            return [...fixedColumns];
        } else {
            return [];
        }
    }, [state]);

    const isSameRule = (ruleOne, ruleTwo) => (
        ruleOne.operatingChain === ruleTwo.operatingChain
        && ruleOne.staffType === ruleTwo.staffType
        && ruleOne.ruleType === ruleTwo.ruleType
        && returnNullOrValue(ruleOne.ruleValue) === returnNullOrValue(ruleTwo.ruleValue)
        && ruleOne.ruleMetric === ruleTwo.ruleMetric
        && ruleOne.rewardType === ruleTwo.rewardType
        && returnNullOrValue(ruleOne.fiscalKey) === returnNullOrValue(ruleTwo.fiscalKey)
        && returnNullOrValue(ruleOne.startDate) === returnNullOrValue(ruleTwo.startDate)
        && returnNullOrValue(ruleOne.endDate) === returnNullOrValue(ruleTwo.endDate)
    );

    const createNewObject = (bonusEntries) => {
        const newBonusEntries = [];
        let ruleId = 1;
        bonusEntries.forEach((newBonusEntry, index) => {
            const entry = {
                id: newBonusEntry.id + '#' + index,
                altId: newBonusEntry.id,
                operatingChain: newBonusEntry.operatingChain,
                ruleType: newBonusEntry.ruleType,
                ruleValue: newBonusEntry.ruleValue,
                staffType: newBonusEntry.staffType,
                ruleMetric: newBonusEntry.ruleMetric,
                limit: newBonusEntry.limitIncl,
                rewardType: newBonusEntry.rewardType,
                rewardValue: newBonusEntry.rewardValue,
                startDate: !newBonusEntry.startDate ? '' : newBonusEntry.startDate,
                endDate: !newBonusEntry.endDate ? '' : newBonusEntry.endDate,
                fiscalKey: newBonusEntry.fiscalKey,
            };

            if (index !== 0) {
                ruleId = isSameRule(entry, newBonusEntries[index - 1]) ? ruleId : ruleId + 1;
            };

            entry[COLUMN_KEYS.RULE_ID] = ruleId;
            newBonusEntries.push(entry);
        });
        return newBonusEntries;
    };

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row.id = uuidv4();
        row.altId = row.id;
        row[columnName] = { ...row[columnName], ...{ value } };

        if (columnName === COLUMN_KEYS.RULE_TYPE) {
            row[COLUMN_KEYS.RULE_VALUE] = columnTypes.keyValue.cell(
                null,
                value,
                state.keyValueArgs
            );
        }

        if (columnName === COLUMN_KEYS.FISCAL_KEY) {
            const periodDates = getDatesFromPeriod(value, bonusPeriods);
            row[COLUMN_KEYS.START_DATE] = columnTypes.startDate.cell(periodDates ? periodDates.startDate : '');
            row[COLUMN_KEYS.END_DATE] = columnTypes.endDate.cell(periodDates ? periodDates.endDate : '');
        }

        if ([COLUMN_KEYS.START_DATE, COLUMN_KEYS.END_DATE].includes(columnName)) {
            const startDate = columnName === COLUMN_KEYS.START_DATE ? value : row[COLUMN_KEYS.START_DATE].value;
            const endDate = columnName === COLUMN_KEYS.END_DATE ? value : row[COLUMN_KEYS.END_DATE].value;
            const period = getPeriodFromDates(startDate, endDate, bonusPeriods);
            row[COLUMN_KEYS.FISCAL_KEY] = columnTypes.fiscalKey.cell((period && period.isPeriodMatch) ? period.fiscalKey : '');
        }

        setTableData(newTableData);
    };

    const newRow = () => {
        const total = {
            id: uuidv4(),
            ruleType: '',
            ruleValue: null,
            ruleMetric: '',
            staffType: '',
            limit: 0,
            rewardType: '',
            rewardValue: 0,
            startDate: '',
            endDate: '',
            fiscalKey: '',
            operatingChains: '',
        };
        total.altId = total.id;
        const row = createRow(
            state.ruleTypes,
            state.keyValueArgs,
            state.operatingChainOptions
        )(total);
        const newTableData = [...tableData, row];
        setTableData(newTableData);
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteSelected = () => setTableData(removeSelected(tableData));

    const toggleItem = (id) => setTableData(toggleRow(id, tableData));

    const toggleAll = (ids, shouldToggle) => {
        setTableData(toggleAllRows(ids, shouldToggle, tableData));
    };

    const hasSameValues = (valueOne, valueTwo) => {
        return (
            valueOne.operatingChain === valueTwo.operatingChain.value &&
            valueOne.staffType === valueTwo.staffType.value &&
            valueOne.ruleType === valueTwo.ruleType.value &&
            returnNullOrValue(valueOne.ruleValue) === returnNullOrValue(valueTwo.ruleValue.value) &&
            valueOne.ruleMetric === valueTwo.ruleMetric.value &&
            valueOne.limit === valueTwo.limit.value &&
            valueOne.rewardType === valueTwo.rewardType.value &&
            hasSameDates(valueOne, valueTwo, bonusPeriods)
        );
    };

    const activeFilter = (filter, row) => {
        if (!filter.activeFilter || filter.activeFilter.value === '') {
            return true;
        }
        let startDate;
        let endDate;

        if (!row.startDate.value || row.startDate.value === '') {
            const dates = getDatesFromPeriod(row.fiscalKey.value, bonusPeriods);
            startDate = Date.parse(dates.startDate);
            endDate = Date.parse(dates.endDate);
        } else {
            startDate = new Date(row.startDate.value);
            endDate = new Date(row.endDate.value);
        }
        const now = new Date();

        if (filter.activeFilter.value === 'Passed' && endDate < now) {
            return true;
        }
        if (filter.activeFilter.value === 'Future' && startDate > now) {
            return true;
        }
        if (
            filter.activeFilter.value === 'Active' &&
            startDate <= now &&
            endDate >= now
        ) {
            return true;
        }
        return false;
    };

    const rows = useMemo(() => {
        const filter = filters.composeFilters([
            filters.operatingChain,
            activeFilter,
        ])(filterSpec);
        const filtered = tableData.filter(filter);
        return [...filtered];
    }, [filterSpec, tableData]);

    // Convert table data to string
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) => 
                    `${row.operatingChain.value}\t${row.staffType.value}\t${
                    row.ruleType.value}\t${row.ruleValue.value}\t${
                    row.ruleMetric.value}\t${row.limit.value}\t${
                    row.rewardType.value}\t${row.rewardValue.value}\t${
                    row.fiscalKey.value}\t${row.startDate.value}\t${
                    row.endDate.value}`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    const sampleCSV = () => {
        return `OCSEELG\tSALES\tMODELTYPECODE\tINS\tQTY\t5\tPOINTS_FIXED\t10000\t202304\t\t
OCSEELG\tSALES\tMODELTYPECODE\tINS\tQTY\t11\tPOINTS_FIXED\t20000\t202304\t\t
OCSEELG\tSALES\tMODELTYPECODE\tINS\tQTY\t15\tPOINTS_FIXED\t30000\t202304\t\t
OCNOELK\tSALES\tOUTLET\t\tREV\t10000\tAMOUNT\t3\t\t2023-08-01\t2023-08-20
OCNOELK\tSALES\tOUTLET\t\tREV\t20000\tAMOUNT\t4\t\t2023-08-01\t2023-08-20
OCNOELK\tSALES\tOUTLET\t\tREV\t50000\tAMOUNT\t5\t\t2023-08-01\t2023-08-20
OCDKELG\tOPERATIONS\tARTICLECODE\t47573\tQTY\t3\tAMOUNT_FIXED\t50\t202303\t\t
OCDKELG\tOPERATIONS\tARTICLECODE\t47573\tQTY\t6\tAMOUNT_FIXED\t100\t202303\t\t
OCDKELG\tOPERATIONS\tARTICLECODE\t47573\tQTY\t11\tAMOUNT_FIXED\t200\t202303\t\t
OCDKELG\tOPERATIONS\tARTICLECODE\t47573\tQTY\t16\tAMOUNT_FIXED\t300\t202303\t\t
OCDKELG\tOPERATIONS\tARTICLECODE\t47573\tQTY\t20\tAMOUNT_FIXED\t400\t202303\t\t`;
    };

    const returnNullOrValueExtended = (str) => {
        if (returnNullOrValue(str)) {
            return str === 'NULL' ? undefined : str;
        }
    };

    const addCsvData = (data) => {
        const createItem = (row) => {
            const item = {
                id: uuidv4(),
                selected: false,
                operatingChain: row.items[0],
                staffType: row.items[1],
                ruleType: row.items[2].toUpperCase(),
                ruleValue: returnNullOrValueExtended(row.items[3].toUpperCase()),
                ruleMetric: returnNullOrValue(row.items[4].toUpperCase()),
                limit: row.items[5],
                rewardType: returnNullOrValue(row.items[6]),
                rewardValue: row.items[7]?.replace(',', '.'),
                fiscalKey: row.items[8],
                startDate: row.items[9],
                endDate: row.items[10],
            };
            item.altId = item.id;
            item.id = item.id + '#' + 1;
            return item;
        };

        const input = data.data.map(createItem);

        const newRows = input
            .map(
                createRow(
                    state.ruleTypes,
                    state.keyValueArgs,
                    state.operatingChainOptions
                )
            );
        setTableData([...tableData, ...newRows]);
    };

    const ruleTypeValidator = (ruleTypes) => (row) => {
        const ruleType = row[getColumnIndex(COLUMN_KEYS.RULE_TYPE)];
        const validValues = ruleTypes.map((rt) => rt.value);
        const validKey = validValues.indexOf(ruleType) >= 0;
        return {
            isValid: validKey,
            errorMessage: validKey
                ? ''
                : 'Invalid rule type, valid values are: ' +
                  validValues.join(','),
        };
    };

    const ruleValueValidator = (keyValueArgs) => async (row) => {
        const ruleValue = row[getColumnIndex(COLUMN_KEYS.RULE_VALUE)];
        const ruleType = row[getColumnIndex(COLUMN_KEYS.RULE_TYPE)];
        const optionsForRuleType = columnTypes.keyValue.getOptions(keyValueArgs, ruleType);
        const ruleTypesWithoutKeyValue = [
            'OUTLET',
            'B2B',
            'KITCHEN',
            'CUSTOMER_RECRUITMENT',
        ];

        const options = Array.isArray(optionsForRuleType)
            ? optionsForRuleType
            : await optionsForRuleType(ruleValue);
        const isValid =
            options.map((o) => o.value).indexOf(ruleValue) >= 0 ||
            (ruleTypesWithoutKeyValue.includes(ruleType) &&
            (!ruleValue || ruleValue === null || ruleValue === ''));
        return {
            isValid: isValid,
            errorMessage: isValid ? '' : 'Invalid rule value',
        };
    };

    const dateValidator = (cellIndex) => (row) => {
        const dateStr = row[cellIndex];
        const isValid = ( 
            (!dateStr || dateStr === '' ) ||
            (dateStr.split('-').length === 3 && Date.parse(dateStr))
        );

        return {
            isValid: isValid,
            errorMessage: isValid ? '' : 'Invalid date, valid format YYYY-MM-DD',
        };
    };

    const fiscalKeyValidator = (cellIndex, bonusPeriods) => (row) => {
        const value = row[cellIndex]?.trim();
        const pattern = /^\d{6}$/;

        let isValid = (!value || value === '')
          || (pattern.test(value) && Number(value.substring(0, 4)) > 2000
          && Number(value.substring(4, 6)) > 0 && Number(value.substring(4, 6)) <= 12);

        let errMessage = isValid ? '' : 'Invalid period, valid format is YYYYMM';

        if (isValid && !!value && value !== '') {
            isValid = existsPeriod(value, bonusPeriods);
            errMessage = isValid ? '' : 'Not existing period';
        };

        return {
            isValid,
            errMessage,
        }
    };

    const limitValidation = (cellIndex) => (row) => {
        const value = row[cellIndex]?.toString().replace(',', '.');
        const number = Number(value);
        const isValid = !isNaN(number) && number > 0;
        return {
            isValid: isValid,
            errorMessage: isValid ? '' : 'Invalid limit, the value is not a positive number',
        };
    };

    const rewardValueValidation = (cellIndex) => (row) => {
        const value = row[cellIndex]?.toString().replace(',', '.');
        const number = Number(value);
        const isValid = !isNaN(number) && number > 0;
        return {
            isValid: isValid,
            errorMessage: isValid ? '' : 'Invalid reward value, the value is not a positive number',
        };
    };

    const getColumnIndex = (accessor) => (columns.map(i => i.accessor).indexOf(accessor));

    const rowValidator = () => (row) => {
        const fiscalKey = row[getColumnIndex(COLUMN_KEYS.FISCAL_KEY)];
        const startDate = row[getColumnIndex(COLUMN_KEYS.START_DATE)];
        const endDate = row[getColumnIndex(COLUMN_KEYS.END_DATE)];

        let isValid = (!!fiscalKey && fiscalKey !== '')
        || (!!startDate && startDate !== '' && !!endDate && endDate !== '');

        let errorMessage = isValid ? '' :'Period or Start date + End date must be filled';

        if (isValid && !!fiscalKey && fiscalKey !== '') {
            isValid = existsPeriod(fiscalKey, bonusPeriods);
            errorMessage = isValid ? '' : "Period does not exists"
        }

        if (isValid) {
            if (startDate && startDate !== ''  && endDate && endDate !== '' && !!fiscalKey && fiscalKey !== '') {
                const dates = getDatesFromPeriod(fiscalKey, bonusPeriods);
                isValid = (dates.startDate === startDate && dates.endDate === endDate);
            };
            errorMessage = isValid ? '' : 'Start date and End data must match the period';
        }

        if (isValid) {
            isValid = ((!startDate || startDate === '' ) && (!endDate || endDate === '' ))
              || Date.parse(startDate) <= Date.parse(endDate);
            errorMessage = isValid ? '' : 'Start date must be before endDate';
        }

        if (isValid) {
            isValid = ((!startDate || startDate === '' ) && (!endDate || endDate === '' ))
              || getPeriodFromDates(startDate, endDate, bonusPeriods) !== undefined;
            errorMessage = isValid ? '' : 'Start date and End date are not in the same period';
        }

        return {
            isValid,
            errorMessage,
        };
    };

    const rowValidators = [
        rowValidator(),
        ruleTypeValidator(state.ruleTypes),
        ruleValueValidator(state.keyValueArgs),
        dateValidator(getColumnIndex(COLUMN_KEYS.START_DATE)),
        dateValidator(getColumnIndex(COLUMN_KEYS.END_DATE)),
        fiscalKeyValidator(getColumnIndex(COLUMN_KEYS.FISCAL_KEY), bonusPeriods),
        limitValidation(getColumnIndex(COLUMN_KEYS.LIMIT)),
        rewardValueValidation(getColumnIndex(COLUMN_KEYS.REWARD_VALUE)),
    ];

    const csvRowValidator = async (row) => {
        const validationResults = await Promise.all(
            rowValidators.map((v) => v(row))
        );
        const result = validationResults.reduce(
            (acc, curr) => {
                return {
                    isValid: acc.isValid && curr.isValid,
                    text:
                        curr.errorMessage === ''
                            ? acc.text
                            : [...acc.text, curr.errorMessage],
                };
            },
            { isValid: true, text: [] }
        );
        return {
            isValid: result.isValid,
            text: result.isValid
                ? result.text.filter((y) => y != '')
                : result.text,
        };
    };

    const tableValidator = async (tableData) => {
        const result = [];

        for (const tableRow of tableData) {
            const row = [
                tableRow.operatingChain.value,
                tableRow.staffType.value,
                tableRow.ruleType.value,
                tableRow.ruleValue.value,
                tableRow.ruleMetric.value,
                tableRow.limit.value,
                tableRow.rewardType.value,
                tableRow.rewardValue.value,
                tableRow.fiscalKey.value,
                tableRow.startDate.value,
                tableRow.endDate.value,
            ];

            const validationResults = await csvRowValidator(row);
            if (validationResults && Object.keys(validationResults).length > 0) {
                validationResults['rowId'] = tableRow.id;
                result.push(validationResults);
            }
        };

        return result;
    };

    const { returnNullOrValue } = validators;

    const saveData = async () => {
        let isValid = true;
        let message = '';
        setInvalidRowIds([]);

        //validate each table row
        const result = await tableValidator(tableData);
        const messages = [];

        if (result && result.length > 0) {
            result.forEach((item, index) => {
                if (item.isValid === false) {
                    messages.push(`row ${index + 1}: ${item.text.toString()}`);
                    setInvalidRowIds((prev) => [...prev, item.rowId]);
                }
            });
            isValid = (messages.length > 0) ? false : true;
        }

        //search for duplicities
        const formattedBonuses = [];

        if (isValid) {
            tableData.forEach((bonusRule) => {
                const duplicitRow = formattedBonuses.find((row) => hasSameValues(row, bonusRule));

                if (!duplicitRow)
                {
                    let periodDates;
                    //fill startDate and endDate if only fiscalKey is selected
                    if (returnNullOrValue(bonusRule.fiscalKey.value)) {
                        periodDates = getDatesFromPeriod(bonusRule.fiscalKey.value, bonusPeriods);
                    };

                    let periodResult;
                    //fill fiscalKey if only startDate and endDate are filled (and if matches the whole period)
                    if (returnNullOrValue(bonusRule.startDate.value) && returnNullOrValue(bonusRule.endDate.value)
                      && !returnNullOrValue(bonusRule.fiscalKey.value)) {
                        const period = getPeriodFromDates(bonusRule.startDate.value, bonusRule.endDate.value, bonusPeriods);
                        if (period && period.isPeriodMatch) {
                            periodResult = period.fiscalKey
                        }
                    }

                    const formattedBonus = {
                        id: bonusRule.altId,
                        operatingChain: bonusRule.operatingChain.value,
                        staffType: bonusRule.staffType.value,
                        ruleType: bonusRule.ruleType.value,
                        ruleValue: bonusRule.ruleValue.value,
                        ruleMetric: bonusRule.ruleMetric.value,
                        limit: bonusRule.limit.value,
                        rewardType: bonusRule.rewardType.value,
                        rewardValue: bonusRule.rewardValue.value,
                        startDate: periodDates ? periodDates.startDate : bonusRule.startDate.value,
                        endDate: periodDates ? periodDates.endDate : bonusRule.endDate.value,
                        fiscalKey: periodResult ? periodResult.fiscalKey : returnNullOrValue(bonusRule.fiscalKey.value),
                    };
                    formattedBonuses.push(formattedBonus);
                } else {
                    isValid = false;
                    message = 'Table contains duplicit rows.'
                    setInvalidRowIds((prev) => [...prev, bonusRule.id]);
                }
            });
        };

        if (isValid) {
            const bonusRuleApi = apiFactory.bonusRuleApi;
            const saveFunction = bonusRuleApi.saveStackedBonus;
            await saveStackedBonusGeneral(saveFunction)(
                formattedBonuses,
                simulationIdState.get()[0].simulationId,
            );
            const savedData = await (bonusRuleApi.getStackedBonus(true));
            setState({ ...state, data: savedData });
        }

        return {
            valid: isValid,
            message: messages.length > 0 ? messages : message,
        };
    };

    const copySelectedRule = (rules) => {
        const rulesTableData = rules.map(createRow(
            state.ruleTypes,
            state.keyValueArgs,
            state.operatingChainOptions
        ));
        const newTableData = [
            ...tableData,
            ...rulesTableData,
        ];

        setTableData(newTableData);
    };

    const existsSelectedRow = rows.find(item => item.selected) ? true: false;

    //separate individual rules with a border, show error border
    const getRowBorderStyle = (row, previousRow) => {
        let style = {};

        if (previousRow && row.original.ruleId !== previousRow.original.ruleId) {
            style = {'borderTop': '1px solid black'};
        }
        //error border can override previous border style
        if (invalidRowIds && invalidRowIds.length > 0) {
            style = invalidRowIds.find(id => id === row.original.id)
                ? {'border': '2px solid red'}
                : style
        };

        return style
    };

    const csvColumnNames = [
            'Operating Chain',
            'Staff Type',
            'Rule Type',
            'Rule Value',
            'Rule Metric',
            'Limit',
            'Reward Type',
            'Reward Value',
            'Period',
            'Start Date',
            'End Date',
        ];

    return isLoaded ? (
        <Table
            data={rows}
            columns={columns}
            keyValueArgs={state.keyValueArgs}
            ruleTypes={state.ruleTypes}
            onUpdateData={updateData}
            onRemoveRow={deleteRow}
            onNewRow={newRow}
            onSaveData={saveData}
            onToggleItem={toggleItem}
            onDeleteSelected={deleteSelected}
            onToggleAll={toggleAll}
            onCopyRule={copySelectedRule}
            showCopyRule={true}
            enableCopyRule={existsSelectedRow}
            showNewRuleBtn={true}
            sampleCSV={sampleCSV}
            rowsCSV={rowsCSV}
            addCsvData={addCsvData}
            csvRowValidator={csvRowValidator}
            csvColumns={csvColumnNames}
            showDeleteAllBtn={false}
            getRowBorderStyle={getRowBorderStyle}
            autoResetPage={false}
            components={{
                filter: (
                    <Filter
                        filter={filterSpec}
                        onChange={setFilterSpec}
                        operatingChainOptions={state.operatingChainOptions}
                    />
                ),
            }}
        />
    ) : (
        <div className="w-full text-center">
            <Loader />
        </div>
    );
}
