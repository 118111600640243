import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Loader from '../../components/Loader';
import { buttons, CSVForm } from '../../components/index';
import * as XLSX from 'xlsx';
import AsyncSelect from 'react-select/async';
import AcceptedInvitations from './AcceptedInvitations';
import CompetitionDetails from './CompetitionDetails';
import PendingInvitations from './PendingInvitations';
import RejectedInvitations from './RejectedInvitations';
import { useDependencies } from '../../DependencyProvider';
import ErrorMsg from '../../components/ErrorMsg';
import { competitionColumnTypes } from '../../components/DisplayTable/index';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { competitionUtils } from '@/utils';

const ShowCompetitionDetails = ({
    onClose,
    currentPeriod,
    updateCompetitionList,
    setIsSaving,
    validPeriodOptions,
    allCompetitions,
    chosenCompetitionId,
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const { apiFactory } = useDependencies();
    const { employeeFactorsApi, masterdataApi } = apiFactory;
    const [state, setState] = useState({});
    const [removeParticipant, setRemoveParticipant] = useState();
    const [unsavedState, setUnsavedState] = useState(false);
    const [allRemovedParticpants, setAllRemovedParticpants] = useState([]);
    const [acceptedParticipants, setAcceptedParticipants] = useState([]);
    const [pendingParticipants, setPendingParticipants] = useState([]);
    const [rejectedParticipants, setRejectedParticipants] = useState([]);
    const [addParticipantsFrom, setAddParticipantsFrom] = useState('dropdown');
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [filteredStoreIds, setFilteredStoreIds] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [storeOptions, setStoreOptions] = useState([]);
    const [newParticipants, setNewParticipants] = useState([]);
    const [newCompName, setNewCompName] = useState('');
    const [selectedExpirationDate, setSelectedExpirationDate] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [chosenCompetitionDetails, setChosenCompetitionDetails] = useState(
        {}
    );
    const [tabIndex, setTabIndex] = useState(0);
    const {
        formatEmployeeLabel,
        EmployeeValueLabel,
        selectStyles,
        sampleCSV,
        activeParticipantsSelect,
        selectedTab,
        unselectedTab,
        filterEmployeeOptions,
        isValidResponse,
        getCompleteSelectedParticipants,
    } = competitionUtils;

    const parameterMapper = useMemo(() => {
        return {
            totalPoints: 'Total Points',
            payout: 'Payout',
            individualPoints: 'Individual Points',
            insurancePoints: 'Insurance Points',
            addedValueServicesPoints: 'Added Value Services Points',
            subscriptionPoints: 'Subscription Points',
            recurringInsurance: 'Recurring Insurance',
            accessoriesPoints: 'Accessories Points',
            outletPoints: 'Outlet Points',
            kitchenPoints: 'Kitchen Points',
            installationPoints: 'Installation Points',
            revenuePoints: 'Revenue Points',
            gmPoints: 'GM Points',
            customerRecruitmentPoints: 'Customer Recruitment Points',
        };
    }, []);

    const parameterPointsMapper = useMemo(() => {
        return {
            totalPoints: 0,
            payout: 0,
            individualPoints: 0,
            insurancePoints: 0,
            addedValueServicesPoints: 0,
            subscriptionPoints: 0,
            recurringInsurance: 0,
            accessoriesPoints: 0,
            outletPoints: 0,
            kitchenPoints: 0,
            installationPoints: 0,
            revenuePoints: 0,
            gmPoints: 0,
            customerRecruitmentPoints: 0,
        };
    }, []);

    useEffect(() => {
        if (chosenCompetitionDetails.id) {
            setState({
                id: chosenCompetitionDetails.id,
                createdAt: chosenCompetitionDetails.createdAt,
                createdBy: chosenCompetitionDetails.createdBy,
                displayParameters: chosenCompetitionDetails.displayParameters,
                mainParameter: chosenCompetitionDetails.mainParameter,
                name: chosenCompetitionDetails.name,
                period: chosenCompetitionDetails.period,
                participants: chosenCompetitionDetails.participants,
                opchain: chosenCompetitionDetails.opchain,
                numOfAccepted: chosenCompetitionDetails.numOfAccepted,
                startDate: chosenCompetitionDetails.startDate,
                endDate: chosenCompetitionDetails.endDate,
            });
        }
    }, [chosenCompetitionDetails]);

    useEffect(() => {
        async function getData() {
            const [employees, stores] = await Promise.all([
                masterdataApi.getEmployees(),
                masterdataApi.getStores(),
            ]);

            const allEmployeeOptions = employees.map((employee) => ({
                label: `${employee.firstname} ${employee.lastname}`,
                employeeId: employee.employeeId,
                storeId: employee.storeId,
                username: employee.username,
                value: employee.employeeId,
            }));

            const allStoreOptions = stores.map((store) => ({
                label: store.departmentName,
                value: store.storeId,
                operatingChain: store.operatingChain,
            }));

            setEmployeeOptions(allEmployeeOptions);
            setStoreOptions(allStoreOptions);
        }
        getData();
    }, [employeeFactorsApi, masterdataApi]);

    useEffect(() => {
        const comp = allCompetitions.find(
            (obj) => obj.id === chosenCompetitionId
        );

        async function fetchParticipantsPoints(competitionDetailsData) {
            const { competitionsApi } = apiFactory;
            const participantsPointsData =
                await competitionsApi.getParticipantsPoints({
                    competitionId: competitionDetailsData.id,
                });

            competitionDetailsData.participants =
                competitionDetailsData.participants.map((obj) => {
                    const points = participantsPointsData.data.find(
                        (part) => part.username === obj.toUser
                    );

                    if (points === undefined) {
                        return {
                            ...obj,
                            ...parameterPointsMapper,
                        };
                    } else {
                        return {
                            ...obj,
                            ...points,
                        };
                    }
                });

            setChosenCompetitionDetails(competitionDetailsData);
        }
        if (comp) {
            fetchParticipantsPoints(comp);
        } else {
            setChosenCompetitionDetails(comp);
        }
    }, [
        allCompetitions,
        chosenCompetitionId,
        apiFactory,
        parameterPointsMapper,
    ]);

    useEffect(() => {
        if (state.participants) {
            setState({
                ...state,
                participants: state.participants.filter(
                    (part) => part.toUser !== removeParticipant
                ),
            });
        }
    }, [removeParticipant]);

    useEffect(() => {
        if (state.participants) {
            setAcceptedParticipants(
                state.participants.filter(
                    (p) => p.acceptedAt !== null && p.rejectedAt === null
                )
            );

            setPendingParticipants(
                state.participants.filter(
                    (p) =>
                        p.acceptedAt === null &&
                        p.rejectedAt === null &&
                        !isExpired(p.expireAt)
                )
            );
            setRejectedParticipants(
                state.participants.filter(
                    (p) =>
                        p.rejectedAt !== null ||
                        (isExpired(p.expireAt) && p.acceptedAt === null)
                )
            );
            setIsLoaded(true);
        }
    }, [state.participants]);

    useEffect(() => {
        if (state.opchain) {
            const chosenStores = storeOptions.filter(
                (store) => store.operatingChain === state.opchain
            );

            setFilteredStoreIds(
                chosenStores.map((store) => store.value).sort((a, b) => a - b)
            );
        }
    }, [state.opchain, storeOptions]);

    // Filters employees available in dropdown select
    useEffect(() => {
        if (employeeOptions) {
            const availableEmployees = employeeOptions.filter(
                (employee) =>
                    filteredStoreIds.includes(employee.storeId) && employee
            );

            const unavailableEmployees =
                acceptedParticipants.concat(pendingParticipants);

            const filteredAvailableEmployees = availableEmployees.filter(
                (empl) =>
                    !unavailableEmployees.some(
                        (notempl) =>
                            notempl.toUser === empl.username &&
                            notempl.toUserStoreId === empl.storeId
                    )
            );

            setFilteredEmployees(filteredAvailableEmployees);
        }
    }, [
        filteredStoreIds,
        employeeOptions,
        acceptedParticipants,
        pendingParticipants,
    ]);

    useEffect(() => {
        if (newParticipants.length === 0 && newCompName === '') {
            setUnsavedState(false);
        }
    }, [newParticipants, newCompName]);

    const isExpired = (expirationDate) => {
        return new Date(expirationDate).getTime() < new Date().getTime();
    };

    const removeParticipantRow = useCallback(
        (row) => {
            setUnsavedState(true);
            setRemoveParticipant(row.toUser);
            setAllRemovedParticpants([
                ...allRemovedParticpants,
                { username: row.toUser, storeId: row.toUserStoreId },
            ]);
        },
        [allRemovedParticpants]
    );

    const acceptedColumns = useMemo(() => {
        const deleteCompetitionButton = (row) => {
            return (
                <buttons.Delete
                    text=""
                    customClass="flex justify-center"
                    onDelete={() => removeParticipantRow(row.original)}
                />
            );
        };
        const fixedColumns = [
            competitionColumnTypes.detailUsername.column(),
            competitionColumnTypes.storeId.column(),
        ];

        if (state.period <= currentPeriod.periodId) {
            fixedColumns.push(
                competitionColumnTypes.mainParameterPoints.column(
                    state.mainParameter,
                    `${Object.keys(parameterMapper).find(
                        (k) => parameterMapper[k] === state.mainParameter
                    )}`
                )
            );
        }
        if (
            state.period <= currentPeriod.periodId &&
            state.displayParameters.length > 0
        ) {
            state.displayParameters.forEach((dp) => {
                fixedColumns.push(
                    competitionColumnTypes.otherParameter.column(
                        dp,
                        `${Object.keys(parameterMapper).find(
                            (k) => parameterMapper[k] === dp
                        )}`
                    )
                );
            });
        }
        if (tabIndex === 1) {
            fixedColumns.push(
                competitionColumnTypes.buttonColumn.column(
                    'delte-button-column',
                    deleteCompetitionButton,
                    true
                )
            );
        }

        return [...fixedColumns];
    }, [
        state.displayParameters,
        parameterMapper,
        state.mainParameter,
        currentPeriod.periodId,
        state.period,
        removeParticipantRow,
        tabIndex,
    ]);

    const sortedAcceptedParticipants = useMemo(() => {
        const mainParameter = Object.keys(parameterMapper).find(
            (k) => parameterMapper[k] === state.mainParameter
        );
        const sorted = acceptedParticipants.sort(
            (a, b) => a[mainParameter] - b[mainParameter]
        );
        return sorted;
    }, [acceptedParticipants, parameterMapper, state.mainParameter]);

    // Convert table data to string
    const rowsCSV = useMemo(() => {
        if (state.participants && state.participants.length > 0) {
            const rowStrings = state.participants.map(
                (participant) =>
                    `${participant.toUser}\t${participant.toUserStoreId}`
            );

            return rowStrings.join('\n');
        }
    }, [state.participants]);

    const acceptedRowsCSV = useMemo(() => {
        if (
            sortedAcceptedParticipants &&
            sortedAcceptedParticipants.length > 0
        ) {
            const headerRowString = acceptedColumns
                .map((ac) => ac.Header)
                .join('\t');

            const accessors = acceptedColumns.map((ac) => ac.accessor);

            const rowStrings = sortedAcceptedParticipants
                .map((participant) => {
                    return accessors.map((acc) => participant[acc]).join('\t');
                })
                .map((participant) => participant.replaceAll('.', ','));

            rowStrings.reverse();

            return headerRowString + '\n' + rowStrings.join('\n');
        }
    }, [sortedAcceptedParticipants, acceptedColumns]);

    const downloadParticipantsCSV = () => {
        const data = acceptedRowsCSV.split('\n').map((r) => r.split('\t'));
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Table');
        XLSX.writeFile(workbook, 'participants_with_points.xlsx');
    };

    const downloadInvitesCSV = () => {
        const data = rowsCSV.split('\n').map((r) => r.split('\t'));
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Table');
        XLSX.writeFile(workbook, 'invited_employees.xlsx');
    };

    useEffect(() => {
        if (editMode === false) {
            setUnsavedState(false);
        }
    }, [editMode]);

    const isEditMode = (value) => {
        setEditMode(value);
    };

    const saveChanges = async () => {
        const { competitionsApi } = apiFactory;

        if (newParticipants.length && !selectedExpirationDate) {
            return setShowErrorMsg(true);
        }

        isEditMode(false);
        setUnsavedState(false);

        if (
            allRemovedParticpants.length ||
            newParticipants.length ||
            newCompName ||
            showExpirationDatePicker()
        ) {
            setIsSaving(true);
            const data = {
                competitionId: chosenCompetitionDetails.id,
                competitionName: newCompName
                    ? newCompName
                    : chosenCompetitionDetails.name,
                fromStoreId: chosenCompetitionDetails.fromStoreId,
                createdBy: chosenCompetitionDetails.createdBy,
                addParticipants: newParticipants.length
                    ? newParticipants.map((obj) => {
                          return {
                              username: obj.username,
                              storeId: obj.storeId,
                          };
                      })
                    : [],
                removeParticipants: allRemovedParticpants.length
                    ? allRemovedParticpants
                    : [],
                expireAt: showExpirationDatePicker()
                    ? selectedExpirationDate
                    : null,
            };
            await competitionsApi.updateCompetition(data);
            setIsSaving(false);
            updateCompetitionList();
            setNewParticipants([]);
            setSelectedExpirationDate('');
            setAllRemovedParticpants([]);
            setTabIndex(0);
        }
        setShowErrorMsg(false);
    };

    const handleTitleChange = (value) => {
        setUnsavedState(true);
        setNewCompName(value);
    };

    const loadOptions = (inputValue) => {
        const lowerInput = inputValue.toLowerCase();
        const options = filterEmployeeOptions(lowerInput, filteredEmployees);

        return new Promise((resolve) => resolve(options));
    };

    const handleAddCSV = (state) => {
        const completeNewSelectedParticipants = getCompleteSelectedParticipants(
            state,
            newParticipants,
            filteredEmployees
        );

        setUnsavedState(true);

        setNewParticipants(
            completeNewSelectedParticipants.filter(
                (v, i, a) =>
                    a.findIndex((v2) =>
                        ['username', 'storeId'].every((k) => v2[k] === v[k])
                    ) === i
            )
        );

        setAddParticipantsFrom('dropdown');
    };

    const validateRow = (row) => {
        return isValidResponse(row, filteredEmployees);
    };

    const findValidEndDate = () => {
        if (chosenCompetitionDetails.period) {
            return chosenCompetitionDetails.period ===
                validPeriodOptions[0].value
                ? validPeriodOptions[0].endDate
                : validPeriodOptions[1].endDate;
        }

        const endStr = chosenCompetitionDetails.endDate.toString();
        const year = endStr.slice(0, 4);
        const month = endStr.slice(4, 6);
        const day = endStr.slice(6);
        return `${year}-${month}-${day}`;
    };

    const showExpirationDatePicker = () => {
        return (
            state.period >= currentPeriod.periodId || newParticipants.length > 0
        );
    };

    const exclamationClasses = `bg-yellow-600 text-gray-200 p-2 px-10 ml-4 rounded transition-opacity`;

    return isLoaded ? (
        <div className="bg-gray-700 p-6 rounded-lg flex flex-col items-center h-6/6 overflow-y-auto">
            <div
                className="flex flex-1 h-full m-auto flex-col"
                style={{ maxWidth: '100rem' }}
            >
                {
                    <div
                        className={
                            'w-full pt-0 pb-0 ' +
                            (unsavedState ? 'visible' : 'invisible')
                        }
                    >
                        <div
                            className={
                                exclamationClasses + ' flex justify-between'
                            }
                        >
                            <span>
                                <span className="align-botton">
                                    {' '}
                                    Unsaved data
                                </span>
                            </span>
                        </div>
                    </div>
                }
            </div>
            <div className="w-full mb-4">
                <h1 className="font-bold h-10 text-2xl text-gray-50">
                    Competition Details
                </h1>
            </div>
            <Tabs
                className="w-full rounded-lg"
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
            >
                <TabList className="border-b-4 border-blue-500">
                    <Tab
                        selectedClassName="bg-blue-500 text-white rounded-t-lg w-2/12 text-center"
                        className="react-tabs__tab text-white w-2/12 text-center"
                    >
                        Details
                    </Tab>
                    <Tab
                        selectedClassName="bg-blue-500 text-white rounded-t-lg"
                        className="react-tabs__tab text-white w-2/12 text-center"
                    >
                        Edit
                    </Tab>
                </TabList>

                <TabPanel>
                    <div className="flex flex-row justify-center mt-5 w-full">
                        <CompetitionDetails
                            state={state}
                            downloadInvitesCSV={downloadInvitesCSV}
                            downloadParticipantsCSV={downloadParticipantsCSV}
                        />
                    </div>

                    <div className="w-full">
                        {acceptedParticipants.length > 0 && (
                            <div className="flex flex-row justify-center mt-5 w-full">
                                <AcceptedInvitations
                                    state={state}
                                    participants={acceptedParticipants}
                                    parameterMapper={parameterMapper}
                                    acceptedColumns={acceptedColumns}
                                    sortedAcceptedParticipants={
                                        sortedAcceptedParticipants
                                    }
                                />
                            </div>
                        )}
                        <div className="flex flex-row justify-between mt-5 w-full gap-8">
                            {pendingParticipants.length > 0 && (
                                <PendingInvitations
                                    participants={pendingParticipants}
                                    removeParticipant={removeParticipantRow}
                                />
                            )}
                            {rejectedParticipants.length > 0 && (
                                <RejectedInvitations
                                    participants={rejectedParticipants}
                                    removeParticipant={removeParticipantRow}
                                />
                            )}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <h2 className="font-bold text-xl text-gray-50 mt-5">
                        Parameters
                    </h2>
                    <div className="flex flex-col pr-4 mt-4">
                        <label className="text-gray-50 pr-2 py-2 font-medium">
                            Competition Name
                        </label>
                        <input
                            type="text"
                            defaultValue={state.name}
                            className="h-38px w-6/12 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md p-2 grow"
                            onChange={(e) => handleTitleChange(e.target.value)}
                        />
                    </div>
                    <span className="flex justify-between">
                        <div className="flex flex-col self-start w-full sm:pt-5 sm:pb-5">
                            <div className="flex flex-row items-center w-full gap-1">
                                <label className="text-gray-50 pr-2 py-2 font-medium">
                                    Add participants from...
                                </label>
                                <div
                                    className={
                                        addParticipantsFrom === 'dropdown'
                                            ? selectedTab
                                            : unselectedTab
                                    }
                                    onClick={() =>
                                        setAddParticipantsFrom('dropdown')
                                    }
                                >
                                    Dropdown
                                </div>
                                <div
                                    className={
                                        addParticipantsFrom === 'csv'
                                            ? selectedTab
                                            : unselectedTab
                                    }
                                    onClick={() =>
                                        setAddParticipantsFrom('csv')
                                    }
                                >
                                    CSV
                                </div>
                            </div>

                            {addParticipantsFrom === 'dropdown' ? (
                                <div className="flex justify-between sm:items-start w-full rounded-md">
                                    <span className={activeParticipantsSelect}>
                                        <AsyncSelect
                                            loadOptions={loadOptions}
                                            isMulti
                                            onChange={(e) => {
                                                setUnsavedState(true);
                                                setNewParticipants(
                                                    e,
                                                    ...newParticipants
                                                );
                                            }}
                                            formatOptionLabel={
                                                formatEmployeeLabel
                                            }
                                            components={{
                                                MultiValueLabel:
                                                    EmployeeValueLabel,
                                            }}
                                            styles={selectStyles}
                                            name="participants"
                                            menuPortalTarget={document.body}
                                            value={newParticipants}
                                        />
                                        {/* <ErrorMsg condition={showErrorMsg && selectedParticipants.length === 0 } errorMsg="This field is required"/> */}
                                    </span>
                                </div>
                            ) : null}

                            {addParticipantsFrom === 'csv' ? (
                                <div>
                                    <CSVForm
                                        columns={['Username', 'Store ID']}
                                        participants={true}
                                        sampleCSV={sampleCSV}
                                        onSave={handleAddCSV}
                                        validateRow={validateRow}
                                    />
                                </div>
                            ) : null}
                        </div>
                        {showExpirationDatePicker() && (
                            <div className="flex flex-col flex-grow gap-4 px-4 sm:pt-5 sm:pb-5 w-full justify-start">
                                <label
                                    htmlFor="expiration-date"
                                    className="text-gray-50 font-medium"
                                >
                                    Invitation expiration date
                                </label>
                                <span className="rounded-md">
                                    <input
                                        type="date"
                                        id="start"
                                        name="expiration-date"
                                        value={selectedExpirationDate}
                                        onChange={(e) => {
                                            setSelectedExpirationDate(
                                                e.target.value
                                            );
                                            setUnsavedState(true);
                                        }}
                                        min={new Date()
                                            .toISOString()
                                            .slice(0, -14)}
                                        max={findValidEndDate()}
                                        className="h-38px border p-2.5 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full"
                                    ></input>
                                    {
                                        <ErrorMsg
                                            condition={
                                                showErrorMsg &&
                                                !selectedExpirationDate
                                            }
                                            errorMsg="This field is required"
                                        />
                                    }
                                </span>
                            </div>
                        )}
                    </span>
                    <div className="w-full">
                        {acceptedParticipants.length > 0 && (
                            <div className="flex flex-row justify-center mt-5 w-full">
                                <AcceptedInvitations
                                    state={state}
                                    participants={acceptedParticipants}
                                    parameterMapper={parameterMapper}
                                    acceptedColumns={acceptedColumns}
                                    sortedAcceptedParticipants={
                                        sortedAcceptedParticipants
                                    }
                                />
                            </div>
                        )}
                        <div className="flex flex-row justify-between mt-5 w-full gap-8">
                            {pendingParticipants.length > 0 && (
                                <PendingInvitations
                                    participants={pendingParticipants}
                                    editMode={true}
                                    removeParticipant={removeParticipantRow}
                                />
                            )}
                            {rejectedParticipants.length > 0 && (
                                <RejectedInvitations
                                    participants={rejectedParticipants}
                                    editMode={true}
                                    removeParticipant={removeParticipantRow}
                                />
                            )}
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
            <div className="pt-5 self-end">
                <div className="flex w-full gap-4">
                    <buttons.Cancel
                        text="Close"
                        onCancel={onClose}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    />
                    <buttons.Save
                        text="Save"
                        enabled={unsavedState}
                        onSave={() => {
                            saveChanges();
                        }}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className="flex flex-col justify-center items-center p-8 pb-16">
            <Loader />
        </div>
    );
};

export default ShowCompetitionDetails;
