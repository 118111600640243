import React, { useState } from 'react';
import * as buttons from './buttons';
import Select from 'react-select';
import MultiSelect from './MultiSelect';
import { customSelectDropdown } from './style';

const CopyFrom = ({
    onCopy,
    onClose,
    fiscalKeyOptions,
    operatingChainOptions,
    opChainMultiSelect,
    fiscalKey,
    filterSpec,
    componentName,
}) => {
    const [selectedFiscalKey, setSelectedFiscalKey] = useState('');
    const [selectedOpChains, setSelectedOpChains] = useState([]);

    const handleFiscalSelectChange = (e) => {
        setSelectedFiscalKey(e);
    };

    const checkComponentName = (componentName) => {
        if (componentName === 'PayoutSystems') {
            return true;
        }
        return false;
    };

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <form className="flex flex-col space-y-2 w-full">
                <h2 className="font-bold text-center h-10 text-xl text-gray-50">
                    Copy data into{' '}
                    {checkComponentName(componentName) &&
                        filterSpec.operatingChain.label &&
                        `${filterSpec.operatingChain.label},`}{' '}
                    Period: {fiscalKey ? fiscalKey : 'General'}
                </h2>
                <div className="w-full flex flex-col">
                    <label className="text-gray-50">Period</label>
                    <Select
                        options={fiscalKeyOptions}
                        onChange={(e) => {
                            handleFiscalSelectChange(e);
                        }}
                        className="w-full inline-block text-gray-900"
                        classNamePrefix="select-sm"
                        styles={customSelectDropdown(32)}
                        value={selectedFiscalKey}
                        menuPortalTarget={document.body}
                    ></Select>
                </div>
                <div className="w-full flex flex-col">
                    <label className="text-gray-50">Operating chain</label>
                    {opChainMultiSelect ? (
                        <MultiSelect
                            options={operatingChainOptions}
                            onChange={(e) => {
                                setSelectedOpChains(e, ...selectedOpChains);
                            }}
                            className="w-full inline-block text-gray-900"
                            classNamePrefix="select-sm"
                            styles={customSelectDropdown(32)}
                            value={selectedOpChains}
                            menuPortalTarget={document.body}
                        ></MultiSelect>
                    ) : (
                        <Select
                            options={operatingChainOptions}
                            onChange={(e) => {
                                setSelectedOpChains(e);
                            }}
                            className="w-full inline-block text-gray-900"
                            classNamePrefix="select-sm"
                            styles={customSelectDropdown(32)}
                            value={selectedOpChains}
                            menuPortalTarget={document.body}
                        ></Select>
                    )}
                </div>
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Copy
                        text="Copy"
                        onClick={(e) => {
                            e.preventDefault();
                            onCopy(selectedFiscalKey, selectedOpChains);
                            onClose();
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

export default CopyFrom;
