import { memoizedMakeReqwithId, postRequest } from './apiCommon';

// prettier-ignore
const bonusRuleApi = (axios) => ({
    getTotals: memoizedMakeReqwithId(axios, 'bonus-rules/total'),
    saveTotals: postRequest(axios, 'bonus-rules/total'),
    getStorePoints: memoizedMakeReqwithId(axios, 'bonus-rules/store'),
    saveStorePoints: postRequest(axios, 'bonus-rules/store'),
    getStoreOperations: memoizedMakeReqwithId(axios, 'bonus-rules/store/operations'),
    saveStoreOperations: postRequest(axios, 'bonus-rules/store/operations'),
    getIndividualSales: memoizedMakeReqwithId(axios,'bonus-rules/individual/sales'),
    saveIndividualSales: postRequest(axios, 'bonus-rules/individual/sales'),
    getIndividualOperations: memoizedMakeReqwithId(axios,'bonus-rules/individual/operations'),
    saveIndividualOperations: postRequest(axios,'bonus-rules/individual/operations'),
    getRecurringPayments: memoizedMakeReqwithId(axios, 'bonus-rules/recurring-payment'),
    saveRecurringPayments: postRequest(axios, 'bonus-rules/recurring-payment'),
    getCustomerRecruitments: memoizedMakeReqwithId(axios, 'bonus-rules/customer/recruitment'),
    saveCustomerRecruitments: postRequest(axios, 'bonus-rules/customer/recruitment'),
    getDateSpecificOperations: memoizedMakeReqwithId(axios, 'bonus-rules/date-specific/operations'),
    saveDateSpecificOperations: postRequest(axios, 'bonus-rules/date-specific/operations'),
    getDateSpecificSales: memoizedMakeReqwithId(axios, 'bonus-rules/date-specific/sales'),
    saveDateSpecificSales: postRequest(axios, 'bonus-rules/date-specific/sales'),
    getRecurringParameters: memoizedMakeReqwithId(axios, 'bonus-rules/recurring-parameters'),
    getStackedBonus: memoizedMakeReqwithId(axios, 'bonus-rules/stacked-bonus'),
    saveStackedBonus: postRequest(axios, 'bonus-rules/stacked-bonus'),
});

export default bonusRuleApi;
