import bonusRuleApi from './bonusRuleApi';
import bonusSettingsApi from './bonusSettingsApi';
import articleMasterdataApi from './articleMasterdataApi';
import masterdataApi from './masterdataApi';
import searchApi from './searchApi';
import employeeFactorsApi from './employeeFactorsApi';
import employeeCorrectionsApi from './employeeCorrectionsApi';
import payoutSystemsApi from './payoutSystemsApi';
import targetsApi from './targetsApi';
import simulationApi from './simulationApi';
import competitionsApi from './competitionsApi';
import axios from 'axios';
import { getEnvVar } from '@/hostedEnvironmentResolver';

export function createApiFactory(axios) {
    return {
        articleMasterdataApi: articleMasterdataApi(axios),
        bonusRuleApi: bonusRuleApi(axios),
        bonusSettingsApi: bonusSettingsApi(axios),
        employeeCorrectionsApi: employeeCorrectionsApi(axios),
        employeeFactorsApi: employeeFactorsApi(axios),
        masterdataApi: masterdataApi(axios),
        payoutSystemsApi: payoutSystemsApi(axios),
        searchApi: searchApi(axios),
        targetsApi: targetsApi(axios),
        simulationApi: simulationApi(axios),
        competitionsApi: competitionsApi(axios),
    };
}

export function createAxiosClient(accessToken) {
    const ax = axios.create({
        baseURL: window.location.origin + getEnvVar("REACT_APP_API_PATH"),
        headers: {
            'Content-type': 'application/json',
            ElkClient: 'BonusManager',
        },
    });

    ax.interceptors.request.use(async (config) => {
        const token = accessToken;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    });

    ax.interceptors.response.use(
        (response) => {
            // If access is regained after timeout, reset the 'refreshAuth' flag
            if (window.location.hash.indexOf('refreshed') > -1) {
                if (response.status === 200) {
                    window.location.hash = '';
                }
            }

            return response;
        },
        (error) => {
            // If unauthorized, reload page to refresh auth or trigger login
            if (error.response.status === 401) {
                if (window.location.hash.indexOf('refreshed') === -1) {
                    window.location = window.location + '#refreshed';
                    window.location.reload();
                }
            }

            return Promise.reject(error);
        }
    );
    return ax;
}
