import React from 'react';
import DisplayTable from '../../components/DisplayTable';

const AcceptedInvitations = ({
    acceptedColumns,
    sortedAcceptedParticipants,
}) => {
    return (
        <div className="w-full">
            <h2 className="font-bold h-10 text-xl text-gray-50">
                Accepted Invitations
            </h2>
            <DisplayTable
                columns={acceptedColumns}
                tableData={sortedAcceptedParticipants}
                competition={true}
                showPageOptions={false}
            />
        </div>
    );
};

export default AcceptedInvitations;
