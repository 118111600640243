import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useDependencies } from '@/DependencyProvider';
import {
    SimulationList,
    CreateSimulation,
    CopySimulation,
    PickSimulationOpchains,
} from '@/pages/simulation/index';
import { Loader, buttons } from '@/components';
import { useRender } from '@/renderContext';
import { isAuthorized, pages } from '@/utils';
import { useAuth } from '@/auth';

const Simulation = () => {
    const { apiFactory } = useDependencies();
    const [periods, setPeriods] = useState([]);
    const [operatingChains, setOperatingChains] = useState([]);
    const [simulationList, setSimulationList] = useState([]);
    const [chosenRowData, setChosenRowData] = useState(undefined);
    const [simulationPageLoading, setSimulationPageLoading] = useState(true);
    const [showCreateSimulation, setShowCreateSimulation] = useState(false);
    const [showCopySimulation, setShowCopySimulation] = useState(false);
    const [showPickSimulationOpchains, setShowPickSimulationOpchains] =
        useState(false);
    const [selectedSimulation, setSelectedSimulation] = useState(undefined);
    const { value } = useRender();
    const auth = useAuth();
    const roles = auth.roles;

    useEffect(() => {
        let mounted = true;
        const getData = async () => {
            mounted ? setSimulationPageLoading(true) : '';
            const { masterdataApi, bonusSettingsApi, simulationApi } =
                apiFactory;
            const [periodOptions, operatingChains, simulationList] =
                await Promise.all([
                    masterdataApi.getBonusPeriods(),
                    bonusSettingsApi.getOperatingChains(),
                    simulationApi.getSimulations(),
                ]);
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            if (mounted) {
                setPeriods(periodOptions);
                setOperatingChains(operatingChainOptions);
                setSimulationList(simulationList);
                setSimulationPageLoading(false);
            }
        };
        getData();
        return () => (mounted = false);
    }, [apiFactory, value]);

    const setPageLoading = async (value) => {
        setSimulationPageLoading(value);
    };

    const updateSimulationList = async () => {
        const { simulationApi } = apiFactory;
        const simulationList = await simulationApi.getSimulations(true);
        setSimulationList(simulationList);
        setSimulationPageLoading(false);
        setShowCreateSimulation(false);
        setShowCopySimulation(false);
    };

    const handleRunClick = (row) => {
        setShowPickSimulationOpchains(true);
        setChosenRowData(row);
    };

    const handleSimulationSelect = (row) => {
        setSelectedSimulation(row);
    };

    return (
        <>
            {!simulationPageLoading ? (
                <div className="flex flex-col items-center w-full">
                    <div className="flex flex-col justify-start w-11/12">
                        {isAuthorized(pages.SIMULATIONPAGE, roles) && (
                            <div className="flex flex-row gap-4">
                                <buttons.Button
                                    onClick={() =>
                                        setShowCreateSimulation(true)
                                    }
                                    text="Create new simulation"
                                    className={
                                        'mt-6 mb-6 w-max inline-flex items-center px-4 py-2 border border-transparent text-m font-bold rounded-md shadow-sm text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                    }
                                />
                                <buttons.Button
                                    onClick={() => setShowCopySimulation(true)}
                                    text="Copy selected simulation"
                                    className={
                                        'mt-6 mb-6 w-max inline-flex items-center px-4 py-2 border border-transparent text-m font-bold rounded-md shadow-sm text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                    }
                                    disabled={!selectedSimulation}
                                />
                            </div>
                        )}
                        <SimulationList
                            simulationList={simulationList}
                            setPageLoading={setPageLoading}
                            updateSimulationList={updateSimulationList}
                            onRunClick={handleRunClick}
                            onSimulationSelect={handleSimulationSelect}
                        />
                    </div>
                    <Modal
                        isOpen={showCreateSimulation}
                        className="w-full h-auto max-w-lg bg-gray-700 rounded-lg"
                        overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                        onRequestClose={(e) => {
                            e.stopPropagation();
                            setShowCreateSimulation(false);
                        }}
                        shouldCloseOnOverlayClick={true}
                    >
                        <CreateSimulation
                            periods={periods}
                            operatingChains={operatingChains}
                            updateSimulationList={updateSimulationList}
                            setPageLoading={setPageLoading}
                            onClose={() => setShowCreateSimulation(false)}
                        />
                    </Modal>
                    <Modal
                        isOpen={showPickSimulationOpchains}
                        className="w-full h-auto max-w-lg bg-gray-700 rounded-lg"
                        overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                        onRequestClose={(e) => {
                            e.stopPropagation();
                            setShowPickSimulationOpchains(false);
                        }}
                        shouldCloseOnOverlayClick={true}
                    >
                        <PickSimulationOpchains
                            operatingChains={operatingChains}
                            updateSimulationList={updateSimulationList}
                            setPageLoading={setPageLoading}
                            onClose={() => setShowPickSimulationOpchains(false)}
                            chosenRowData={chosenRowData}
                        />
                    </Modal>
                    <Modal
                        isOpen={showCopySimulation}
                        className="w-full h-auto max-w-lg bg-gray-700 rounded-lg"
                        overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                        onRequestClose={(e) => {
                            e.stopPropagation();
                            setShowCopySimulation(false);
                        }}
                        shouldCloseOnOverlayClick={true}
                    >
                        <CopySimulation
                            periods={periods}
                            updateSimulationList={updateSimulationList}
                            setPageLoading={setPageLoading}
                            simulation={selectedSimulation}
                            onClose={() => setShowCopySimulation(false)}
                        />
                    </Modal>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default Simulation;
