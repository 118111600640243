import React from 'react';

function Home() {
    return (
        <div className="h-full w-full flex justify-center">
            <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
                <div className="text-center">
                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-50 sm:text-5xl md:text-6xl">
                        <span className="block xl:inline">Welcome to</span>{' '}
                        <span className="block text-green-500 xl:inline">
                            Bonus Manager V2
                        </span>
                    </h1>
                </div>
            </main>
        </div>
    );
}

export default Home;
