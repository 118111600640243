import * as simulationColumnTypes from './simulationColumnTypes';
import * as competitionColumnTypes from './competitionColumnTypes';
import * as filters from '../Table/filters';

const removeRow = (id, table) => {
    return [...table].filter((y) => y.id != id);
};

const removeAllRows = (table, filterSpecOpchain) => {
    if (!filterSpecOpchain || filterSpecOpchain.label === 'All') {
        return [];
    } else {
        return table.filter(
            (el) => el.operatingChain.value !== filterSpecOpchain.value
        );
    }
};

const toggleRow = (id, table) => {
    const newTable = [...table].map((y) => {
        if (y.id === id) {
            return { ...y, selected: !y.selected };
        }
        return y;
    });
    return newTable;
};

const toggleAllRows = (ids, shouldToggle, table) => {
    const newTable = [...table].map((y) => {
        if (ids.indexOf(y.id) >= 0) {
            return { ...y, selected: shouldToggle };
        }
        return y;
    });
    return newTable;
};

const removeSelected = (table) => {
    return table.filter((row) => !row.selected);
};

const getName = (dataRow, employees) => {
    if (dataRow.name) return dataRow.name;
    const user = employees.find((e) => e.employeeId === dataRow.employeeId) || {
        firstname: '',
        lastname: '',
    };
    return `${user.firstname} ${user.lastname}`;
};

const getUserName = (dataRow, employees) => {
    if (dataRow.username) return dataRow.username;
    const user = employees.find((e) => e.employeeId === dataRow.employeeId) || {
        username: '',
    };
    return user.username;
};

export {
    simulationColumnTypes,
    competitionColumnTypes,
    removeRow,
    removeAllRows,
    toggleRow,
    removeSelected,
    toggleAllRows,
    filters,
    getName,
    getUserName,
};
