import React, { useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const SelectCell = (rowData) => {
    const {
        value,
        row: {
            original: { id: rowId },
        },
        column: { id },
        onUpdateData,
    } = rowData;
    const defaultValue = value || { value: '' };
    const config = {
        ...{ options: () => [], isVisible: true, isDisabled: false },
        ...defaultValue,
    };
    const options = config.isAsync ? [] : config.options();
    const selectedValue = config.isAsync
        ? { value: config.value, label: config.value }
        : options.find((opt) => opt.value === config.value) || {
              value: config.value,
          };
    const [lastOptions, setLastOptions] = useState([]);
    const onChange = (changedValue) => {
        if (changedValue.value !== selectedValue?.value) {
            onUpdateData(rowId, id, changedValue.value);
        }
    };

    const loadOptions = async (inputValue) => {
        let options = [];
        const searchValue = inputValue || selectedValue.value || '';
        options = await config.options(searchValue);
        setLastOptions(options);
        return options;
    };

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            width: state.selectProps.width,
        }),
        control: (provided) => ({
            ...provided,
            minHeight: '26px',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '2px 2px',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '2px',
        }),
        container: (provided) => ({
            ...provided,
            width: '100%',
            flex: '1 1 0%',
        }),
    };
    const foundValue = lastOptions.find((o) => o.value === selectedValue.value);
    const elem = config.isVisible ? (
        config.isAsync ? (
            <AsyncSelect
                styles={customStyles}
                defaultOptions={true}
                loadOptions={loadOptions}
                onChange={onChange}
                value={foundValue}
                isDisabled={config.isDisabled}
                menuPlacement="auto"
                key={config.key}
            />
        ) : (
            <Select
                styles={customStyles}
                options={options}
                onChange={onChange}
                value={selectedValue}
                isDisabled={config.isDisabled}
                menuPlacement="auto"
                key={config.key}
                isMulti={config.isMulti}
            />
        )
    ) : (
        <></>
    );
    return elem;
};

export default SelectCell;
