import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { MsalProvider } from '@azure/msal-react';
import { RenderProvider } from '@/renderContext';
import { msalInstance } from '@/auth';
import App from '@/App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
Modal.setAppElement(rootElement);
const root = createRoot(rootElement);

root.render(
    <MsalProvider instance={msalInstance}>
        <BrowserRouter basename={baseUrl}>
            <RenderProvider>
                <App />
            </RenderProvider>
        </BrowserRouter>
    </MsalProvider>
);
