import React from 'react';

const ContentCard = ({ header, content, icon }) => {
    const containerStyle =
        'relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3';

    return (
        <div className={containerStyle}>
            <div className="flex-shrink-0 h-full flex items-center justify-center w-16">
                {icon}
            </div>
            <div className="flex-1 min-w-0">
                <span className="inset-0" aria-hidden="true" />
                <p className="text-sm text-gray-500">{header}</p>
                <p className="text-sm font-medium text-gray-900">{content}</p>
            </div>
        </div>
    );
};

export default ContentCard;
