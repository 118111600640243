import { memoizedMakeReq, postRequest } from './apiCommon';

const simulationApi = (axios) => ({
    getSimulations: memoizedMakeReq(axios, 'simulations'),
    createSimulation: postRequest(axios, 'create-simulation'),
    copySimulation: postRequest(axios, 'copy-simulation'),
    saveSimulation: postRequest(axios, 'simulation'),
    runSimulation: postRequest(axios, 'run-simulation'),
    deleteSimulation: postRequest(axios, 'delete-simulation'),
    getLastRunStatus: memoizedMakeReq(axios, 'get-simulation-status'),
    runBonusCalculation: postRequest(axios, 'run-bonus-calculation'),
});

export default simulationApi;
