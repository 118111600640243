import { memoizedMakeReq } from './apiCommon';

const cached = {};

const searchArticles = (axios) => async (query) => {
    if (!query) {
        return [];
    }
    const values = Array.isArray(query) ? query : [query];

    return values.map((value) => ({
        value: value,
        label: value,
    }));

    // if (values.length === 1 && values[0].includes(",")) {
    //     return [{
    //         value: values[0],
    //         label: values[0],
    //     }];
    // }

    // const valuesToSearch = values.filter((value) => !cached[value]);

    // const result = valuesToSearch.length === 0 ? [] : ((await memoizedMakeReq(axios, 'search/articles?' + valuesToSearch.map(v=>"q="+v).join())()) || []);

    // console.log("cached", Object.values(cached).length, cached, result.length);

    // const options = [...result.map((item) => ({
    //     value: item.articleNumber,
    //     label: `${item.articleNumber}${item.title ? (` - ${item.title}`) : '' }`,
    // })), ...Object.values(cached)];

    // const completeOptions = values.map((value) => {
    //     const option = options.find((y) => y.value === value);
    //     if (option) {
    //         cached[value] = option;
    //     }
    //     return option ? option : { value: value, label: value };
    // });

    // return completeOptions;
};

const searchApi = (axios) => ({
    searchArticles: searchArticles(axios),
});

export default searchApi;
