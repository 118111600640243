import React, { useState, useEffect, useMemo } from 'react';
import { Table } from '@/components';
import { v4 as uuidv4 } from 'uuid';
import { valueTypes, validators } from '@/utils';
import {
    columnTypes,
    removeRow,
    removeAllRows,
} from '@/components/Table/index';
import { prepareTableData, prepareTableDataMerge } from './common';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';
import { Filter } from './operatingChainFilter';
import Loader from '../../components/Loader';

const staffTypes = [
    { label: 'Sales', value: 'SALES' },
    { label: 'Operations', value: 'OPERATIONS' },
];

const createRow =
    (ruleTypes, operatingChains) => (customerRecruitmentEntry) => {
        const row = {
            id: customerRecruitmentEntry.id,
            key: columnTypes.ruleType.cell(
                customerRecruitmentEntry.key,
                ruleTypes
            ),
            valueType: columnTypes.valueType.cell(
                customerRecruitmentEntry.valueType,
                customerRecruitmentEntry.key,
                customerRecruitmentEntry.keyValue
            ),
            operatingChain: columnTypes.operatingChainType.cell(
                `${customerRecruitmentEntry.operatingChain}`,
                operatingChains
            ),
            value: columnTypes.operatingChainValueTwo.cell(
                customerRecruitmentEntry.value
            ),
            staff: columnTypes.staffType.cell(customerRecruitmentEntry.staff),
        };
        return row;
    };

export default function CustomerRecruitment({ fiscalKey }) {
    const { apiFactory } = useDependencies();
    const { bonusSettingsApi, bonusRuleApi } = apiFactory;
    const [isLoaded, setIsLoaded] = useState(false);
    const [state, setState] = useState({});
    const [tableData, setTableData] = useState([]);
    const [filterSpec, setFilterSpec] = useState({
        operatingChain: { label: 'All' },
    });

    useEffect(() => {
        if (state.allCustomerRecruitmentRules && state.ruleTypes) {
            const customerRecruitments = prepareTableData(
                state.allCustomerRecruitmentRules,
                fiscalKey
            );
            const unsortedCustomerRecruitments = customerRecruitments
                .map(createRow(state.ruleTypes, state.operatingChainOptions))
                .filter((obj) => obj.operatingChain.label.length > 0);
            const recsSortedByStaff = unsortedCustomerRecruitments.sort(
                (a, b) =>
                    b.staff.value > a.staff.value
                        ? -1
                        : a.staff.value > b.staff.value
                        ? 1
                        : 0
            );
            const sortedByOperatingChain = recsSortedByStaff.sort((a, b) =>
                b.operatingChain.label[0].label >
                a.operatingChain.label[0].label
                    ? -1
                    : a.operatingChain.label[0].label >
                      b.operatingChain.label[0].label
                    ? 1
                    : 0
            );
            setTableData(sortedByOperatingChain);
            setIsLoaded(true);
        }
    }, [fiscalKey, state]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const [operatingChains, allCustomerRecruitmentRules] =
                await Promise.all([
                    bonusSettingsApi.getOperatingChains(),
                    bonusRuleApi.getCustomerRecruitments(),
                ]);
            const ruleTypes = [
                {
                    value: 'CUSTOMER_RECRUITMENT',
                    label: 'Customer recruitments',
                },
            ];
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            mounted
                ? setState({
                      ruleTypes,
                      allCustomerRecruitmentRules,
                      operatingChainOptions,
                  })
                : '';
        }
        getData();
        return () => (mounted = false);
    }, [bonusRuleApi, bonusSettingsApi]);

    const columns = useMemo(() => {
        const fixedColumns = [
            columnTypes.operatingChainType.column(),
            columnTypes.staffType.column(),
            columnTypes.operatingChainValueTwo.column(),
        ];
        return [...fixedColumns];
    }, []);

    const rows = useMemo(() => {
        return filterSpec &&
            filterSpec.operatingChain &&
            filterSpec.operatingChain.value
            ? tableData.filter(
                  (el) =>
                      filterSpec.operatingChain.value ===
                      el.operatingChain.value.split('_')[0]
              )
            : tableData;
    }, [filterSpec, tableData]);

    const newRow = () => {
        const opChainValue =
            filterSpec &&
            filterSpec.operatingChain &&
            filterSpec.operatingChain.value;
        const recruitmentRow = {
            id: uuidv4(),
            valueType: valueTypes.fixed,
            key: state.ruleTypes[0].value,
            operatingChain:
                opChainValue || state.operatingChainOptions[0].value,
            staff: columnTypes.staffType.cell.value,
            value: 0,
        };
        const row = createRow(
            state.ruleTypes,
            state.operatingChainOptions
        )(recruitmentRow);
        const newTableData = [...tableData, row];
        setTableData(newTableData);
    };

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row.id = uuidv4();
        row[columnName] = { ...row[columnName], ...{ value } };
        if (columnName === 'value') {
            row[columnName] = parseFloat(value).toFixed(2);
        }
        setTableData(newTableData);
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData, filterSpec.operatingChain));
    };

    const saveData = async () => {
        const formattedCustomerRecruitments = [];
        tableData.forEach((customerRecruitment) => {
            formattedCustomerRecruitments.push({
                id: customerRecruitment.id,
                key: customerRecruitment.key.value,
                keyValue: columnTypes.keyValue.value(customerRecruitment),
                operatingChain: customerRecruitment.operatingChain.value,
                staff: customerRecruitment.staff.value,
                value: customerRecruitment.value,
                revGMIndicator:
                    columnTypes.revGMIndicator.value(customerRecruitment),
                valueType: customerRecruitment.valueType.value,
            });
        });
        const data = {
            data: formattedCustomerRecruitments,
            fiscalKey: fiscalKey,
            simulationId: simulationIdState.get()[0].simulationId,
        };

        await bonusRuleApi.saveCustomerRecruitments(data);
        const savedData = await bonusRuleApi.getCustomerRecruitments(true);
        setState({ ...state, allCustomerRecruitmentRules: savedData });
        return {
            valid: true,
        };
    };

    const sampleCSV = () => `OCNOELK\tSALES\t20
OCNOELK\tOPERATIONS\t80
OCSEELG\tSALES\t20
OCSEELG\tOPERATIONS\t80
OCDKELG\tSALES\t20
OCDKELG\tOPERATIONS\t80
OCFIGIG\tSALES\t20
OCFIGIG\tOPERATIONS\t80
    `;

    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) =>
                    `${row.operatingChain.value}\t${row.staff.value}\t${row.value}`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    const {
        optionValidator,
        composeValidators,
        validateIfRecruitmentRowAlreadyExist,
    } = validators;
    const csvRowValidator = (currentData) => async (row) => {
        const validators = [
            optionValidator('Invalid opChain', state.operatingChainOptions, 0),
            optionValidator('Invalid staff', staffTypes, 1),
            validateIfRecruitmentRowAlreadyExist(currentData, row),
        ];
        const validator = composeValidators(validators);
        return await validator(row);
    };

    const checkIfRowAlreadyExist = (uploadedRow, existingRow) => {
        return (
            uploadedRow.operatingChain === existingRow.operatingChain.value &&
            uploadedRow.staff === existingRow.staff.value
        );
    };

    const addCsvData = (data) => {
        const createItem = (row) => {
            const item = {
                id: uuidv4(),
                operatingChain: row.items[0],
                staff: row.items[1],
                value: row.items[2].replace(',', '.'),
                key: 'CUSTOMER_RECRUITMENT',
            };
            item.id = item.id + '#' + 1;
            return item;
        };
        const input = data.data.map(createItem);
        const existingRows = tableData.map((tr) => {
            const updatedRow = input.find((i) => checkIfRowAlreadyExist(i, tr));
            if (updatedRow) {
                tr.value = updatedRow.value;
            }
            return tr;
        });
        const newRows = input
            .filter(
                (r) =>
                    existingRows.findIndex((ud) =>
                        checkIfRowAlreadyExist(r, ud)
                    ) < 0
            )
            .map(createRow(state.ruleTypes, state.operatingChainOptions));
        setTableData([...existingRows, ...newRows]);
    };

    const copyFiscalKey = (fiscalKeyData, operatingChainData) => {
        const chosenOpChains = operatingChainData.map((oc) => oc.value);
        const newChosenObjects = prepareTableData(
            state.allCustomerRecruitmentRules,
            fiscalKey
        ); // Table we are copying INTO
        const newCopiedObjects = prepareTableData(
            state.allCustomerRecruitmentRules,
            fiscalKeyData.value
        ); // Data we are copying
        if (!chosenOpChains[0]) {
            state.operatingChainOptions.forEach((opChain) =>
                chosenOpChains.push(opChain.value)
            );
        }
        const copiedObjectsFilteredByOC = newCopiedObjects.filter((el) =>
            chosenOpChains.includes(el.operatingChain)
        );

        const chosenPeriodRows = newChosenObjects.map(
            createRow(state.ruleTypes, state.operatingChainOptions)
        );
        const copiedPeriodRows = copiedObjectsFilteredByOC.map(
            createRow(state.ruleTypes, state.operatingChainOptions)
        );
        setTableData(prepareTableDataMerge(chosenPeriodRows, copiedPeriodRows));
    };

    return isLoaded ? (
        <Table
            data={rows}
            columns={columns}
            onUpdateData={updateData}
            onRemoveRow={deleteRow}
            onRemoveAllRows={deleteAllRows}
            onNewRow={newRow}
            onSaveData={saveData}
            components={{
                filter: (
                    <Filter
                        filter={filterSpec}
                        onChange={setFilterSpec}
                        operatingChainOptions={state.operatingChainOptions}
                    />
                ),
            }}
            sampleCSV={sampleCSV}
            rowsCSV={rowsCSV}
            csvRowValidator={csvRowValidator(tableData, state.ruleTypes)}
            csvColumns={['Operating Chain', 'Staff', 'Value']}
            addCsvData={addCsvData}
            copyFiscalKey={copyFiscalKey}
            showCopyFiscal={true}
            onCopyFrom={copyFiscalKey}
            filterSpec={filterSpec}
            fiscalKey={fiscalKey}
        />
    ) : (
        <div className="w-full text-center">
            <Loader />
        </div>
    );
}
