import React from 'react';
import * as buttons from './buttons';

const DeleteAll = ({ onDeleteAll, onClose, selectedOpChain }) => {
    const opChain =
        selectedOpChain.props.filter.operatingChain &&
        selectedOpChain.props.filter.operatingChain.value
            ? selectedOpChain.props.filter.operatingChain
            : 'All';

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <div className="flex flex-col space-y-2 w-full">
                <h2 className="font-bold text-center mb-4 text-xl text-gray-50">
                    {opChain === 'All'
                        ? 'You are about to delete all data from ALL operating chains.'
                        : `You are about to delete all data from ${opChain.label}.`}
                </h2>
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Delete
                        text="Delete All"
                        onDelete={(e) => {
                            e.preventDefault();
                            onDeleteAll();
                            onClose();
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default DeleteAll;
