import React, { useState, useEffect, useMemo } from 'react';
import { Table } from '@/components';
import { v4 as uuidv4 } from 'uuid';
import { valueTypes, validators } from '@/utils';
import {
    columnTypes,
    removeRow,
    removeAllRows,
} from '@/components/Table/index';
import {
    saveBonusGeneral,
    prepareTableData,
    prepareTableDataMerge,
    checkRowValue,
    prepareRuleTypes,
} from './common';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';
import { Filter } from './operatingChainFilter';
import Loader from '../../components/Loader';

const createKeyValueArgs = (modelTypes, categories) => {
    return {
        getModelTypes: () => {
            return modelTypes;
        },
        getCategories: () => categories,
    };
};

const createRow =
    (ruleTypes, keyValueArgs, operatingChains) => (individualSalesEntry) => {
        const row = {
            id: individualSalesEntry.id,
            altId: individualSalesEntry.altId,
            key: columnTypes.ruleType.cell(individualSalesEntry.key, ruleTypes),
            keyCopy: individualSalesEntry.key,
            keyValue: columnTypes.keyValue.cell(
                individualSalesEntry.keyValue,
                individualSalesEntry.key,
                keyValueArgs,
                true
            ),
            valueType: columnTypes.valueType.cell(
                individualSalesEntry.valueType,
                individualSalesEntry.key,
                individualSalesEntry.keyValue
            ),
            revGMIndicator: columnTypes.revGMIndicator.cell(
                individualSalesEntry.revGMIndicator,
                individualSalesEntry.key,
                individualSalesEntry.keyValue,
                individualSalesEntry.valueType
            ),
            operatingChain: columnTypes.operatingChainType.cell(
                individualSalesEntry.operatingChain,
                operatingChains
            ),
            homeTechCode: columnTypes.homeTechCodeValue.cell(
                individualSalesEntry.homeTechCode
            ),
            value: columnTypes.operatingChainValueTwo.cell(
                individualSalesEntry.value
            ),
        };
        return row;
    };

export default function SalesIndividual({ fiscalKey }) {
    const { apiFactory } = useDependencies();
    const { bonusSettingsApi, articleMasterdataApi, bonusRuleApi } = apiFactory;
    const [isLoaded, setIsLoaded] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [state, setState] = useState({});
    const [filterSpec, setFilterSpec] = useState({
        operatingChain: { label: 'All' },
    });

    useEffect(() => {
        if (state.allIndividualSales && state.ruleTypes && state.keyValueArgs) {
            const individualSales = prepareTableData(
                state.allIndividualSales,
                fiscalKey
            );
            const unsortedIndividualSales = createNewIndividualSalesObject(
                individualSales
            )
                .map(
                    createRow(
                        state.ruleTypes,
                        state.keyValueArgs,
                        state.operatingChainOptions
                    )
                )
                .filter((obj) => obj.operatingChain.label.length > 0);

            const sortedByDepartment = unsortedIndividualSales.sort((a, b) =>
                b.homeTechCode.value > a.homeTechCode.value
                    ? -1
                    : a.homeTechCode.value > b.homeTechCode.value
                    ? 1
                    : 0
            );
            const sortedByRuleValue = sortedByDepartment.sort((a, b) =>
                b.keyValue.label > a.keyValue.label
                    ? -1
                    : a.keyValue.label > b.keyValue.label
                    ? 1
                    : 0
            );
            const sortedByRuleType = sortedByRuleValue.sort((a, b) =>
                b.key.value > a.key.value
                    ? -1
                    : a.key.value > b.key.value
                    ? 1
                    : 0
            );
            const sortedByOperatingChain = sortedByRuleType.sort((a, b) =>
                b.operatingChain.label[0].label >
                a.operatingChain.label[0].label
                    ? -1
                    : a.operatingChain.label[0].label >
                      b.operatingChain.label[0].label
                    ? 1
                    : 0
            );

            setTableData(sortedByOperatingChain);
            setIsLoaded(true);
        }
    }, [state, fiscalKey]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const [
                measures,
                categories,
                modelTypes,
                operatingChains,
                allIndividualSales,
            ] = await Promise.all([
                bonusSettingsApi.getMeasures(),
                articleMasterdataApi.getCategories(),
                articleMasterdataApi.getModelTypes(),
                bonusSettingsApi.getOperatingChains(),
                bonusRuleApi.getIndividualSales(),
            ]);

            const ruleTypes = prepareRuleTypes(measures, 'individual');
            const categoryItems = categories.map((c) => ({
                value: c.id,
                label: `${c.id} - ${c.description}`,
            }));
            const modelTypeItems = modelTypes.map((c) => ({
                value: c.id,
                label: `${c.id} - ${c.description}`,
            }));
            const keyValueArgs = createKeyValueArgs(
                modelTypeItems,
                categoryItems
            );
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));

            mounted
                ? setState({
                      allIndividualSales,
                      ruleTypes,
                      operatingChains,
                      keyValueArgs,
                      operatingChainOptions,
                  })
                : '';
        }
        getData();
        return () => (mounted = false);
    }, [articleMasterdataApi, bonusRuleApi, bonusSettingsApi]);

    const columns = useMemo(() => {
        const fixedColumns = [
            columnTypes.operatingChainType.column(),
            columnTypes.homeTechCodeValue.column(true),
            columnTypes.ruleType.column(),
            columnTypes.keyValue.column(true),
            columnTypes.valueType.column(true),
            columnTypes.revGMIndicator.column(true),
            columnTypes.operatingChainValueTwo.column(),
        ];
        return [...fixedColumns];
    }, []);

    const rows = useMemo(() => {
        const rows =
            filterSpec &&
            filterSpec.operatingChain &&
            filterSpec.operatingChain.value
                ? tableData.filter(
                      (el) =>
                          filterSpec.operatingChain.value ===
                          el.operatingChain.value
                  )
                : tableData;

        return rows;
    }, [filterSpec, tableData]);

    const createNewIndividualSalesObject = (tableData) => {
        const newIndividualSales = [];
        tableData.forEach((individualSale) => {
            individualSale.id = uuidv4();
            individualSale.operatingChains.forEach((el, index) => {
                if (checkRowValue(el.value)) {
                    newIndividualSales.push({
                        id: individualSale.id + `#${el.homeTechCode}` + index,
                        altId: individualSale.id,
                        key: individualSale.key,
                        operatingChain: el.operatingChain,
                        value: el.value,
                        valueType: individualSale.valueType,
                        homeTechCode: el.homeTechCode,
                        keyValue: individualSale.keyValue,
                        revGMIndicator: individualSale.revGMIndicator,
                    });
                }
            });
        });
        return newIndividualSales;
    };

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row.id = uuidv4();
        row.altId = row.id;

        row[columnName] = { ...row[columnName], ...{ value } };
        if (columnName === 'value') {
            row[columnName] = parseFloat(value).toFixed(2);
        }
        if (columnName === 'key') {
            row['keyValue'] = columnTypes.keyValue.cell(
                '',
                value,
                state.keyValueArgs
            );
            row['valueType'] = columnTypes.valueType.cell('', value);
        }
        if (columnName === 'keyValue') {
            const keyCell = row['key'];
            row['valueType'] = columnTypes.valueType.cell(
                '',
                keyCell.value,
                value
            );
        }
        if (
            ['key', 'keyValue', 'valueType', 'revGMIndicator'].indexOf(
                columnName
            ) > -1
        ) {
            const keyValue = columnName === 'key' ? value : row['key'].value;
            const keyValueValue =
                columnName === 'keyValue' ? value : row['keyValue'].value;
            const valueTypeValue =
                columnName === 'valueType' ? value : row['valueType'].value;
            const revGMIndicatorValue =
                columnName === 'revGMIndicator'
                    ? value
                    : row['revGMIndicator'].value;
            row['revGMIndicator'] = columnTypes.revGMIndicator.cell(
                revGMIndicatorValue,
                keyValue,
                keyValueValue,
                valueTypeValue
            );
        }
        setTableData(newTableData);
    };

    const newRow = () => {
        const opChainValue =
            filterSpec &&
            filterSpec.operatingChain &&
            filterSpec.operatingChain.value;
        const individualSale = {
            id: uuidv4(),
            valueType: valueTypes.percentage,
            operatingChain: opChainValue || '',
            key: '',
            homeTechCode: '',
            value: 0,
        };
        individualSale.altId = individualSale.id;
        const row = createRow(
            state.ruleTypes,
            state.keyValueArgs,
            state.operatingChainOptions
        )(individualSale);
        const newTableData = [...tableData, row];
        setTableData(newTableData);
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData, filterSpec.operatingChain));
    };

    const hasSameValues = (valueOne, valueTwo) => {
        return (
            valueOne.key === valueTwo.key.value &&
            valueOne.keyValue === valueTwo.keyValue.value &&
            valueOne.valueType.value === valueTwo.valueType.value &&
            valueOne.revGMIndicator === valueTwo.revGMIndicator.value &&
            valueOne.homeTechCode === valueTwo.homeTechCode.value
        );
    };

    const saveData = async () => {
        const formattedIndividualSales = [];
        const filteredTable = tableData.filter(
            (obj) =>
                obj.operatingChain.value && obj.homeTechCode.value && obj.value
        );

        filteredTable.forEach((individualSale) => {
            if (
                !formattedIndividualSales.some((e) =>
                    hasSameValues(e, individualSale)
                ) ||
                formattedIndividualSales.length === 0
            ) {
                formattedIndividualSales.push({
                    id: individualSale.altId,
                    operatingChains: [
                        {
                            operatingChain: individualSale.operatingChain.value,
                            value: individualSale.value,
                            homeTechCode: individualSale.homeTechCode.value,
                        },
                    ],
                    key: individualSale.key.value,
                    valueType: individualSale.valueType,
                    keyValue: individualSale.keyValue.value,
                    revGMIndicator: individualSale.revGMIndicator.value,
                    homeTechCode: individualSale.homeTechCode.value,
                });
            } else {
                formattedIndividualSales.forEach((el) => {
                    if (hasSameValues(el, individualSale)) {
                        el.operatingChains.push({
                            operatingChain: individualSale.operatingChain.value,
                            value: individualSale.value,
                            homeTechCode: individualSale.homeTechCode.value,
                        });
                    }
                });
            }
        });
        await saveBonusGeneral(bonusRuleApi.saveIndividualSales)(
            formattedIndividualSales,
            fiscalKey,
            simulationIdState.get()[0].simulationId
        );
        const savedData = await bonusRuleApi.getIndividualSales(true);
        setState({ ...state, allIndividualSales: savedData });
        return {
            valid: true,
        };
    };

    const sampleCSV = () => `OCNOELK\tHOME\tGM\t\tPERCENTAGE\t\t20
OCNOELK\tHOME\tREV\t\tPERCENTAGE\t\t80
OCNOELK\tHOME\tMODELTYPECODE\tACC\tPERCENTAGE\tREV\t75
OCNOELK\tHOME\tCATEGORYCODE\t1\tPERCENTAGE\tREV\t70
OCNOELK\tHOME\tMODELTYPECODE\tFIN\tPERCENTAGE\t\t30
OCNOELK\tTECH\tOUTLET\t\tPERCENTAGE\tREV\t40
OCNOELK\tTECH\tB2B\t\tPERCENTAGE\tREV\t5
OCNOELK\tHOME\tKITCHEN\t\tPERCENTAGE\tREV\t10
    `;

    // Convert table data to string
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) =>
                    `${row.operatingChain.value}\t${row.homeTechCode.value}\t${
                        row.key.value
                    }\t${!row.keyValue.isDisabled ? row.keyValue.value : ''}\t${
                        row.valueType.value
                    }\t${
                        row.revGMIndicator.isVisible
                            ? row.revGMIndicator.value
                            : ''
                    }\t${row.value}`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    const {
        optionValidator,
        composeValidators,
        validateIfRowAlreadyExist,
        returnNullOrValue,
    } = validators;
    const csvRowValidator = (currentData, ruleTypes) => async (row) => {
        const validators = [
            optionValidator('Invalid rule type', ruleTypes, 2),
            validateIfRowAlreadyExist(currentData),
        ];
        const validator = composeValidators(validators);
        return await validator(row);
    };

    const checkIfRowAlreadyExist = (uploadedRow, existingRow) => {
        return (
            uploadedRow.key === existingRow.key.value &&
            uploadedRow.operatingChain === existingRow.operatingChain.value &&
            returnNullOrValue(uploadedRow.keyValue) ===
                returnNullOrValue(existingRow.keyValue.value) &&
            returnNullOrValue(uploadedRow.revGMIndicator) ===
                returnNullOrValue(existingRow.revGMIndicator.value) &&
            uploadedRow.homeTechCode === existingRow.homeTechCode.value &&
            uploadedRow.valueType === existingRow.valueType.value
        );
    };

    const addCsvData = (data) => {
        const createItem = (row, index) => {
            const item = {
                id: uuidv4(),
                operatingChain: row.items[0],
                homeTechCode: row.items[1].toUpperCase(),
                key: row.items[2].toUpperCase(),
                keyValue: row.items[3].toUpperCase(),
                valueType: row.items[4].toUpperCase(),
                revGMIndicator: row.items[5].toUpperCase(),
                value: row.items[6].replace(',', '.'),
            };
            item.altId = item.id;
            item.id = item.id + '#' + index;
            return item;
        };
        const input = data.data.map(createItem);
        const existingRows = tableData.map((tr) => {
            const updatedRow = input.find((i) => checkIfRowAlreadyExist(i, tr));
            if (updatedRow) {
                tr.value = updatedRow.value;
            }
            return tr;
        });
        const newRows = input
            .filter(
                (r) =>
                    existingRows.findIndex((ud) =>
                        checkIfRowAlreadyExist(r, ud)
                    ) < 0
            )
            .map(
                createRow(
                    state.ruleTypes,
                    state.keyValueArgs,
                    state.operatingChainOptions
                )
            );
        setTableData([...existingRows, ...newRows]);
    };

    const copyFiscalKey = (fiscalKeyData, operatingChainData) => {
        const chosenOpChains = operatingChainData.map((oc) => oc.value);

        const newChosenObjects = createNewIndividualSalesObject(
            prepareTableData(state.allIndividualSales, fiscalKey)
        ); // Table we are copying INTO
        const newCopiedObjects = createNewIndividualSalesObject(
            prepareTableData(state.allIndividualSales, fiscalKeyData.value)
        ); // Data we are copying
        if (!chosenOpChains[0]) {
            state.operatingChainOptions.forEach((opChain) =>
                chosenOpChains.push(opChain.value)
            );
        }
        const copiedObjectsFilteredByOC = newCopiedObjects.filter((el) =>
            chosenOpChains.includes(el.operatingChain)
        );

        const chosenPeriodRows = newChosenObjects.map(
            createRow(
                state.ruleTypes,
                state.keyValueArgs,
                state.operatingChainOptions
            )
        );
        const copiedPeriodRows = copiedObjectsFilteredByOC.map(
            createRow(
                state.ruleTypes,
                state.keyValueArgs,
                state.operatingChainOptions
            )
        );
        setTableData(prepareTableDataMerge(chosenPeriodRows, copiedPeriodRows));
    };

    return isLoaded ? (
        <Table
            data={rows}
            columns={columns}
            onUpdateData={updateData}
            onRemoveRow={deleteRow}
            onRemoveAllRows={deleteAllRows}
            onNewRow={newRow}
            onSaveData={saveData}
            components={{
                filter: (
                    <Filter
                        filter={filterSpec}
                        onChange={setFilterSpec}
                        operatingChainOptions={state.operatingChainOptions}
                    />
                ),
            }}
            sampleCSV={sampleCSV}
            rowsCSV={rowsCSV}
            csvRowValidator={csvRowValidator(tableData, state.ruleTypes)}
            csvColumns={[
                'Operating Chain',
                'Department',
                'Rule Type',
                'Rule value',
                'PERCENTAGE/FIXED',
                'REV/GM',
                'Value',
            ]}
            addCsvData={addCsvData}
            copyFiscalKey={copyFiscalKey}
            showCopyFiscal={true}
            onCopyFrom={copyFiscalKey}
            filterSpec={filterSpec}
            fiscalKey={fiscalKey}
        />
    ) : (
        <div className="w-full text-center">
            <Loader />
        </div>
    );
}
