import React, { useState } from 'react';

const DecimalCell = ({
    value,
    row: {
        original: { id: rowId },
    },
    column: { id },
    onUpdateData,
}) => {
    const [config] = useState({ ...{ isDisabled: false }, ...value });
    const [changed, setChanged] = useState(false);
    const [inputValue, setInputValue] = useState(
        Number(config.value || 0).toFixed(config.numberOfDecimals)
    );

    const onChange = (e) => {
        setChanged(true);
        setInputValue(e.target.value);
    };

    const onBlur = () => {
        if (changed) {
            onUpdateData(
                rowId,
                id,
                parseFloat((inputValue || '0').replace(',', '.')).toFixed(
                    config.numberOfDecimals
                )
            );
            setChanged(false);
        }
    };

    const extraClasses = config.isDisabled ? 'disabled:opacity-50' : '';
    const className = `border-solid border-gray-200 border-2 w-full p-1 ${extraClasses}`;
    return (
        <div className="whitespace-nowrap w-full">
            <input
                type="number"
                value={inputValue || '0'}
                onChange={onChange}
                onBlur={onBlur}
                className={className}
                disabled={config.isDisabled}
                step={config.stepValue}
            />
        </div>
    );
};

export default DecimalCell;
