const avaialbleParameters = [
    { label: 'Total Points', value: 'totalPoints' },
    { label: 'Payout', value: 'payout' },
    { label: 'Individual Points', value: 'individualPoints' },
    {
        label: 'Insurance Points',
        value: 'insurancePoints',
    },
    {
        label: 'Added Value Services Points',
        value: 'addedValueServicesPoints',
    },
    {
        label: 'Subscription Points',
        value: 'subscriptionPoints',
    },
    {
        label: 'Recurring Insurance',
        value: 'recurringInsurance',
    },
    { label: 'Accessories Points', value: 'accessoriesPoints' },
    { label: 'Outlet Points', value: 'outletPoints' },
    { label: 'Kitchen Points', value: 'kitchenPoints' },
    { label: 'Installation Points', value: 'installationPoints' },
    { label: 'Revenue Points', value: 'revenuePoints' },
    { label: 'GM Points', value: 'GMPoints' },
    {
        label: 'Customer Recruitment Points',
        value: 'customerRecruitmentPoints',
    },
];

export default avaialbleParameters;
