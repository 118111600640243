import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { HiOutlineLogout } from 'react-icons/hi';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useAuth } from '@/auth';
import { axiosUserContext } from '@/apis/apiCommon';
import { createAxiosClient, createApiFactory } from '@/apis/apiFactory';
import { DependencyProvider } from '@/DependencyProvider';
import { useRender, RenderProvider } from '@/renderContext';
import { buttons, AppRoutes, Navbar } from '@/components';
import Loader from './components/Loader';
import { constants, removeSimulationId } from '@/utils';
import { simulationIdState } from '@/simulationIdState';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';


const contextClass = {
    success: 'bg-green-600',
    error: 'bg-red-600',
    info: 'bg-gray-600',
    warning: 'bg-yellow-600',
    default: 'bg-indigo-600',
    dark: 'bg-white-600 font-gray-300',
};

const Banner = () => {
    const { setValue } = useRender();
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        removeSimulationId();
        localStorage.removeItem('simulationName');
        setValue((value) => value + 1);
        navigate('/simulation');
    };

    if (simulationIdState.get()[0].simulationId === constants.PRODUCTION)
        return null;
    return (
        <div
            className="bg-red-600 text-lg text-center flex items-center justify-center"
            style={{ width: 'calc(100% - 15rem)' }}
        >
            <buttons.Button
                text={`End Simulation Mode for ${localStorage.simulationName}`}
                onClick={handleClick}
                className="hover:underline"
            />
        </div>
    );
};

export default function App() {
    const auth = useAuth();
    axiosUserContext.accessToken = auth.accessToken;
    const axios = createAxiosClient(auth.accessToken);
    const apiFactory = createApiFactory(axios);
    const { value } = useRender();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
    const [menuIsOpen, setMenuIsOpen] = useState(true);
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    React.useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 640);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    useEffect(() => {
        if (localStorage.getItem('simId') !== null) {
            simulationIdState.set([
                { simulationId: localStorage.getItem('simId') },
            ]);
        }
    });

    const handleLoginClick = async () => {
        try {
            await instance.loginRedirect();
        } catch (error) {
            console.error(error);
        }
    };

    const handleLogoutClick = async () => {
      await instance.logoutRedirect();
    };

    const LoginScreen = ({ login }) => (
        <div className="flex flex-col items-center justify-center">
            <div className="mt-24 flex flex-col items-center w-3/4 max-w-xl p-4 shadow-2xl rounded-xl h-96 bg-elkjop-blue justify-evenly bg-gray-800">
                <h2 className="text-3xl tracking-tight font-extrabold text-gray-50 sm:text-2xl md:text-2xl">
                    <span className="block text-green-500 xl:inline">
                        Bonus Manager V2 - Login
                    </span>
                </h2>
                <buttons.Primary
                    text={'Login'}
                    onClick={login}
                    customClass="w-48 text-xl"
                />
            </div>
        </div>
    );

    const Profile = ({ userName, logout }) => (
        <div className="w-56 box-content pl-4 flex items-center font-bold">
            <span>{userName}</span>
            <div className="group relative inline-block ml-auto pr-7 hover:">
                <button onClick={logout}>
                    <HiOutlineLogout className="w-6 h-6 mt-2 ml-5" />
                </button>
                <span className="invisible absolute -bottom-12 left-0 z-10 w-17 h-10 p-2 rounded-md bg-black text-center group-hover:visible">Logout</span>
            </div>
        </div>
    );

    const headerStyle = isMobile
        ? 'bg-gray-800 flex border-b border-solid border-gray-600 h-12 w-full fixed inset-x-0 top-0 z-40 justify-between items-center'
        : 'bg-gray-800 flex border-b border-solid border-gray-600 h-12 w-full fixed inset-x-0 top-0 z-40 justify-between';

    const mainContentStyle = menuIsOpen ? 'ml-60 w-full' : 'w-full';

    return (
        <DependencyProvider apiFactory={apiFactory}>
            <div className="parent bg-gray-900 flex flex-col h-full text-gray-100 w-full min-h-screen min-w-screen overflow-auto">
                <ToastContainer
                    toastClassName={({ type }) =>
                        contextClass[type || 'default'] +
                        ' relative flex p-1 my-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'
                    }
                    bodyClassName={() =>
                        'text-sm font-white font-med block p-3'
                    }
                />
                <header className={headerStyle}>
                    <div className="w-56 box-content pl-4 flex items-center font-bold">
                        Bonus manager v2
                    </div>
                    {isAuthenticated &&
                        <Profile userName={auth.userName} logout={handleLogoutClick}/>
                    }
                    {isMobile ? (
                        <buttons.Button
                            text={menuIsOpen ? 'Hide Menu' : 'Show Menu'}
                            onClick={() => setMenuIsOpen(!menuIsOpen)}
                            className="bg-blue-500 text-gray-200 p-2 h-10 align-middle font-bold rounded "
                        />
                    ) : null}
                    <Banner />
                </header>
                <div className="main flex-1 flex mt-12 overflow-auto">
                    <RenderProvider value={value}>
                        {menuIsOpen ? <Navbar /> : null}
                    </RenderProvider>
                    <div className={mainContentStyle}>
                        <UnauthenticatedTemplate>
                            <LoginScreen login={handleLoginClick}/>
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>
                            {auth.accessToken ? (
                                <AppRoutes />
                            ) : (
                                <div>
                                    <Loader />
                                </div>
                            )}
                        </AuthenticatedTemplate>
                    </div>
                </div>
            </div>
        </DependencyProvider>
    );
}
