export const valueTypes = {
    percentage: 'PERCENTAGE',
    fixed: 'FIXED',
    options: [
        {
            label: 'Percentage',
            value: 'PERCENTAGE',
        },
        {
            label: 'Fixed',
            value: 'FIXED',
        },
    ],
};
