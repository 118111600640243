const customSelectDropdown = (height) => {
    return {
        control: (base) => ({
            ...base,
            minHeight: 0,
            maxHeight: height,
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: 0,
            paddingLeft: 8,
            paddingRight: 8,
        }),
        valueContainer: (provided) => ({
            ...provided,
            minHeight: 1,
            height: height,
            position: 'static',
            display: 'flex',
        }),
        placeholder: (provided) => ({
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
        }),
    };
};

export { customSelectDropdown };
