import React from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

const trimValues = (values, maxItems, allOption) => {
    const withoutAllOption = values.filter(
        (value) => value.value != allOption.value
    );
    if (withoutAllOption.length <= maxItems + 1) {
        return values;
    }
    const count = withoutAllOption.length - maxItems;
    const text = `${count} more...`;
    return [...withoutAllOption.slice(0, 3), text];
};

const ValueContainer = (props) => {
    const {
        children,
        selectProps,
        ...rest
    } = props;

    const {
        allOption,
    } = selectProps;

    const [values, input] = children;
    const newValues = Array.isArray(values)
        ? trimValues(values, 3, allOption)
        : values;

    return (
        <components.ValueContainer allOption={allOption} selectProps={selectProps} {...rest }>
            {newValues}
            {input}
        </components.ValueContainer>
    );
};

const animatedComponents = makeAnimated();
const MultiSelect = (props) => {
    const options = props.allowSelectAll
        ? [props.allOption, ...props.options]
        : props.options;
    const onChange = !props.allowSelectAll
        ? props.onChange
        : (selected, event) => {
              if (selected !== null && selected.length > 0) {
                  if (
                      selected[selected.length - 1].value ===
                      props.allOption.value
                  ) {
                      return props.onChange([
                          props.allOption,
                          ...props.options,
                      ]);
                  }
                  let result = [];
                  if (selected.length === props.options.length) {
                      if (selected.includes(props.allOption)) {
                          result = selected.filter(
                              (option) => option.value !== props.allOption.value
                          );
                      } else if (event.action === 'select-option') {
                          result = [props.allOption, ...props.options];
                      }
                      return props.onChange(result);
                  }
              }

              return props.onChange(selected);
          };

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            width: state.selectProps.width,
        }),
    };

    const modifiedProps = {
        ...props,
        options: options,
        isMulti: true,
        closeMenuOnSelect: false,
        hideSelectedOptions: false,
        components: {
            ValueContainer: ValueContainer,
            animatedComponents,
        },
        formatOptionLabel: props.formatOptionLabel,
        onChange: onChange,
        styles: customStyles,
        menuPlacement: 'auto',
    };

    return <Select {...modifiedProps} />;
};

MultiSelect.defaultProps = {
    allOption: {
        label: 'Select all',
        value: '*',
    },
};

export default MultiSelect;
