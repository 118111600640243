import React, { useState, useEffect } from 'react';
import { MultiSelect, buttons, CSVForm } from '../../components/index';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { v4 as uuidv4 } from 'uuid';
import { useDependencies } from '../../DependencyProvider';
import Loader from '../../components/Loader';
import { competitionUtils } from '@/utils';
import RadioButtonItemCompetition from '@/components/RadioButtonItemCompetition';
// import ErrorMsg from "../../components/ErrorMsg";

const CreateCompetition = ({
    periods,
    operatingChains,
    onClose,
    parameters,
    updateCompetitionList,
    validPeriodOptions,
    setIsUpdating,
}) => {
    const { apiFactory } = useDependencies();
    const [competitionName, setCompetitionName] = useState('');
    const [nameIsEmpty, setNameIsEmpty] = useState(false);
    const [mainParamIsEmpty, setMainParamIsEmpty] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState();
    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
    const [customDateStart, setCustomDateStart] = useState('');
    const [customDateEnd, setCustomDateEnd] = useState('');
    const [selectedExpirationDate, setSelectedExpirationDate] = useState('');
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [validParameters, setValidParameters] = useState([]);
    const [selectedMainParameter, setSelectedMainParameter] = useState([]);
    const [selectedDisplayParameters, setSelectedDisplayParameters] = useState(
        []
    );
    const [selectedOpChain, setSelectedOpChain] = useState();
    const [parametersOptions, setParametersOptions] = useState([]);
    const [filteredStoreIds, setFilteredStoreIds] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    // const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [state, setState] = useState({});
    const [addParticipantsFrom, setAddParticipantsFrom] = useState('dropdown');
    const { employeeFactorsApi, masterdataApi, competitionsApi } = apiFactory;
    const {
        formatEmployeeLabel,
        EmployeeValueLabel,
        selectStyles,
        sampleCSV,
        activeParticipantsSelect,
        activeCSV,
        selectedTab,
        unselectedTab,
        filterEmployeeOptions,
        getCompleteSelectedParticipants,
    } = competitionUtils;

    useEffect(() => {
        async function getData() {
            const [employees, stores] = await Promise.all([
                masterdataApi.getEmployees(),
                masterdataApi.getStores(),
            ]);

            const employeeOptions = employees.map((employee) => ({
                label: `${employee.firstname} ${employee.lastname}`,
                employeeId: employee.employeeId,
                storeId: employee.storeId,
                username: employee.username,
                value: employee.employeeId,
            }));

            const periodOptions = periods;

            const storeOptions = stores.map((store) => ({
                label: store.departmentName,
                value: store.storeId,
                operatingChain: store.operatingChain,
            }));

            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.label,
                value: op.value,
            }));

            setState({
                employeeOptions,
                periodOptions,
                storeOptions,
                operatingChainOptions,
            });
            setIsLoaded(true);
        }
        getData();
    }, [employeeFactorsApi, masterdataApi, periods, operatingChains]);

    useEffect(() => {
        compNameIsEmpty(competitionName);
    }, [competitionName]);

    const compNameIsEmpty = (competitionName) => {
        const isEmpty =
            competitionName.length > 0 && competitionName.trim().length === 0;
        setNameIsEmpty(isEmpty);
    };

    const createCompetition = async (event) => {
        event.preventDefault();

        if (
            competitionName &&
            !nameIsEmpty &&
            (selectedPeriod || (customDateStart && customDateEnd)) &&
            selectedExpirationDate &&
            selectedMainParameter.label &&
            selectedOpChain &&
            selectedParticipants.length > 0
        ) {
            setIsSaving(true);
            setIsLoaded(false);
            const id = uuidv4();

            const filteredSelectedParticipants = selectedParticipants.map(
                (sp) => {
                    return {
                        username: sp.username,
                        employeeId: sp.employeeId,
                        storeId: sp.storeId,
                    };
                }
            );
            const competitionData = {
                id: id,
                name: competitionName,
                period: selectedPeriod ? selectedPeriod : null,
                expireAt: selectedExpirationDate,
                mainParameter: selectedMainParameter.label,
                displayParameters: selectedDisplayParameters.map(
                    (obj) => obj.label
                ),
                operatingChain: selectedOpChain,
                participants: filteredSelectedParticipants,
                opchain: selectedOpChain.value,
                startDate: !selectedPeriod
                    ? parseInt(customDateStart.replaceAll('-', ''))
                    : null,
                endDate: !selectedPeriod
                    ? parseInt(customDateEnd.replaceAll('-', ''))
                    : null,
            };

            await competitionsApi.createCompetition(competitionData);
            updateCompetitionList();
            onClose();
            setIsSaving(false);
            setIsLoaded(true);
            setIsUpdating(true);
            setAddParticipantsFrom('');
        } else {
            competitionName.length > 0
                ? compNameIsEmpty(competitionName)
                : setNameIsEmpty(true);
            selectedMainParameter.label
                ? setMainParamIsEmpty(false)
                : setMainParamIsEmpty(true);
        }
    };

    const handleMainParameterChange = (parameter) => {
        const filteredParameters = validParameters.filter(
            (p) => p.label !== parameter.label
        );
        setParametersOptions(filteredParameters);
        setSelectedMainParameter(parameter);
    };

    // Filters employees available in dropdown select after chosen opChain
    useEffect(() => {
        if (state.employeeOptions) {
            const availableEmployees = state.employeeOptions.filter(
                (employee) =>
                    filteredStoreIds.includes(employee.storeId) && employee
            );
            setFilteredEmployees(availableEmployees);
        }
    }, [selectedOpChain, filteredStoreIds, state.employeeOptions]);

    const handleOpChainChange = (opChain) => {
        const chosenStores = state.storeOptions.filter(
            (store) => store.operatingChain === opChain.value
        );
        setFilteredStoreIds(
            chosenStores.map((store) => store.value).sort((a, b) => a - b)
        );
        setSelectedOpChain(opChain);
    };

    const loadOptions = (inputValue) => {
        const lowerInput = inputValue.toLowerCase();
        const options = filterEmployeeOptions(lowerInput, filteredEmployees);

        return new Promise((resolve) => resolve(options));
    };

    const findValidEndDate = () => {
        if (customDateEnd) {
            return customDateEnd;
        }
        return selectedPeriod === validPeriodOptions[0].value
            ? validPeriodOptions[0].endDate
            : validPeriodOptions[1].endDate;
    };

    const setCorrectParameters = (periodType) => {
        if (periodType === 'presetPeriod') {
            setValidParameters(parameters);
        }

        if (periodType === 'custom') {
            setValidParameters(
                parameters.filter(
                    (param) =>
                        param.label !== 'Total Points' &&
                        param.label !== 'Payout' &&
                        param.label !== 'Customer Recruitment Points'
                )
            );
        }
    };

    const handlePeriodChange = async (period) => {
        if (period === 'custom') {
            setSelectedPeriod('');
            setShowCustomDatePicker(true);
            setSelectedExpirationDate('');
            setSelectedMainParameter([]);
            setSelectedDisplayParameters([]);
            setCorrectParameters('custom');
            return;
        }
        setShowCustomDatePicker(false);
        setCustomDateStart('');
        setCustomDateEnd('');
        setSelectedPeriod(period.value);
        setSelectedExpirationDate('');
        setSelectedMainParameter([]);
        setSelectedDisplayParameters([]);
        setCorrectParameters('presetPeriod');
    };

    const activeSelect = 'flex flex-col w-full h-auto';
    const disabledSelect =
        'flex flex-col w-full opacity-50 pointer-events-none h-38px';

    const disabledCSV =
        'flex flex-col disabled opacity-50 pointer-events-none pt-4 w-full';

    const buttonStyle =
        'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold rounded-md text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';

    /**
     * CSV functionality
     */

    const validateRow = (row) => {
        const participant = {
            employeeId: row[0],
            storeId: row[1],
        };

        const existingEmployee = filteredEmployees.filter(
            (empl) =>
                empl.employeeId === participant.employeeId &&
                empl.storeId === participant.storeId
        );

        if (existingEmployee.length === 1) {
            return { isValid: true, text: ['OK'] };
        } else {
            return {
                isValid: false,
                text: ['Invalid employee ID or store ID'],
            };
        }
    };

    const handleAddCSV = (state) => {
        const completeNewSelectedParticipants = getCompleteSelectedParticipants(
            state,
            selectedParticipants,
            filteredEmployees
        );

        setSelectedParticipants(
            completeNewSelectedParticipants.filter(
                (v, i, a) =>
                    a.findIndex((v2) =>
                        ['employeeId', 'storeId'].every((k) => v2[k] === v[k])
                    ) === i
            )
        );

        setAddParticipantsFrom('dropdown');
    };

    /**
     * End CSV functionality
     */

    const datepickerStyle =
        'border p-2.5 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-full';

    return isLoaded ? (
        <div className="bg-gray-700 p-6 rounded-lg">
            <div className="flex flex-col w-full">
                <h3 className="font-bold text-center h-10 text-xl text-gray-50">
                    Create new competition
                </h3>
                <div className="w-full flex flex-col gap-4">
                    <div className="w-full flex flex-row gap-4 justify-between">
                        <div className="flex flex-col flex-grow sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">
                            <label
                                htmlFor="competition-name"
                                className="text-gray-50 font-medium"
                            >
                                Competition name
                            </label>
                            <span className="flex flex-col w-full h-38px">
                                <input
                                    type="text"
                                    name="competition-name"
                                    id="competition-name"
                                    onChange={(e) =>
                                        setCompetitionName(e.target.value)
                                    }
                                    className="h-full w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md p-2 grow"
                                />
                                {/* <ErrorMsg condition={showErrorMsg && !competitionName} errorMsg="This field is required"/> */}
                            </span>
                            {nameIsEmpty ? (
                                <span className="italic text-red-500 -mt-4">
                                    * Field cannot be empty
                                </span>
                            ) : null}
                        </div>

                        <div className="flex flex-col flex-grow sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">
                            <label className="text-gray-50 font-medium">
                                Period
                            </label>
                            <div className="w-full">
                                <ul className="items-center w-full h-38px text-sm font-medium text-gray-900 bg-white rounded-md border border-gray-300 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                    <RadioButtonItemCompetition
                                        period={validPeriodOptions[0]}
                                        handlePeriodChange={handlePeriodChange}
                                    />
                                    <RadioButtonItemCompetition
                                        period={validPeriodOptions[1]}
                                        handlePeriodChange={handlePeriodChange}
                                    />
                                    <RadioButtonItemCompetition
                                        period={{
                                            label: 'Custom',
                                            value: 'custom',
                                        }}
                                        handlePeriodChange={handlePeriodChange}
                                    />
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-row gap-10">
                        <div className="flex flex-col flex-grow sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">
                            <label
                                htmlFor="custom-date-start"
                                className="text-gray-50 font-medium"
                            >
                                Custom date start
                            </label>
                            <span
                                className={
                                    showCustomDatePicker
                                        ? activeSelect
                                        : disabledSelect
                                }
                            >
                                <input
                                    type="date"
                                    id="custom-date-start"
                                    name="custom-date-picker-start"
                                    value={customDateStart}
                                    onChange={(e) =>
                                        setCustomDateStart(e.target.value)
                                    }
                                    min={new Date().toISOString().slice(0, -14)}
                                    className={datepickerStyle}
                                ></input>
                            </span>
                        </div>

                        <div className="flex flex-col flex-grow sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">
                            <label
                                htmlFor="custom-date-end"
                                className="text-gray-50 font-medium"
                            >
                                Custom date end
                            </label>
                            <span
                                className={
                                    customDateStart !== ''
                                        ? activeSelect
                                        : disabledSelect
                                }
                            >
                                <input
                                    type="date"
                                    id="custom-date-end"
                                    name="custom-date-picker-end"
                                    value={customDateEnd}
                                    onChange={(e) =>
                                        setCustomDateEnd(e.target.value)
                                    }
                                    min={customDateStart}
                                    className={datepickerStyle}
                                ></input>
                            </span>
                        </div>

                        <div className="flex flex-col flex-grow sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">
                            <label
                                htmlFor="expiration-date"
                                className="text-gray-50 font-medium"
                            >
                                Invitation expiration date
                            </label>
                            <span
                                className={
                                    selectedPeriod || customDateEnd
                                        ? activeSelect
                                        : disabledSelect
                                }
                            >
                                <input
                                    type="date"
                                    id="start"
                                    name="expiration-date"
                                    value={selectedExpirationDate}
                                    onChange={(e) =>
                                        setSelectedExpirationDate(
                                            e.target.value
                                        )
                                    }
                                    min={new Date().toISOString().slice(0, -14)}
                                    max={findValidEndDate()}
                                    className={datepickerStyle}
                                ></input>
                            </span>
                        </div>
                    </div>

                    <div className="w-full flex flex-row gap-10">
                        <div className="w-1/2 flex flex-col">
                            <div className="flex flex-col sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">
                                <label
                                    htmlFor="main-parameter"
                                    className="text-gray-50 font-medium"
                                >
                                    Main parameter
                                </label>
                                <span className="flex flex-col w-full">
                                    <Select
                                        menuPortalTarget={document.body}
                                        onChange={handleMainParameterChange}
                                        options={validParameters}
                                        value={selectedMainParameter}
                                    />
                                </span>
                                {mainParamIsEmpty ? (
                                    <span className="italic text-red-500 -mt-4 -mb-6">
                                        * Field cannot be empty
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">
                                <label
                                    htmlFor="other-parameters"
                                    className="text-gray-50 font-medium pt-2 pb-2"
                                >
                                    Display parameters
                                </label>
                                <span
                                    className={
                                        selectedMainParameter.length === 0
                                            ? disabledSelect
                                            : activeSelect
                                    }
                                >
                                    <MultiSelect
                                        onChange={(parameters) =>
                                            setSelectedDisplayParameters(
                                                parameters
                                            )
                                        }
                                        options={parametersOptions}
                                        menuPortalTarget={document.body}
                                        value={selectedDisplayParameters}
                                    />
                                </span>
                            </div>
                        </div>

                        <div className="w-1/2 flex flex-col">
                            <div className="w-full flex flex-col">
                                <div className="flex flex-col sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">
                                    <label
                                        htmlFor="opchain"
                                        className="text-gray-50 font-medium"
                                    >
                                        Operating Chain
                                    </label>
                                    <span className="flex flex-col w-full">
                                        <Select
                                            onChange={handleOpChainChange}
                                            options={
                                                state.operatingChainOptions
                                            }
                                            menuPortalTarget={document.body}
                                        />
                                        {/* <ErrorMsg condition={showErrorMsg && selectedMainParameter.length === 0 } errorMsg="This field is required"/> */}
                                    </span>
                                </div>

                                <div className="flex flex-col sm:items-start sm:pt-5 sm:pb-5">
                                    <div className="flex flex-row items-center w-full gap-1">
                                        <label className="text-gray-50 pr-2 py-2 font-medium">
                                            Add participants from...
                                        </label>
                                        <div
                                            className={
                                                addParticipantsFrom ===
                                                'dropdown'
                                                    ? selectedTab
                                                    : unselectedTab
                                            }
                                            onClick={() =>
                                                setAddParticipantsFrom(
                                                    'dropdown'
                                                )
                                            }
                                        >
                                            Dropdown
                                        </div>
                                        <div
                                            className={
                                                addParticipantsFrom === 'csv'
                                                    ? selectedTab
                                                    : unselectedTab
                                            }
                                            onClick={() =>
                                                setAddParticipantsFrom('csv')
                                            }
                                        >
                                            CSV
                                        </div>
                                    </div>

                                    {addParticipantsFrom === 'dropdown' ? (
                                        <div className="flex flex-col sm:items-start w-full pt-4">
                                            <span
                                                className={
                                                    selectedOpChain ===
                                                    undefined
                                                        ? disabledSelect
                                                        : activeParticipantsSelect
                                                }
                                            >
                                                <AsyncSelect
                                                    loadOptions={loadOptions}
                                                    isMulti
                                                    onChange={(e) =>
                                                        setSelectedParticipants(
                                                            e,
                                                            ...selectedParticipants
                                                        )
                                                    }
                                                    formatOptionLabel={
                                                        formatEmployeeLabel
                                                    }
                                                    components={{
                                                        MultiValueLabel:
                                                            EmployeeValueLabel,
                                                    }}
                                                    styles={selectStyles}
                                                    name="participants"
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    value={selectedParticipants}
                                                />
                                                {/* <ErrorMsg condition={showErrorMsg && selectedParticipants.length === 0 } errorMsg="This field is required"/> */}
                                            </span>
                                        </div>
                                    ) : null}

                                    {addParticipantsFrom === 'csv' ? (
                                        <div
                                            className={
                                                selectedOpChain === undefined
                                                    ? disabledCSV
                                                    : activeCSV
                                            }
                                        >
                                            <CSVForm
                                                columns={[
                                                    'Employee ID',
                                                    'Store ID',
                                                ]}
                                                participants={true}
                                                sampleCSV={sampleCSV}
                                                onSave={handleAddCSV}
                                                validateRow={validateRow}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-5 border-t border-gray-600 mt-4">
                <div className="flex justify-end gap-4">
                    <buttons.Cancel
                        text="Cancel"
                        onCancel={onClose}
                        className={buttonStyle}
                    />
                    <buttons.Save
                        text="Create"
                        onSave={createCompetition}
                        className={buttonStyle}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className="flex flex-col justify-center items-center p-8 pb-16">
            {isSaving ? <Loader text="Saving..." /> : <Loader />}
        </div>
    );
};

export default CreateCompetition;
