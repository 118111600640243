export const constants = {
    PRODUCTION: 'PRODUCTION',
    DATE_SPECIFIC: 'DATE_SPECIFIC',
    PERIOD_SPECIFIC: 'PERIOD_SPECIFIC',
};

export const roles = {
    ADMIN: 'BonusManagerAdmin',
    SIMULATION: 'BonusManagerSimulation',
    HR: 'BonusManagerHR',
};

export const pages = {
    SIMULATIONPAGE: 'SimulationPage',
    EMPLOYEEFACTORS: 'EmployeeFactors',
    COMPETITIONPAGE: 'CompetitionPage',
    OPERATINGCHAINS: 'OperatingChains',
};

export const ruleMetricOptions = [
    { label: 'Qty', value: 'QTY' },
    { label: 'Revenue', value: 'REV' },
    { label: 'GM', value: 'GM' },
];

export const staffTypeOptions = [
    { label: 'Sales', value: 'SALES' },
    { label: 'Operations', value: 'OPERATIONS' },
];

export const rewardTypeOptions = [
    { label: 'Amount', value: 'AMOUNT' },
    { label: 'Amount fixed', value: 'AMOUNT_FIXED' },
    { label: 'Points', value: 'POINTS' },
    { label: 'Points fixed', value: 'POINTS_FIXED' },
];
