import React from 'react';

const ErrorMsg = (props) => {
    return (
        <div className="text-red-700 p-1">
            {props.condition && props.errorMsg}
        </div>
    );
};

export default ErrorMsg;
