import React, { useState } from 'react';

const NumberCell = ({
    value,
    row: {
        original: { id: rowId },
    },
    column: { id },
    onUpdateData,
}) => {
    const config = { ...{ isDisabled: false }, ...value };
    const [initialValue, setInitialValue] = useState(config.value);
    const [inputValue, setInputValue] = useState(config.value);
    const [inputChanged, setInputChanged] = useState(false);
    const onBlur = (e) => {
        if (inputChanged) {
            onUpdateData(rowId, id, e.target.value);
            setInputChanged(false);
        }
    };

    const onChange = (e) => {
        setInputChanged(true);
        setInputValue(e.target.value);
    };

    if (initialValue != config.value) {
        setInitialValue(config.value);
        setInputValue(config.value);
    }

    const extraClasses = config.isDisabled ? 'disabled:opacity-50' : '';
    const className = `border-solid border-gray-200 border-2 w-full p-1 ${extraClasses}`;
    return (
        <div className="whitespace-nowrap w-full">
            <input
                type="number"
                value={inputValue}
                onChange={onChange}
                onBlur={onBlur}
                className={className}
                disabled={config.isDisabled}
            />
        </div>
    );
};

export default NumberCell;
