import React, { createContext, useContext } from 'react';

const DependencyContext = createContext({});

export function useDependencies() {
    return useContext(DependencyContext);
}

export function DependencyProvider({ children, ...services }) {
    return (
        <DependencyContext.Provider value={services}>
            {children}
        </DependencyContext.Provider>
    );
}
