import React, { useMemo } from 'react';
import DisplayTable from '../../components/DisplayTable';
import { competitionColumnTypes } from '../../components/DisplayTable/index';
import { buttons } from '@/components';

const PendingInvitations = ({ participants, editMode, removeParticipant }) => {
    const pendeningColumns = useMemo(() => {
        const deleteCompetitionButton = (row) => {
            return (
                <buttons.Delete
                    text=""
                    customClass="flex justify-center"
                    onDelete={() => removeParticipant(row.original)}
                />
            );
        };

        const fixedColumns = [
            competitionColumnTypes.detailUsername.column(),
            competitionColumnTypes.storeId.column(),
            competitionColumnTypes.createdAt.column(),
            competitionColumnTypes.expiresAt.column(),
        ];
        if (editMode) {
            fixedColumns.push(
                competitionColumnTypes.buttonColumn.column(
                    'delte-button-column',
                    deleteCompetitionButton,
                    true
                )
            );
        }
        return [...fixedColumns];
    }, [editMode, removeParticipant]);

    return (
        <div className="w-full">
            <h2 className="font-bold h-10 text-xl text-gray-50">
                Pending Invitations
            </h2>
            <DisplayTable
                columns={pendeningColumns}
                tableData={participants}
                competition={true}
                showPageOptions={false}
            />
        </div>
    );
};

export default PendingInvitations;
