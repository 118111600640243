import React, { useState } from 'react';

const NumberCellSorting = (data) => {
    const config = { ...{ isDisabled: false }, ...data.value };
    const [initialValue, setInitialValue] = useState(data.value);
    const [inputValue, setInputValue] = useState(data.value);
    const [inputChanged, setInputChanged] = useState(false);
    const onBlur = (e) => {
        if (inputChanged) {
            data.onUpdateData(
                data.row.original.id,
                data.column.id,
                e.target.value
            );
            setInputChanged(false);
        }
    };

    const onChange = (e) => {
        setInputChanged(true);
        setInputValue(e.target.value);
    };

    if (initialValue != data.value) {
        setInitialValue(data.value);
        setInputValue(data.value);
    }

    const extraClasses = config.isDisabled ? 'disabled:opacity-50' : '';
    const className = `border-solid border-gray-200 border-2 w-full p-1 ${extraClasses}`;
    return (
        <div className="whitespace-nowrap w-full">
            <input
                type="number"
                value={inputValue}
                onChange={onChange}
                onBlur={onBlur}
                className={className}
                disabled={config.isDisabled}
            />
        </div>
    );
};

export default NumberCellSorting;
