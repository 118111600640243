import { columnTypes } from '@/components/Table/index';
import { v4 as uuidv4 } from 'uuid';
import { valueTypes } from '@/utils';

export const saveBonusGeneral =
    (save) => async (tableData, fiscalKey, simulationId) => {
        const transform = (tableData) => {
            const data = tableData.map((row) => {
                return {
                    id: row.id,
                    key: columnTypes.ruleType.value(row),
                    keyValue: columnTypes.keyValue.value(row),
                    revGMIndicator: columnTypes.revGMIndicator.value(row),
                    valueType: columnTypes.valueType.value(row),
                    operatingChains: row.operatingChains,
                    startDate: columnTypes.startDate.value(row),
                    endDate: columnTypes.endDate.value(row),
                };
            });
            return {
                fiscalKey: fiscalKey,
                data: data,
                simulationId: simulationId,
            };
        };
        return await saveTable(save, transform)(tableData);
    };
    export const saveStackedBonusGeneral =
    (save) => async (tableData, simulationId) => {
        const transform = (tableData) => {
            const data = tableData.map((row) => {
                //TODO - unify retrieval of data from row
                return {
                    id: row.id,
                    operatingChain: columnTypes.operatingChain.value(row),
                    startDate: columnTypes.startDate.value(row),
                    endDate: columnTypes.endDate.value(row),
                    fiscalKey: row.fiscalKey,
                    staffType: row.staffType,
                    ruleType: columnTypes.ruleType.value(row, 'ruleType'),
                    ruleValue: columnTypes.keyValue.value(row, 'ruleValue'),
                    ruleMetric: columnTypes.ruleMetric.value(row),
                    limitIncl: row.limit,
                    rewardType: columnTypes.rewardType.value(row),
                    rewardValue: row.rewardValue,
                }
            });
            return {
                data: data,
                simulationId: simulationId,
            };
        };
        return await saveTable(save, transform)(tableData);
    };
export const saveRecurring =
    (apiEndpoint) => async (tableData, fiscalKey, simulationId) => {
        const data = {
            fiscalKey: fiscalKey,
            data: tableData,
            simulationId: simulationId,
        };

        return await apiEndpoint(data);
    };

export const updateRowGeneral = (id, columnName, value, tableData) => {
    columnName === 'value' ? (value = parseFloat(value).toFixed(2)) : '';

    const newTableData = [...tableData];
    const rowIndex = newTableData.findIndex((y) => y.id == id);
    const row = newTableData[rowIndex];
    columnName === 'value'
        ? (row[columnName] = value)
        : (row[columnName].value = value);

    return newTableData;
};

export const saveTable = (apiEndpoint, transform) => async (tableData) => {
    const data = transform(tableData);
    return await apiEndpoint(data);
};

export const createRow = (ruleTypes, operatingChains) => (total) => {
    const row = {
        id: total.id,
        altId: total.altId,
        key: columnTypes.ruleType.cell(total.key, ruleTypes),
        valueType: columnTypes.valueType.cell(total.valueType),
        operatingChain: columnTypes.operatingChainType.cell(
            total.operatingChain,
            operatingChains
        ),
        value: columnTypes.operatingChainValueTwo.cell(total.value),
    };
    return row;
};

export const prepareTableData = (data, fiscalKey) => {
    return data.filter((t) => t.fiscalKey === fiscalKey).flatMap((d) => d.data);
};

export const prepareTableDataMerge = (chosenPeriod, copiedPeriod) => {
    const chosenPeriodClone = JSON.parse(JSON.stringify(chosenPeriod));
    const copiedPeriodClone = JSON.parse(JSON.stringify(copiedPeriod));
    const removeIndex = [];
    chosenPeriod.forEach((el, chosenIndex) => {
        copiedPeriod.forEach((tr, copiedIndex) => {
            /*DeleteMergeParameters removes the parameters that could be different and that we do not want to compare */
            deleteMergeParameters(
                chosenPeriodClone[chosenIndex],
                copiedPeriodClone[copiedIndex]
            );

            /* Compare chosen and copied in a string. This way we don't have to do if-statements and it gets more dynamic */
            if (
                JSON.stringify(copiedPeriodClone[copiedIndex]) ===
                    JSON.stringify(chosenPeriodClone[chosenIndex]) &&
                el.value &&
                tr.value
            ) {
                el.value.value = tr.value.value;
                removeIndex.push(copiedIndex);
            } else if (
                JSON.stringify(copiedPeriodClone[copiedIndex]) ===
                    JSON.stringify(chosenPeriodClone[chosenIndex]) &&
                /* Operations Target doesnt have value, therefore, check target value */
                el.target.value &&
                tr.target.value &&
                el.vsLastYear.value &&
                tr.vsLastYear.value
            ) {
                el.target.value = tr.target.value;
                el.vsLastYear.value = tr.vsLastYear.value;
                removeIndex.push(copiedIndex);
            }
        });
    });

    if (removeIndex.length === copiedPeriod.length) {
        copiedPeriod = [];
    } else {
        removeIndex.forEach((index) => {
            copiedPeriod.splice(index, 1);
        });
    }
    return [...chosenPeriod, ...copiedPeriod];
};

const deleteMergeParameters = (chosenPeriod, copiedPeriod) => {
    delete chosenPeriod.id;
    delete chosenPeriod.altId;
    delete chosenPeriod.value;
    delete chosenPeriod.target;
    delete chosenPeriod.vsLastYear;
    delete copiedPeriod.id;
    delete copiedPeriod.altId;
    delete copiedPeriod.value;
    delete copiedPeriod.target;
    delete copiedPeriod.vsLastYear;
};

/* This function will check if row value is not zero. 
If it is zero we wont display the row and next time it will be saved the row will disappear from the database*/
export const checkRowValue = (value) => {
    return value !== 0;
};

export const prepareRuleTypes = (measures, table) => {
    const measureMap = measures.reduce(
        (acc, m) => ({ ...acc, [m.key]: m }),
        {}
    );
    const filteredMeasures = Object.values(measureMap);
    const ruleTypes = filteredMeasures
        .filter((m) => m[`${table}`])
        .map((m) => {
            return {
                value: m.key,
                label: m.description,
            };
        });
    return ruleTypes;
};

export const prepareCsvData = (data, setTableData, tableData, state) => {
    const createItem = (row) => {
        const item = {
            id: uuidv4(),
            operatingChain: row.items[0],
            key: row.items[1],
            valueType: row.items[2].toUpperCase(),
            value: row.items[3].replace(',', '.'),
        };
        item.altId = item.id;
        item.id = item.id + '#' + 1;
        return item;
    };
    const input = data.data.map(createItem);
    const existingRows = tableData.map((tr) => {
        const updatedRow = input.find(
            (i) =>
                i.key === tr.key.value &&
                i.operatingChain === tr.operatingChain.value
        );
        if (updatedRow) {
            tr.value = updatedRow.value;
        }
        return tr;
    });
    const newRows = input
        .filter(
            (r) =>
                existingRows.findIndex(
                    (ud) =>
                        r.key === ud.key.value &&
                        r.operatingChain === ud.operatingChain.value
                ) < 0
        )
        .map(createRow(state.ruleTypes, state.operatingChainOptions));
    setTableData([...existingRows, ...newRows]);
};

export const sortByRuleValueAndOpChain = (
    ruleTypes,
    opChains,
    allData,
    fiscalKey
) => {
    const data = prepareTableData(allData, fiscalKey);

    const unsortedList = createNewRowsObject(data)
        .map(createRow(ruleTypes, opChains))
        .filter((obj) => obj.operatingChain.label.length > 0);

    const sortedByRuleValue = unsortedList.sort((a, b) =>
        b.key.label > a.key.label ? -1 : a.key.label > b.key.label ? 1 : 0
    );

    const sortedByOperatingChain = sortedByRuleValue.sort((a, b) =>
        b.operatingChain.label[0].label > a.operatingChain.label[0].label
            ? -1
            : a.operatingChain.label[0].label > b.operatingChain.label[0].label
            ? 1
            : 0
    );
    return sortedByOperatingChain;
};

export const createNewRowsObject = (filteredRows) => {
    const newRows = [];
    filteredRows.forEach((row) => {
        row.id = uuidv4();
        row.operatingChains.forEach((el, index) => {
            if (checkRowValue(el.value)) {
                newRows.push({
                    id: row.id + '#' + index,
                    altId: row.id,
                    key: row.key,
                    operatingChain: el.operatingChain,
                    value: el.value,
                    valueType: row.valueType,
                });
            }
        });
    });
    return newRows;
};

export const createNewRowsObjectSettingsStore = (filteredRows) => {
    filteredRows.forEach((row) => {
        row.id = uuidv4();
    });
    return filteredRows;
};

export const prepareDataForSave = (tableData) => {
    const formattedRows = [];
    tableData.forEach((row) => {
        if (
            !formattedRows.some((e) => e.key === row.key.value) ||
            formattedRows.length === 0
        ) {
            formattedRows.push({
                id: row.altId,
                operatingChains: [
                    {
                        operatingChain: row.operatingChain.value,
                        value: row.value || row.value.value,
                    },
                ],
                key: row.key.value,
                valueType: row.valueType,
            });
        } else {
            formattedRows.forEach((el) => {
                if (el.key === row.key.value) {
                    el.operatingChains.push({
                        operatingChain: row.operatingChain.value,
                        value: row.value || row.value.value,
                    });
                }
            });
        }
    });
    return formattedRows;
};

export const newRowGeneral = (setTableData, tableData, filterSpec, state) => {
    const opChainValue =
        filterSpec &&
        filterSpec.operatingChain &&
        filterSpec.operatingChain.value;
    const rowToCreate = {
        id: uuidv4(),
        valueType: valueTypes.percentage,
        operatingChain: opChainValue || '',
        value: 0,
    };
    rowToCreate.altId = rowToCreate.id;
    const row = createRow(
        state.ruleTypes,
        state.operatingChainOptions
    )(rowToCreate);
    const newTableData = [...tableData, row];
    setTableData(newTableData);
};

export const getYearFromPeriod = (fiscalKey) => (fiscalKey.trim().substring(0, 4));

export const getDateFromDateTime = (dateTime) => (dateTime ? dateTime.split('T')[0] : undefined);

export const getDatesFromPeriod = (fiscalKey, bonusPeriods) => {
    const year = getYearFromPeriod(fiscalKey);
    const bonusPeriodsInYear = bonusPeriods.filter(period => period.label === year);
    const bonusPeriodOptions = bonusPeriodsInYear[0]?.options;
    const period = bonusPeriodOptions?.find(option => option.value === fiscalKey)?.period;
    const startDate = getDateFromDateTime(period?.startDate);
    const endDate = getDateFromDateTime(period?.endDate);

    return period ? {startDate, endDate} : undefined;
};

export const hasSameDates = (valueOne, valueTwo, bonusPeriods) => {
    const valueOneDates = {
        startDate: (valueOne.startDate && valueOne.startDate !== '')
            ? Date.parse(valueOne.startDate)
            : Date.parse(getDatesFromPeriod(valueOne.fiscalKey, bonusPeriods).startDate),
        endDate: (valueOne.endDate && valueOne.endDate !== '')
            ? Date.parse(valueOne.endDate)
            : Date.parse(getDatesFromPeriod(valueOne.fiscalKey, bonusPeriods).endDate),
    };

    const valueTwoDates = {
        startDate: (valueTwo.startDate.value && valueTwo.startDate.value !== '') 
            ? Date.parse(valueTwo.startDate.value)
            : Date.parse(getDatesFromPeriod(valueTwo.fiscalKey.value, bonusPeriods).startDate),
        endDate: (valueTwo.endDate.value && valueTwo.endDate.value !== '')
            ? Date.parse(valueTwo.endDate.value)
            : Date.parse(getDatesFromPeriod(valueTwo.fiscalKey.value, bonusPeriods).endDate),
    };

    return (valueTwoDates.startDate >= valueOneDates.startDate && valueTwoDates.startDate <= valueOneDates.endDate)
        || (valueTwoDates.endDate >= valueOneDates.startDate && valueTwoDates.endDate <= valueOneDates.endDate)
        || (valueTwoDates.startDate <= valueOneDates.startDate && valueTwoDates.endDate >= valueOneDates.endDate)
        || (valueTwoDates.startDate >= valueOneDates.startDate && valueTwoDates.endDate <= valueOneDates.endDate);
};

export const existsPeriod = (fiscalKey, bonusPeriods) => (
    bonusPeriods.find(period => period.options?.find(option => option.value === fiscalKey))
      ? true
      : false
);

const findPeriods = (startDate, endDate, bonusPeriods, year) => {
    const bonusPeriodsInYear = bonusPeriods.filter(period => period.label == year);
    return bonusPeriodsInYear && bonusPeriodsInYear[0]?.options && bonusPeriodsInYear[0]?.options.find(
        option => Date.parse(getDateFromDateTime(option.period.startDate)) <= Date.parse(startDate)
        && Date.parse(getDateFromDateTime(option.period.endDate)) >= Date.parse(endDate));
};

export const getPeriodFromDates = (startDate, endDate, bonusPeriods) => {
        if (!bonusPeriods || !startDate || !endDate || Date.parse(startDate) > Date.parse(endDate)) return undefined;

        //startDate, endDate date format YYYY-MM-DD
        const year = startDate.split('-')[0];
        //period dates can start in the next year (eg. period 202310 can have start and end dates in 2024) = check previous year if period is not found
        let bonusPeriod = findPeriods(startDate, endDate, bonusPeriods, year);
        bonusPeriod = bonusPeriod || findPeriods(startDate, endDate, bonusPeriods, Number(year) - 1);
        const isPeriodMatch = bonusPeriod
            && Date.parse(getDateFromDateTime(bonusPeriod.period.startDate)) === Date.parse(startDate)
            && Date.parse(getDateFromDateTime(bonusPeriod.period.endDate)) === Date.parse(endDate);

        return bonusPeriod
            ? {fiscalKey: bonusPeriod?.value, isPeriodMatch}
            : undefined;
    };

