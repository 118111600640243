import React from 'react';
import './reactTableCustomStyles.css';
import { DateTime } from 'luxon';

const inputFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <input
            className="custom-react-table-input"
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder="Search..."
        />
    );
};

const competitionName = {
    column: () => ({
        Header: 'Name',
        accessor: 'name',
        Filter: inputFilter,
        disableSortBy: true,
        sortType: (nameA, nameB) => {
            if (nameA.name.toUpperCase() < nameB.name.toUpperCase()) {
                return -1;
            }
            if (nameA.name.toUpperCase() > nameB.name.toUpperCase()) {
                return 1;
            }
            return 0;
        },
    }),
};

const competitionPeriod = {
    column: () => ({
        Header: 'Period',
        accessor: 'period',
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const operatingChain = {
    column: () => ({
        Header: 'Operating Chain',
        accessor: 'opchain',
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const mainParameter = {
    column: () => ({
        Header: 'Main Parameter',
        accessor: 'mainParameter',
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const displayParameters = {
    column: () => ({
        Header: 'Display Parameters',
        accessor: 'displayParameters',
        Cell: (e) => e.value.sort().join(', '),
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const numOfDisplayParameters = {
    column: () => ({
        Header: 'Display Params',
        accessor: 'displayParameters',
        id: 'numOfDisplayParameters',
        Cell: (e) => e.value.length,
        disableFilters: true,
        disableSortBy: true,
    }),
};

const chosenCompetitionPeriod = {
    column: () => ({
        Header: 'Period',
        accessor: 'period',
        Filter: inputFilter,
        disableFilters: true,
    }),
};

const chosenDisplayParameters = {
    column: () => ({
        Header: 'Display Parameters',
        accessor: 'displayParameters',
        Cell: (e) => e.value.sort().join(', '),
        Filter: inputFilter,
        disableFilters: true,
    }),
};

const participants = {
    column: () => ({
        Header: 'Participants',
        accessor: 'participants',
        Cell: (e) => {
            if (e.value.length > 1) {
                const first = e.value.slice(0, 1);
                const rest = e.value.length - 1;
                return first + ` + ${rest} more`;
            } else {
                return e.value;
            }
        },
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const numOfInvites = {
    column: () => ({
        Header: 'Total Invites',
        accessor: 'numOfInvites',
        Cell: (e) => e.value,
        Filter: inputFilter,
        disableFilters: true,
        disableSortBy: false,
    }),
};

const numOfAccepted = {
    column: () => ({
        Header: 'Accepted',
        accessor: 'numOfAccepted',
        Cell: (e) => e.value,
        Filter: inputFilter,
        disableSortBy: false,
        disableFilters: true,
    }),
};

const createdBy = {
    column: () => ({
        Header: 'Created by (username)',
        accessor: 'createdBy',
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const createdIn = {
    column: () => ({
        Header: 'Created in',
        accessor: 'system',
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const createdAt = {
    column: () => ({
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (e) => DateTime.fromISO(e.value).toFormat('ff'),
        Filter: inputFilter,
        disableFilters: true,
    }),
};

const expiresAt = {
    column: () => ({
        Header: 'Expires At',
        accessor: 'expireAt',
        Cell: (e) => DateTime.fromISO(e.value, { zone: 'utc' }).toFormat('ff'),
        Filter: inputFilter,
        disableFilters: true,
    }),
};

const rejectedAt = {
    column: () => ({
        Header: 'Rejected At',
        accessor: 'rejectedAt',
        Cell: (e) =>
            e.value ? DateTime.fromISO(e.value).toFormat('ff') : '---',
        Filter: inputFilter,
        disableFilters: true,
    }),
};

const expiredAt = {
    column: () => ({
        Header: 'Expired At',
        accessor: 'expireAt',
        Cell: (e) => {
            return new Date(e.value).getTime() < new Date().getTime()
                ? DateTime.fromISO(e.value).toFormat('ff')
                : '---';
        },
        disableFilters: true,
    }),
};

const buttonColumn = {
    column: (id, button, isAuthorized = false, header = null) => ({
        Header: header || ' ',
        id: id, // E.g. "edit-button-column"
        Cell: ({ row }) => {
            return isAuthorized ? button(row) : '';
        },
    }),
};

const chosenCompetitionName = {
    column: () => ({
        Header: 'Competition Name',
        accessor: 'name',
        disableFilters: true,
    }),
};

const employeeName = {
    column: () => ({
        Header: 'Name',
        accessor: 'name',
        Filter: inputFilter,
    }),
};

const username = {
    column: () => ({
        Header: 'Username',
        accessor: 'username',
        Filter: inputFilter,
    }),
};

const storeId = {
    column: () => ({
        Header: 'Store ID',
        accessor: 'toUserStoreId',
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const detailUsername = {
    column: () => ({
        Header: 'Username',
        accessor: 'toUser',
        Filter: inputFilter,
        disableSortBy: true,
    }),
};

const mainParameterPoints = {
    column: (name, accessor) => ({
        Header: `Main: ${name}`,
        accessor: accessor,
        Cell: (e) => {
            return e.value ? e.value.toFixed() : '0';
        },
        Filter: inputFilter,
        disableFilters: true,
    }),
};

const otherParameter = {
    column: (name, accessor) => ({
        Header: name,
        accessor: accessor,
        Cell: (e) => {
            return e.value ? e.value.toFixed() : '0';
        },
        Filter: inputFilter,
        disableFilters: true,
    }),
};

export {
    inputFilter,
    competitionName,
    operatingChain,
    mainParameter,
    displayParameters,
    numOfDisplayParameters,
    chosenDisplayParameters,
    competitionPeriod,
    chosenCompetitionPeriod,
    participants,
    numOfInvites,
    numOfAccepted,
    createdBy,
    createdIn,
    createdAt,
    expiresAt,
    rejectedAt,
    expiredAt,
    buttonColumn,
    chosenCompetitionName,
    employeeName,
    username,
    storeId,
    detailUsername,
    mainParameterPoints,
    otherParameter,
};
