import React, { useState } from 'react';
import * as buttons from './buttons';
import Select from 'react-select';
import MultiSelect from './MultiSelect';
import { customSelectDropdown } from './style';

const CopyOpchainFrom = ({ onCopy, onClose, operatingChainOptions }) => {
    const [selectedOpChains, setSelectedOpChains] = useState([]);
    const [copyToOpchain, setCopyToOpchain] = useState([]);

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <form className="flex flex-col space-y-2 w-full">
                <h2 className="font-bold text-center h-10 text-xl text-gray-50">
                    Copy data
                </h2>

                <div className="w-full flex flex-col">
                    <label className="text-gray-50">
                        From Operating chain(s)
                    </label>
                    <MultiSelect
                        options={operatingChainOptions}
                        onChange={(e) => {
                            setSelectedOpChains(e, ...selectedOpChains);
                        }}
                        className="w-full inline-block text-gray-900"
                        classNamePrefix="select-sm"
                        styles={customSelectDropdown(32)}
                        value={selectedOpChains}
                        menuPortalTarget={document.body}
                    ></MultiSelect>
                </div>
                <div className="w-full flex flex-col">
                    <label className="text-gray-50">To Operating chain</label>
                    <Select
                        options={operatingChainOptions}
                        onChange={(e) => {
                            setCopyToOpchain(e);
                        }}
                        className="w-full inline-block text-gray-900"
                        classNamePrefix="select-sm"
                        styles={customSelectDropdown(32)}
                        value={copyToOpchain}
                        menuPortalTarget={document.body}
                    ></Select>
                </div>
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Copy
                        text="Copy"
                        onClick={(e) => {
                            e.preventDefault();
                            onCopy(selectedOpChains, copyToOpchain);
                            onClose();
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

export default CopyOpchainFrom;
