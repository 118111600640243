import React, { useState, useMemo, useEffect } from 'react';
import { simulationColumnTypes, toggleRow } from '../../components/DisplayTable/index';
import DisplayTable from '../../components/DisplayTable';

import { toast } from 'react-toastify';
import { buttons } from '@/components';
import { useDependencies } from '@/DependencyProvider';
import { useRender } from '@/renderContext';
import {
    saveSimulationId,
    removeSimulationId,
    isAuthorized,
    pages,
} from '@/utils';
import { useAuth } from '@/auth';

const SimulationList = ({
    simulationList,
    updateSimulationList,
    onRunClick,
    onSimulationSelect,
}) => {
    const [data, setData] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [tableData, setTableData] = useState([]);
    const auth = useAuth();
    const roles = auth.roles;

    useMemo(() => {
        setData(simulationList);
    }, [simulationList]);

    useEffect(() => {
        setTableData(
            data.map((row, i) => ({ id: i, selected: false, ...row }))
        );
    }, [data]);

    const { setValue } = useRender();
    const { apiFactory } = useDependencies();
    const { simulationApi } = apiFactory;

    const authorized = isAuthorized(pages.SIMULATIONPAGE, roles);

    const standardBtnClass =
        'w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
    const disabledBtnClass =
        standardBtnClass + ' opacity-50 pointer-events-none';

    const reloadButton = (currentSimulationList) => {
        return (
            <buttons.Button
                className="inline-flex justify-center px-4 py-2 mx-3 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                text="Reload"
                onClick={() => handleRefreshClick(currentSimulationList)}
            />
        );
    };

    const editButton = (row) => {
        return (
            <buttons.Button
                className={standardBtnClass}
                text="Edit"
                onClick={() => editExistingSimulation(row.original)}
            />
        );
    };

    const runButton = (row) => {
        return (
            <buttons.Button
                className={
                    runSimulationAvailable(row.original.lastRunStatus)
                        ? standardBtnClass
                        : disabledBtnClass
                }
                text={
                    row.original.lastRunStatus === 'Finished' ? 'Rerun' : 'Run'
                }
                onClick={() => onRunClick(row)}
                disabled={!runSimulationAvailable(row.original.lastRunStatus)}
            />
        );
    };

    // const runButton = (row) => {
    //     return (
    //         <buttons.Button
    //             className={
    //                 runSimulationAvailable(row.original.lastRunStatus)
    //                     ? standardBtnClass
    //                     : disabledBtnClass
    //             }
    //             text={
    //                 row.original.lastRunStatus === 'Finished' ? 'Rerun' : 'Run'
    //             }
    //             onClick={() => runSimulation(row)}
    //             disabled={!runSimulationAvailable(row.original.lastRunStatus)}
    //         />
    //     );
    // };

    const deleteSimulationButton = (row) => {
        return (
            <buttons.Delete
                enabled={!isDeleting}
                text=""
                customClass="flex justify-center"
                onDelete={() => deleteSimulation(row)}
            />
        );
    };

    const columns = useMemo(() => {
        const fixedColumns = [
            simulationColumnTypes.simulationName.column(),
            simulationColumnTypes.simulationOpChains.column(),
            simulationColumnTypes.simulationPeriods.column(),
            simulationColumnTypes.createdBy.column(),
            simulationColumnTypes.createdAt.column(),
            simulationColumnTypes.lastRanBy.column(),
            simulationColumnTypes.lastRanAt.column(),
            simulationColumnTypes.lastRunStatus.column(),
            simulationColumnTypes.availableInDW.column(),
            simulationColumnTypes.buttonColumn.column(
                'edit-button-column',
                editButton,
                authorized
            ),
            simulationColumnTypes.buttonColumn.column(
                'run-button-column',
                runButton,
                authorized
            ),
            simulationColumnTypes.buttonColumn.column(
                'delte-button-column',
                deleteSimulationButton,
                true,
                reloadButton
            ),
        ];
        return [...fixedColumns];
    }, [authorized, simulationList]);

    // const runSimulation = async (row) => {
    //     await simulationApi.runSimulation({
    //         simulationId: row.original.simulationId,
    //     });
    //     handleRefreshClick(simulationList);
    // };

    const editExistingSimulation = (data) => {
        saveSimulationId(data.simulationId);
        localStorage.setItem('simulationName', data.name);
        setValue((value) => value + 1);
    };

    const handleRefreshClick = async (currentData) => {
        const rowsWithLastRunStatus = await simulationApi.getLastRunStatus(
            true
        );
        const updatedData = currentData.data.map((a) => {
            const exists = rowsWithLastRunStatus.find(
                (b) => a.simulationId == b.simulationId
            );
            return exists
                ? ((a.lastRunStatus = exists.lastRunStatus),
                  (a.lastRunTimestamp = exists.lastRunTimestamp),
                  (a.lastRunUsername = exists.lastRunUsername),
                  (a.statusInDW = exists.statusInDW),
                  a)
                : a;
        });
        setData(updatedData);
    };

    const deleteSimulation = async (row) => {
        setIsDeleting(true);
        toast.error(`Deleting simulation ${row.original.name}...`, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: false,
            toastId: 'delete-simulation',
        });
        toast.info(
            `This procedure takes a few seconds to complete. A confirmation will be shown when completed `,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: false,
                toastId: 'delete-simulation-info',
            }
        );
        try {
            var deleteResult = await simulationApi.deleteSimulation({
                simulationId: row.original.simulationId,
            });
            if (deleteResult.status == 200) {
                toast.update('delete-simulation', { autoClose: 3000 });
                toast.update('delete-simulation-info', { autoClose: 3000 });
                toast.success('Deleted', {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                });
                if (
                    localStorage.getItem('simId') === row.original.simulationId
                ) {
                    removeSimulationId();
                    localStorage.removeItem('simulationName');
                    setValue((value) => value + 1);
                }
            } else {
                toast.error(deleteResult.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (ex) {
            toast.error(ex?.response?.data || ex.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        updateSimulationList();
        setIsDeleting(false);
    };

    const runSimulationAvailable = (status) => {
        if (status === null || status === 'Finished') {
            return true;
        } else {
            return false;
        }
    };

    const toggleItem = (id) => {
        let newTableData = tableData;

        const selectedRow = tableData.find((row) => row.selected);

        //only one row can be selected at a time
        if (selectedRow && selectedRow.id !== id)
            newTableData = toggleRow(selectedRow.id, newTableData);

        newTableData = toggleRow(id, newTableData);

        setTableData(newTableData);
        onSimulationSelect(
            newTableData.filter((row) => row.selected === true)[0]
        );
    };

    return (
        <DisplayTable
            columns={columns}
            tableData={tableData}
            onToggleItem={toggleItem}
            showDeleteSelectedButton={false}
        />
    );
};

export default SimulationList;
