/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import {
    columnTypes,
    removeRow,
    removeAllRows,
} from '@/components/Table/index';
import { Table } from '@/components';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';
import { valueTypes, validators } from '@/utils';
import { Filter } from './bonusRuleGeneral/operatingChainFilter';
import {
    prepareTableData,
    prepareTableDataMerge,
    checkRowValue,
} from './bonusRuleGeneral/common';
import Loader from '../components/Loader';

const createRow = (ruleTypes, operatingChains) => (operationsTarget) => {
    const row = {
        id: operationsTarget.id,
        altId: operationsTarget.altId,
        valueType: operationsTarget.valueType,
        key: columnTypes.ruleType.cell(operationsTarget.key, ruleTypes),
        operatingChain: columnTypes.operatingChainType.cell(
            operationsTarget.operatingChain,
            operatingChains
        ),
        vsLastYear: columnTypes.vsLastYear.cell(operationsTarget.vsLastYear),
        target: columnTypes.target.cell(operationsTarget.target),
    };
    return row;
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const OperationsTargets = () => {
    const { apiFactory } = useDependencies();
    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasData, setHasData] = useState(undefined);
    const [periodOptions, setPeriodOptions] = useState([]);
    const [ruleTypes, setRuleTypes] = useState([]);
    const [operationsTargets, setOperationsTargets] = useState([]);
    const [operatingChainOptions, setOperatingChainOptions] = useState([]);
    const [filterSpec, setFilterSpec] = useState({
        operatingChain: { label: 'All' },
    });
    const navigate = useNavigate();
    const query = useQuery();
    const { masterdataApi, targetsApi, bonusSettingsApi } = apiFactory;
    const fiscalKeyQueryParam = query.get('fiscalKey') || '';
    const selectedFiscalKey = useMemo(() => {
        return (
            periodOptions
                .flatMap((o) => o.options)
                .find((fk) => fk.value === fiscalKeyQueryParam) ?? { value: '' }
        );
    }, [periodOptions, fiscalKeyQueryParam]);

    const columns = useMemo(() => {
        const fixedColumns = [
            columnTypes.operatingChainType.column(),
            columnTypes.ruleType.column(),
            columnTypes.vsLastYear.column(),
            columnTypes.target.column(),
        ];
        return [...fixedColumns];
    }, []);

    useEffect(() => {
        const data = operationsTargets
            .filter((r) => r.fiscalKey === selectedFiscalKey.value)
            .flatMap((r) => r.data);
        const tableData = createNewObject(data)
            .map(createRow(ruleTypes, operatingChainOptions))
            .filter((obj) => obj.operatingChain.label.length > 0);
        const tableDataSortedByRuleValue = tableData.sort((a, b) =>
            a.key.label > b.key.label ? 1 : a.key.label < b.key.label ? -1 : 0
        );
        const tableDataSortedByOperatingChain = tableDataSortedByRuleValue.sort(
            (a, b) =>
                a.operatingChain.label[0].label >
                b.operatingChain.label[0].label
                    ? 1
                    : a.operatingChain.label[0].label <
                      b.operatingChain.label[0].label
                    ? -1
                    : 0
        );

        setTableData(tableDataSortedByOperatingChain);
    }, [
        selectedFiscalKey,
        operationsTargets,
        operatingChainOptions,
        ruleTypes,
    ]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const [
                periodOptions,
                measures,
                operatingChains,
                operationsTargets,
            ] = await Promise.all([
                masterdataApi.getBonusPeriods({ includeEmpty: true }),
                bonusSettingsApi.getMeasures(),
                bonusSettingsApi.getOperatingChains(),
                targetsApi.getOperationsTargets(),
            ]);
            const measureMap = measures.reduce(
                (acc, m) => ({ ...acc, [m.key]: m }),
                {}
            );
            const filteredMeasures = Object.values(measureMap);
            const ruleTypes = filteredMeasures
                .filter((m) => m.storeOperations)
                .map((m) => {
                    return {
                        value: m.key,
                        label: m.description,
                    };
                });
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            if (mounted) {
                setOperatingChainOptions(operatingChainOptions);
                setRuleTypes(ruleTypes);
                setOperationsTargets(operationsTargets);
                setPeriodOptions(periodOptions);
                setHasData((operationsTargets && operationsTargets.length > 0) ? true : false);
            }
        }
        getData();
        return () => (mounted = false);
    }, [bonusSettingsApi, masterdataApi, targetsApi]);

    useEffect(() => {
        if ((hasData && tableData.length > 0) || hasData === false) {
            setIsLoaded(true);
        }
    }, [hasData, tableData]);

    const periodChanged = (selectedValue) => {
        navigate({
            search: `?fiscalKey=${selectedValue.value}`,
        });
    };

    const createNewObject = (operationsTargets) => {
        const newOperationsTargets = [];
        operationsTargets.forEach((operationsTarget) => {
            operationsTarget.id = uuidv4();

            operationsTarget.operatingChains.forEach((el, index) => {
                if (checkRowValue(el.target) || checkRowValue(el.vsLastYear)) {
                    newOperationsTargets.push({
                        id: operationsTarget.id + '#' + index,
                        altId: operationsTarget.id,
                        key: operationsTarget.key,
                        operatingChain: el.operatingChain,
                        vsLastYear: el.vsLastYear,
                        target: el.target,
                        valueType: operationsTarget.valueType,
                    });
                }
            });
        });
        return newOperationsTargets;
    };

    const newRow = () => {
        const opChainValue =
            filterSpec &&
            filterSpec.operatingChain &&
            filterSpec.operatingChain.value;
        const operationsTarget = {
            id: uuidv4(),
            valueType: valueTypes.percentage,
            operatingChain: opChainValue || '',
        };
        operationsTarget.altId = operationsTarget.id;
        const row = createRow(
            ruleTypes,
            operatingChainOptions
        )(operationsTarget);
        const newTableData = [...tableData, row];
        setTableData(newTableData);
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(filterSpec.operatingChain, tableData));
    };

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row.id = uuidv4();
        row.altId = row.id;
        row[columnName].value = value;
        setTableData(newTableData);
    };

    const saveData = async () => {
        const transform = (tableData) => {
            const formattedData = [];
            tableData.forEach((operationsTarget) => {
                if (
                    !formattedData.some(
                        (e) => e.key === operationsTarget.key.value
                    ) ||
                    formattedData.length === 0
                ) {
                    formattedData.push({
                        id: operationsTarget.altId,
                        operatingChains: [
                            {
                                operatingChain:
                                    operationsTarget.operatingChain.value,
                                target: operationsTarget.target.value,
                                vsLastYear: operationsTarget.vsLastYear.value,
                            },
                        ],
                        key: operationsTarget.key.value,
                        valueType: operationsTarget.valueType,
                    });
                } else {
                    formattedData.forEach((el) => {
                        if (el.key === operationsTarget.key.value) {
                            el.operatingChains.push({
                                operatingChain:
                                    operationsTarget.operatingChain.value,
                                target: operationsTarget.target.value,
                                vsLastYear: operationsTarget.vsLastYear.value,
                            });
                        }
                    });
                }
            });
            return {
                data: formattedData,
                fiscalKey: selectedFiscalKey.value,
                simulationId: simulationIdState.get()[0].simulationId,
            };
        };
        const data = transform(tableData);
        await targetsApi.saveOperationsTargets(data);
        const updateOperationTargets = await targetsApi.getOperationsTargets(
            true
        );
        setOperationsTargets(updateOperationTargets);
        return {
            valid: true,
        };
    };

    const { optionValidator, composeValidators, ruleTypeExistValidator } =
        validators;
    const csvRowValidator = (currentData, ruleTypes) => async (row) => {
        const validators = [
            optionValidator('Invalid rule type', ruleTypes, 1),
            ruleTypeExistValidator(currentData, 0),
        ];
        const validator = composeValidators(validators);
        return await validator(row);
    };

    const addCsvData = (data) => {
        const createItem = (row) => {
            const item = {
                id: uuidv4(),
                operatingChain: row.items[0],
                key: row.items[1],
                valueType: valueTypes.percentage,
                vsLastYear: row.items[2].replace(',', '.'),
                target: row.items[3].replace(',', '.'),
            };
            item.altId = item.id;
            item.id = item.id + '#' + 1;
            return item;
        };
        const input = data.data.map(createItem);
        const existingRows = tableData.map((tr) => {
            const updatedRow = input.find(
                (i) =>
                    i.key === tr.key.value &&
                    i.operatingChain === tr.operatingChain.value
            );
            if (updatedRow) {
                tr.vsLastYear.value = updatedRow.vsLastYear;
                tr.target.value = updatedRow.target;
            }
            return tr;
        });
        const newRows = input
            .filter(
                (r) =>
                    existingRows.findIndex(
                        (ud) =>
                            r.key === ud.key.value &&
                            r.operatingChain === ud.operatingChain.value
                    ) < 0
            )
            .map(createRow(ruleTypes, operatingChainOptions));
        setTableData([...existingRows, ...newRows]);
    };

    const rows = useMemo(() => {
        return filterSpec &&
            filterSpec.operatingChain &&
            filterSpec.operatingChain.value
            ? tableData.filter(
                  (el) =>
                      filterSpec.operatingChain.value ===
                      el.operatingChain.value
              )
            : tableData;
    }, [filterSpec, tableData]);

    const copyFiscalKey = (fiscalKeyData, operatingChainData) => {
        const chosenOpChains = operatingChainData.map((oc) => oc.value);

        const newChosenObjects = createNewObject(
            prepareTableData(operationsTargets, selectedFiscalKey.value)
        ); // Table we are copying INTO
        const newCopiedObjects = createNewObject(
            prepareTableData(operationsTargets, fiscalKeyData.value)
        ); // Data we are copying
        const copiedObjectsFilteredByOC = newCopiedObjects.filter((el) =>
            chosenOpChains.includes(el.operatingChain)
        );

        const chosenPeriodRows = newChosenObjects.map(
            createRow(ruleTypes, operatingChainOptions)
        );
        const copiedPeriodRows = copiedObjectsFilteredByOC.map(
            createRow(ruleTypes, operatingChainOptions)
        );
        setTableData(prepareTableDataMerge(chosenPeriodRows, copiedPeriodRows));
    };

    // Convert table data to string
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) =>
                    `${row.key.value}\t${row.operatingChain.value}\t${row.vsLastYear.value}\t${row.target.value}`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    return (
        <div className="flex w-full h-full flex-col">
            <div className="text-center py-8 w-full">
                <label className="font-bold">Period: </label>
                <Select
                    options={periodOptions}
                    onChange={periodChanged}
                    className="w-48 inline-block text-gray-900"
                    value={selectedFiscalKey}
                />
            </div>
            <div className="content w-full flex-1 flex flex-row">
                {isLoaded ? (
                    <Table
                        data={rows}
                        onNewRow={newRow}
                        columns={columns}
                        onSaveData={saveData}
                        rowsCSV={rowsCSV}
                        csvRowValidator={csvRowValidator(tableData, ruleTypes)}
                        components={{
                            filter: (
                                <Filter
                                    filter={filterSpec}
                                    onChange={setFilterSpec}
                                    operatingChainOptions={
                                        operatingChainOptions
                                    }
                                />
                            ),
                        }}
                        onRemoveRow={deleteRow}
                        onRemoveAllRows={deleteAllRows}
                        addCsvData={addCsvData}
                        csvColumns={[
                            'Operating Chain',
                            'Rule Type',
                            'vsLastYear',
                            'Target',
                        ]}
                        onUpdateData={updateData}
                        copyFiscalKey={copyFiscalKey}
                        showCopyFiscal={true}
                        onCopyFrom={copyFiscalKey}
                        filterSpec={filterSpec}
                        fiscalKey={fiscalKeyQueryParam}
                    />
                ) : (
                    <div className="w-full text-center">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
};

export default OperationsTargets;
