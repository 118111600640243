import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTable, usePagination, useFilters, useSortBy } from 'react-table';
import * as FaIcons from 'react-icons/fa';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import * as buttons from './buttons';
import { CSVForm, CopyFrom } from '@/components';
import CopyOpchainFrom from './CopyOpchainFrom';
import CopyRuleForm from './CopyRuleForm';
import { customSelectDropdown } from './style';
import { useDependencies } from '@/DependencyProvider';
import { isAuthorized } from '@/utils';
import { useAuth } from '@/auth';
import CopyFromSettingsStore from './CopyFromSettingsStore';
import DeleteAll from './DeleteAll';

const Table = ({
    columns,
    data,
    onUpdateData,
    onNewRow,
    onRemoveRow,
    onRemoveAllRows,
    onSaveData,
    csvRowValidator,
    sampleCSV,
    rowsCSV,
    addCsvData,
    csvColumns,
    onToggleItem,
    onToggleAll,
    onDeleteSelected,
    onCopyFrom,
    onCopyOpchainFrom,
    onCopyRule,
    showCopyFiscal = false,
    showCopyFiscalSettingsStore = false,
    showCopyOpchain = false,
    filterSpec,
    fiscalKey,
    showDeleteAllBtn = true,
    showCopyRule = false,
    enableCopyRule = true,
    showNewRuleBtn = false,
    opChainMultiSelect = true,
    componentName,
    components,
    changedSavedState,
    keyValueArgs,
    ruleTypes,
    getRowBorderStyle,
    autoResetPage = true,
}) => {
    const [unsavedState, setUnsavedState] = useState(false);
    const [showCSVForm, setShowCSVForm] = useState(false);
    const [saving, setSaving] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [showCopyFrom, setShowCopyFrom] = useState(false);
    const [showCopyFromSettingsStore, setShowCopyFromSettingsStore] =
        useState(false);
    const [showCopyOpchainForm, setShowCopyOpchainForm] = useState(false);
    const [showDeleteAll, setShowDeleteAll] = useState(false);
    const [showCopyRuleModal, setShowCopyRuleModal] = useState(false);
    const [showCreateRuleModal, setShowCreateRuleModal] = useState(false);
    const [fiscalKeyOptions, setFiscalKeyOptions] = useState([]);
    const [operatingChainOptions, setOperatingChainOptions] = useState([]);
    const [showNoDataError, setShowNoDataError] = useState(false);
    const modifyData = (rowIndex, columnName, value) => {
        setCurrentPage(pageIndex);
        setUnsavedState(true);
        onUpdateData && onUpdateData(rowIndex, columnName, value);
    };
    const auth = useAuth();
    const roles = auth.roles;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            onUpdateData: modifyData,
            onNewRow,
            removeRow: onRemoveRow,
            onSaveData,
            sampleCSV,
            rowsCSV,
            initialState: {
                pageIndex: currentPage,
                pageSize: 50,
            },
            autoResetPage,
            autoResetFilters:false
        },
        useFilters,
        useSortBy,
        usePagination
    );
    const { apiFactory } = useDependencies();

    useEffect(
        () => {
            return () => {
                setCurrentPage(0);
            };
        }
    );

    useEffect(() => {
        (async () => {
            const { masterdataApi } = apiFactory;
            const options = await masterdataApi.getBonusPeriods();
            const optionsWithDefault = [
                { label: 'General', value: '' },
                ...options,
            ];
            setFiscalKeyOptions(optionsWithDefault);
        })();
    }, [apiFactory]);

    useEffect(() => {
        if (showNoDataError) {
            toast.error('No data in copied table', {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
            });
            setShowNoDataError(false);
        }
    }, [showNoDataError]);

    useEffect(() => {
        setUnsavedState(changedSavedState);
    }, [changedSavedState]);

    useEffect(() => {
        (async () => {
            const { bonusSettingsApi } = apiFactory;
            const options = await bonusSettingsApi.getOperatingChains();
            const optionsWithLabel = options.map((option) => {
                return {
                    label: option.description,
                    value: option.operatingChain,
                };
            });
            setOperatingChainOptions(optionsWithLabel);
        })();
    }, [apiFactory]);

    const addRow = (evt) => {
        if (pageCount > 1) setCurrentPage(pageCount - 1);

        evt.preventDefault();
        onNewRow && onNewRow();
        setUnsavedState(true);
    };

    const deleteRow = (id) => (evt) => {
        evt.preventDefault();
        onRemoveRow && onRemoveRow(id);
        setUnsavedState(true);
        setCurrentPage(pageIndex);
    };

    const deleteAllRows = () => (evt) => {
        evt.preventDefault();

        if (
            componentName === 'ModelTypes' ||
            componentName === 'NegativeTransactions'
        ) {
            setShowDeleteAll(true);
        } else {
            onRemoveAllRows();
            setUnsavedState(true);
            setCurrentPage(0);
        }
    };

    const deleteSelected = () => {
        onDeleteSelected && onDeleteSelected();
        setUnsavedState(true);
    };

    const save = async (evt) => {
        evt.preventDefault();

        setSaving(true);
        if (unsavedState) {
            if (onSaveData) {
                toast.info('Saving', {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                });
                try {
                    var saveResult = await onSaveData();
                    if (saveResult.valid) {
                        toast.success('Saved', {
                            position: toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true,
                        });
                        setUnsavedState(false);
                    } else if (!saveResult.valid && Array.isArray(saveResult.message)) {
                        saveResult.message.forEach(msg => {
                            toast.error(msg, {position: toast.POSITION.TOP_RIGHT,});
                        });
                    } else {
                        toast.error(saveResult.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                } catch (ex) {
                    toast.error(ex?.response?.data || ex.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
        } else {
            toast.warning('Nothing to save', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setSaving(false);
    };
    const exclamationClasses = `bg-yellow-600 text-gray-200 p-2 px-10 ml-4 rounded transition-opacity`;

    const downloadCSV = (rowsCSV) => {
        const data = rowsCSV.split('\n').map((r) => r.split('\t'));
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Table');
        XLSX.writeFile(workbook, 'table.xlsx');
    };

    const extraActions =
        components && components.extraActions ? (
            components.extraActions
        ) : (
            <div className="space-x-2">
                {addCsvData && (
                    <buttons.AddCsv
                        onAdd={() => setShowCSVForm(true)}
                        text="Add from CSV"
                    />
                )}
                {rowsCSV && (
                    <buttons.SaveAsCSV
                        onSave={(e) => {
                            e.preventDefault();
                            downloadCSV(rowsCSV);
                        }}
                    />
                )}
            </div>
        );

    const allNotChecked =
        rows.findIndex(
            (y) => y.original.selected == null || y.original.selected === false
        ) >= 0;

    const toggleAll = (shouldToggle) => {
        onToggleAll(
            rows.map((r) => r.original.id),
            shouldToggle
        );
    };

    const handleCopy = (fiscalKey, opChain) => {
        const copyData = onCopyFrom(fiscalKey, opChain);
        if (copyData && copyData.length === 0) {
            setShowNoDataError(true);
        } else {
            setUnsavedState(true);
        }
    };
    const handleOpchainCopy = (fromOpChains, toOpChain) => {
        onCopyOpchainFrom(fromOpChains, toOpChain);
        setUnsavedState(true);
    };

    const handleCopySettingsStore = (fiscalKey) => {
        onCopyFrom(fiscalKey);
        setUnsavedState(true);
    };

    const handleRuleCopy = (rules) => {
        onCopyRule(rules);
        setUnsavedState(true);
    };

    const csvModal = () => {
        return (
            <Modal
                isOpen={showCSVForm}
                className="bg-gray-700 min-w-60 rounded-lg max-h-4/5 flex max-w-4/5"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-0 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowCSVForm(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <CSVForm
                    columns={csvColumns || columns.map((c) => c.accessor)}
                    onSave={(data) => {
                        setUnsavedState(true);
                        setShowCSVForm(false);
                        addCsvData(data);
                    }}
                    onClose={() => setShowCSVForm(false)}
                    validateRow={csvRowValidator}
                    sampleCSV={sampleCSV}
                    hasHeader={componentName === 'PayoutSystems'}
                />
            </Modal>
        );
    };

    const copyModal = () => {
        return (
            <Modal
                isOpen={showCopyFrom}
                className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowCopyFrom(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <CopyFrom
                    opChainMultiSelect={opChainMultiSelect}
                    onClose={() => setShowCopyFrom(false)}
                    onCopy={handleCopy}
                    fiscalKeyOptions={fiscalKeyOptions}
                    operatingChainOptions={operatingChainOptions}
                    filterSpec={filterSpec}
                    fiscalKey={fiscalKey}
                    componentName={componentName}
                />
            </Modal>
        );
    };
    const copyOpchainModal = () => {
        return (
            <Modal
                isOpen={showCopyOpchainForm}
                className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowCopyOpchainForm(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <CopyOpchainFrom
                    opChainMultiSelect={opChainMultiSelect}
                    onClose={() => setShowCopyOpchainForm(false)}
                    onCopy={handleOpchainCopy}
                    operatingChainOptions={operatingChainOptions}
                />
            </Modal>
        );
    };

    const copySettingsStoreModal = () => {
        return (
            <Modal
                isOpen={showCopyFromSettingsStore}
                className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowCopyFromSettingsStore(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <CopyFromSettingsStore
                    onClose={() => setShowCopyFromSettingsStore(false)}
                    onCopy={handleCopySettingsStore}
                    fiscalKeyOptions={fiscalKeyOptions}
                    fiscalKey={fiscalKey}
                />
            </Modal>
        );
    };

    const deleteAllModal = () => {
        return (
            <Modal
                isOpen={showDeleteAll}
                className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowDeleteAll(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <DeleteAll
                    onClose={() => setShowDeleteAll(false)}
                    onDeleteAll={() => {
                        onRemoveAllRows();
                        setUnsavedState(true);
                        setCurrentPage(0);
                    }}
                    selectedOpChain={components && components.filter}
                />
            </Modal>
        );
    };

    const createRuleModal = () => (
        <Modal
            isOpen={showCreateRuleModal}
            className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
            overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
            onRequestClose={(e) => {
                e.stopPropagation();
                setShowCreateRuleModal(false);
            }}
            shouldCloseOnOverlayClick={true}
        >
            <CopyRuleForm
                rows={rows}
                onClose={() => setShowCreateRuleModal(false)}
                onCopy={handleRuleCopy}
                operatingChainOptions={operatingChainOptions}
                ruleTypes={ruleTypes}
                keyValueArgs={keyValueArgs}
                fiscalKeyOptions={fiscalKeyOptions}
                newRule
            />
        </Modal>
    );

    const copyRuleModal = () => (
        <Modal
            isOpen={showCopyRuleModal}
            className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
            overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
            onRequestClose={(e) => {
                e.stopPropagation();
                setShowCopyRuleModal(false);
            }}
            shouldCloseOnOverlayClick={true}
        >
            <CopyRuleForm
                rows={rows}
                onClose={() => setShowCopyRuleModal(false)}
                onCopy={handleRuleCopy}
                operatingChainOptions={operatingChainOptions}
                ruleTypes={ruleTypes}
                keyValueArgs={keyValueArgs}
                fiscalKeyOptions={fiscalKeyOptions}
            />
        </Modal>
    );

    return data.length > 0 ||
        componentName === 'EmployeeFactors' ||
        componentName === 'RecurringSales' ? (
        <div
            className="flex flex-1 h-full m-auto flex-col"
            style={{ maxWidth: '100rem' }}
        >
            {
                <div
                    className={
                        'w-full pt-0 pb-0 ' +
                        (unsavedState ? 'visible' : 'invisible')
                    }
                >
                    <div className={exclamationClasses}>
                        <FaIcons.FaExclamation className="h-6 w-6 fill-current inline-block" />
                        <span className="align-botton"> Unsaved data</span>
                    </div>
                </div>
            }
            <div className="flex justify-between px-4 pt-4 pb-4 ">
                {isAuthorized(componentName, roles) && (showCopyFiscal || showCopyFiscalSettingsStore) && (
                    <div
                        className="flex jusify-start items-end space-x-2 space-y-2"
                    >
                        <buttons.Copy
                            onClick={() =>
                                showCopyFiscal
                                    ? setShowCopyFrom(true)
                                    : setShowCopyFromSettingsStore(true)
                            }
                            text="Copy Data From..."
                        />
                    </div>
                )}
                {isAuthorized(componentName, roles) && showCopyOpchain && (
                    <div
                        className="flex jusify-start items-end space-x-2 space-y-2"
                    >
                        <buttons.Copy
                            onClick={() => setShowCopyOpchainForm(true)}
                            text="Copy OpChain from..."
                        />
                    </div>
                )}
                {isAuthorized(componentName, roles) && showCopyRule && (
                    <div
                        className="flex justify-start items-end space-x-2 space-y-2"
                    >
                        <buttons.Copy
                            onClick={() => setShowCopyRuleModal(true)}
                            text="Copy selected rule"
                            enabled={enableCopyRule}
                        />
                    </div>
                )}
                <span className="flex flex-col md:flex-row justify-end items-end flex-wrap text-gray-800 space-x-2 space-y-2 ">
                    {components && components.filter ? (
                        {
                            ...components.filter,
                            props: {
                                ...components.filter.props,
                                setCurrentPage: setCurrentPage,
                            },
                        }
                    ) : (
                        <></>
                    )}
                    {isAuthorized(componentName, roles) && (
                        <>
                            <buttons.Save
                                className="px-2"
                                onSave={save}
                                enabled={!saving}
                            />
                            <buttons.Add onAdd={addRow} text="New Row" />
                            {showNewRuleBtn &&
                                <buttons.Add onAdd={() => setShowCreateRuleModal(true)} text="New Rule" />
                            }
                            {{
                                ...extraActions,
                                props: {
                                    ...extraActions.props,
                                },
                            }}
                            {onDeleteSelected && (
                                <buttons.Delete
                                    onDelete={deleteSelected}
                                    text="Delete Selected"
                                />
                            )}
                        </>
                    )}
                </span>
            </div>
            <div className="flex-1 w-full h-full flex-col overflow-auto px-4">
                <div className="align-middle border-b border-gray-200 flex">
                    <table
                        className="divide-y divide-gray-200 min-w-full table-fixed"
                        {...getTableProps()}
                    >
                        <thead className="">
                            {headerGroups.map((headerGroup) => (
                                // eslint-disable-next-line react/jsx-key
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {onToggleItem && (
                                        <th className="px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                            <input
                                                type="checkbox"
                                                checked={!allNotChecked}
                                                onChange={() =>
                                                    toggleAll(allNotChecked)
                                                }
                                            />
                                        </th>
                                    )}
                                    {headerGroup.headers.map((column) => {
                                        const baseClasses =
                                            'px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider';
                                        const className = column.extraClasses
                                            ? `${baseClasses} ${column.extraClasses}`
                                            : baseClasses;
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <th
                                                className={className}
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps()
                                                )}
                                            >
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                                <div>
                                                    {column.Filter
                                                        ? column.render(
                                                              'Filter'
                                                          )
                                                        : null}
                                                </div>
                                            </th>
                                        );
                                    })}
                                    {
                                        <td
                                            className={
                                                showDeleteAllBtn
                                                    ? 'text-right px-1 bg-gray-50 w-1/12'
                                                    : 'bg-gray-50'
                                            }
                                        >
                                            <buttons.Delete
                                                onDelete={deleteAllRows()}
                                                text="Delete All"
                                                customClass={
                                                    showDeleteAllBtn
                                                        ? 'visible'
                                                        : 'hidden'
                                                }
                                            />
                                        </td>
                                    }
                                </tr>
                            ))}
                        </thead>
                        <tbody
                            className="bg-white divide-y divide-gray-200"
                            {...getTableBodyProps()}
                        >
                            {page.map((row, index) => {
                                prepareRow(row);
                                const rowBorderStyle = getRowBorderStyle
                                    ? getRowBorderStyle(row, index !== 0 ? page[index - 1] : null)
                                    : {};
 
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        key={row.original.id}
                                        style={rowBorderStyle}
                                    >
                                        {onToggleItem && (
                                            <td className="px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        row.original.selected
                                                    }
                                                    onChange={() =>
                                                        onToggleItem(
                                                            row.original.id
                                                        )
                                                    }
                                                />
                                            </td>
                                        )}
                                        {row.cells.map((cell) => {
                                            const renderedCell =
                                                cell.render('Cell');
                                            return (
                                                // eslint-disable-next-line react/jsx-key
                                                <td
                                                    className="whitespace-nowrap text-sm font-medium text-gray-900 min-w-min mx-1"
                                                    {...cell.getCellProps()}
                                                >
                                                    <div className="flex">
                                                        {renderedCell}
                                                        {/* {cell.render("Cell")} */}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                        <td className="px-1 text-right">
                                            <buttons.Delete
                                                onDelete={deleteRow(
                                                    row.original.id
                                                )}
                                                text=""
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className=" text-white flex flex-col justify-center items-center pt-4 pb-4 ml-2 flex-wrap mb-32">
                    <span className="mb-4">
                        <buttons.Button
                            text=""
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                            icon={<FaIcons.FaAngleDoubleLeft />}
                            className="btn-sm-add ml-2"
                            style={{ paddinf: '0.25rem' }}
                        />
                        <buttons.Button
                            text=""
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            icon={<FaIcons.FaAngleLeft />}
                            className="btn-sm-add mx-2"
                        />
                        <span className="text-gray-100 text-lg pl-1">
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <buttons.Button
                            text=""
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            icon={<FaIcons.FaAngleRight />}
                            className="btn-sm-add ml-2"
                        />
                        <buttons.Button
                            text=""
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                            icon={<FaIcons.FaAngleDoubleRight />}
                            className="btn-sm-add mx-2"
                        />
                    </span>
                    <span className="text-gray-100 text-lg pr-2 flex items-end">
                        <span className="ml-2">
                            Go to page:{' '}
                            <input
                                type="number"
                                className="text-black px-2 mx-1"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value
                                        ? Number(e.target.value) - 1
                                        : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>{' '}
                        <Select
                            options={[
                                { value: 50, label: 'Show 50' },
                                { value: 100, label: 'Show 100' },
                            ]}
                            onChange={(e) => {
                                setPageSize(Number(e.value));
                            }}
                            className="w-48 inline-block text-gray-900 "
                            classNamePrefix="select-sm"
                            styles={customSelectDropdown(22)}
                            value={50}
                            placeholder={'Show ' + pageSize}
                        >
                            show
                        </Select>
                    </span>
                </div>
            </div>
            {csvModal()}
            {copyModal()}
            {copySettingsStoreModal()}
            {copyOpchainModal()}
            {deleteAllModal()}
            {createRuleModal()}
            {copyRuleModal()}
        </div>
    ) : (
        <div
            className="flex flex-1 h-full m-auto flex-col"
            style={{ maxWidth: '100rem' }}
        >
            <span className="flex flex-col md:flex-row justify-center items-end flex-wrap text-gray-800 space-x-2 space-y-2 my-4">
                {components &&
                    components.filter && {
                        ...components.filter,
                        props: {
                            ...components.filter.props,
                            setCurrentPage: setCurrentPage,
                        },
                    }}
            </span>
            <div
                className={
                    'w-full pt-0 pb-0 ' +
                    (unsavedState ? 'visible' : 'invisible')
                }
            >
                <div className={exclamationClasses + ' flex justify-between'}>
                    <span>
                        <FaIcons.FaExclamation className="h-6 w-6 fill-current inline-block" />
                        <span className="align-botton"> Unsaved data</span>
                    </span>
                    <buttons.Save
                        className="px-2"
                        onSave={save}
                        enabled={!saving}
                    />
                </div>
            </div>

            <div className="w-full h-full flex justify-center">
                <div className="bg-gray-50 w-4/6 mt-16 text-center h-1/5 flex flex-col justify-center">
                    <h2 className="text-3xl text-gray-900 font-bold">
                        No data in this table
                    </h2>
                    <div className="flex gap-5 justify-center mt-8">
                        <buttons.Add onAdd={addRow} text="Add new row" />

                        {onCopyFrom && (
                            <buttons.Copy
                                onClick={() =>
                                    showCopyFiscal
                                        ? setShowCopyFrom(true)
                                        : setShowCopyFromSettingsStore(true)
                                }
                                text="Copy data from..."
                            />
                        )}

                        {onCopyOpchainFrom && (
                            <buttons.Copy
                                onClick={() => setShowCopyOpchainForm(true)}
                                text="Copy OpChain from..."
                            />
                        )}
                        <buttons.AddCsv
                            onAdd={() => setShowCSVForm(true)}
                            text="Add from CSV"
                        />
                    </div>
                </div>
                {csvModal()}
                {copyModal()}
                {copySettingsStoreModal()}
                {copyOpchainModal()}
                {deleteAllModal()}
                {createRuleModal()}
                {copyRuleModal()}
            </div>
        </div>
    );
};

export default Table;
