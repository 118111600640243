const envObject = {
    dev:{
        "REACT_APP_API_PATH":"/api",
        "REACT_APP_AZURE_AUTHORITY":"https://login.microsoftonline.com/661f8f5f-1e7d-4d4d-a886-1d2661c4ddf8",
        "REACT_APP_AZURE_CLIENT_ID":"5806bd84-2a97-4b49-b83f-6f863a003bac",
        "REACT_APP_AZURE_SCOPE":"https://api.bonusmanager/dev/User.ReadWrite",
    },
    test:{
        "REACT_APP_API_PATH":"/api",
        "REACT_APP_AZURE_AUTHORITY":"https://login.microsoftonline.com/661f8f5f-1e7d-4d4d-a886-1d2661c4ddf8",
        "REACT_APP_AZURE_CLIENT_ID":"704bea8d-18dc-4665-ba18-bec087f28398",
        "REACT_APP_AZURE_SCOPE":"https://api.bonusmanager/test/User.ReadWrite",
    },
    prod:{
        "REACT_APP_API_PATH":"/api",
        "REACT_APP_AZURE_AUTHORITY":"https://login.microsoftonline.com/661f8f5f-1e7d-4d4d-a886-1d2661c4ddf8",
        "REACT_APP_AZURE_CLIENT_ID":"c9c00309-7c06-4241-a348-3e75749071e9",
        "REACT_APP_AZURE_SCOPE":"https://api.bonusmanager/prod/User.ReadWrite",
    },
}

const dev = window.location.origin.includes('dev') || window.location.origin.includes('localhost');

const test = window.location.origin.includes('test');

const hostedEnvSuffixed = (dev && 'dev') || (test && 'test') || 'prod';

const getEnvVar = (variableName) => {
    return envObject[hostedEnvSuffixed][variableName];
}

export { getEnvVar };
