import React, { useState, useEffect, useMemo } from 'react';
import { Table } from '@/components';
import { v4 as uuidv4 } from 'uuid';
import { valueTypes, validators } from '@/utils';
import {
    columnTypes,
    removeRow,
    removeAllRows,
} from '@/components/Table/index';
import {
    saveBonusGeneral,
    prepareTableData,
    prepareTableDataMerge,
    checkRowValue,
    prepareRuleTypes,
} from './common';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';
import { Filter } from './operatingChainFilter';
import Loader from '../../components/Loader';

const createKeyValueArgs = (modelTypes, categories) => {
    return {
        getModelTypes: () => {
            return modelTypes;
        },
        getCategories: () => categories,
    };
};

const createRow =
    (ruleTypes, keyValueArgs, operatingChains) =>
    (individualOperationsEntry) => {
        const row = {
            id: individualOperationsEntry.id,
            altId: individualOperationsEntry.altId,
            key: columnTypes.ruleType.cell(
                individualOperationsEntry.key,
                ruleTypes
            ),
            keyValue: columnTypes.keyValue.cell(
                individualOperationsEntry.keyValue,
                individualOperationsEntry.key,
                keyValueArgs,
                true
            ),
            valueType: columnTypes.valueType.cell(
                individualOperationsEntry.valueType,
                individualOperationsEntry.key,
                individualOperationsEntry.keyValue
            ),
            revGMIndicator: columnTypes.revGMIndicator.cell(
                individualOperationsEntry.revGMIndicator,
                individualOperationsEntry.key,
                individualOperationsEntry.keyValue,
                individualOperationsEntry.valueType
            ),
            operatingChain: columnTypes.operatingChainType.cell(
                `${individualOperationsEntry.operatingChain}`,
                operatingChains
            ),
            value: columnTypes.operatingChainValueTwo.cell(
                individualOperationsEntry.value
            ),
        };
        return row;
    };

export default function OperationsIndividual({ fiscalKey }) {
    const { apiFactory } = useDependencies();
    const { bonusSettingsApi, articleMasterdataApi, bonusRuleApi } = apiFactory;
    const [isLoaded, setIsLoaded] = useState(false);
    const [state, setState] = useState({});
    const [tableData, setTableData] = useState([]);
    const [filterSpec, setFilterSpec] = useState({
        operatingChain: { label: 'All' },
    });

    useEffect(() => {
        if (
            state.allIndividualOperations &&
            state.ruleTypes &&
            state.modelTypeItems &&
            state.categoryItems
        ) {
            const individualOperations = prepareTableData(
                state.allIndividualOperations,
                fiscalKey
            );
            const unsortedIndividualOperations =
                createNewIndividualOperationsObject(individualOperations)
                    .map(
                        createRow(
                            state.ruleTypes,
                            state.keyValueArgs,
                            state.operatingChainOptions
                        )
                    )
                    .filter((obj) => obj.operatingChain.label.length > 0);

            const sortedByRuleValue = unsortedIndividualOperations.sort(
                (a, b) =>
                    b.keyValue.label > a.keyValue.label
                        ? -1
                        : a.keyValue.label > b.keyValue.label
                        ? 1
                        : 0
            );
            const sortedByRuleType = sortedByRuleValue.sort((a, b) =>
                b.key.value > a.key.value
                    ? -1
                    : a.key.value > b.key.value
                    ? 1
                    : 0
            );
            const sortedByOperatingChain = sortedByRuleType.sort((a, b) =>
                b.operatingChain.label[0] && a.operatingChain.label[0]
                    ? b.operatingChain.label[0].label >
                      a.operatingChain.label[0].label
                        ? -1
                        : a.operatingChain.label[0].label >
                          b.operatingChain.label[0].label
                        ? 1
                        : 0
                    : ''
            );

            setTableData(sortedByOperatingChain);
            setIsLoaded(true);
        }
    }, [fiscalKey, state]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const [
                measures,
                categories,
                modelTypes,
                operatingChains,
                allIndividualOperations,
            ] = await Promise.all([
                bonusSettingsApi.getMeasures(),
                articleMasterdataApi.getCategories(),
                articleMasterdataApi.getModelTypes(),
                bonusSettingsApi.getOperatingChains(),
                bonusRuleApi.getIndividualOperations(),
            ]);

            const ruleTypes = prepareRuleTypes(
                measures,
                'individualOperations'
            );
            const categoryItems = categories.map((c) => ({
                ...c,
                value: c.id,
                label: c.description,
            }));
            const modelTypeItems = modelTypes.map((c) => ({
                value: c.id,
                label: c.description,
            }));
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            const keyValueArgs = createKeyValueArgs(
                modelTypeItems,
                categoryItems
            );
            mounted
                ? setState({
                      modelTypeItems,
                      categoryItems,
                      operatingChains,
                      ruleTypes,
                      allIndividualOperations,
                      operatingChainOptions,
                      keyValueArgs,
                  })
                : '';
        }
        getData();
        return () => (mounted = false);
    }, [articleMasterdataApi, bonusRuleApi, bonusSettingsApi]);

    const columns = useMemo(() => {
        const fixedColumns = [
            columnTypes.operatingChainType.column(),
            columnTypes.ruleType.column(),
            columnTypes.keyValue.column(true),
            columnTypes.valueType.column(),
            columnTypes.revGMIndicator.column(),
            columnTypes.operatingChainValueTwo.column(),
        ];
        return [...fixedColumns];
    }, []);

    const rows = useMemo(() => {
        const rows =
            filterSpec &&
            filterSpec.operatingChain &&
            filterSpec.operatingChain.value
                ? tableData.filter(
                      (el) =>
                          filterSpec.operatingChain.value ===
                          el.operatingChain.value
                  )
                : tableData;

        return rows;
    }, [filterSpec, tableData]);

    const createNewIndividualOperationsObject = (tableData) => {
        const newIndividualOperations = [];
        tableData.forEach((individualOperation) => {
            individualOperation.id = uuidv4();
            individualOperation.operatingChains.forEach((el, index) => {
                if (checkRowValue(el.value)) {
                    newIndividualOperations.push({
                        id: individualOperation.id + '#' + index,
                        altId: individualOperation.id,
                        key: individualOperation.key,
                        operatingChain: el.operatingChain,
                        value: el.value,
                        valueType: individualOperation.valueType,
                        keyValue: individualOperation.keyValue,
                        revGMIndicator: individualOperation.revGMIndicator,
                    });
                }
            });
        });
        return newIndividualOperations;
    };

    const newRow = () => {
        const opChainValue =
            filterSpec &&
            filterSpec.operatingChain &&
            filterSpec.operatingChain.value;
        const individualOperation = {
            id: uuidv4(),
            valueType: valueTypes.percentage,
            key: '',
            operatingChain: opChainValue || '',
            value: 0,
        };
        individualOperation.altId = individualOperation.id;
        const row = createRow(
            state.ruleTypes,
            state.keyValueArgs,
            state.operatingChainOptions
        )(individualOperation);
        const newTableData = [...tableData, row];
        setTableData(newTableData);
    };

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row.id = uuidv4();
        row.altId = row.id;
        row[columnName] = { ...row[columnName], ...{ value } };
        if (columnName === 'value') {
            row[columnName] = parseFloat(value).toFixed(2);
        }
        if (columnName === 'key') {
            row['keyValue'] = columnTypes.keyValue.cell(
                '',
                value,
                state.keyValueArgs
            );
            row['valueType'] = columnTypes.valueType.cell('', value);
        }
        if (columnName === 'keyValue') {
            const keyCell = row['key'];
            row['valueType'] = columnTypes.valueType.cell(
                '',
                keyCell.value,
                value
            );
        }
        if (
            ['key', 'keyValue', 'valueType', 'revGMIndicator'].indexOf(
                columnName
            ) > -1
        ) {
            const keyValue = columnName === 'key' ? value : row['key'].value;
            const keyValueValue =
                columnName === 'keyValue' ? value : row['keyValue'].value;
            const valueTypeValue =
                columnName === 'valueType' ? value : row['valueType'].value;
            const revGMIndicatorValue =
                columnName === 'revGMIndicator'
                    ? value
                    : row['revGMIndicator'].value;
            row['revGMIndicator'] = columnTypes.revGMIndicator.cell(
                revGMIndicatorValue,
                keyValue,
                keyValueValue,
                valueTypeValue
            );
        }
        setTableData(newTableData);
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData, filterSpec.operatingChain));
    };

    const hasSameValues = (valueOne, valueTwo) => {
        return (
            valueOne.key === valueTwo.key.value &&
            valueOne.keyValue === valueTwo.keyValue.value &&
            valueOne.valueType.value === valueTwo.valueType.value &&
            valueOne.revGMIndicator === valueTwo.revGMIndicator.value
        );
    };

    const saveData = async () => {
        const formattedIndividualOperations = [];
        const filteredTable = tableData.filter(
            (obj) => obj.operatingChain.value && obj.value
        );

        filteredTable.forEach((individualOperation) => {
            if (
                !formattedIndividualOperations.some((e) =>
                    hasSameValues(e, individualOperation)
                ) ||
                formattedIndividualOperations.length === 0
            ) {
                formattedIndividualOperations.push({
                    id: individualOperation.altId,
                    operatingChains: [
                        {
                            operatingChain:
                                individualOperation.operatingChain.value.split(
                                    '_'
                                )[0],
                            value: individualOperation.value,
                        },
                    ],
                    key: individualOperation.key.value,
                    valueType: individualOperation.valueType,
                    keyValue: individualOperation.keyValue.value,
                    revGMIndicator: individualOperation.revGMIndicator.value,
                });
            } else {
                formattedIndividualOperations.forEach((el) => {
                    if (hasSameValues(el, individualOperation)) {
                        el.operatingChains.push({
                            operatingChain:
                                individualOperation.operatingChain.value.split(
                                    '_'
                                )[0],
                            value: individualOperation.value,
                        });
                    }
                });
            }
        });
        await saveBonusGeneral(bonusRuleApi.saveIndividualOperations)(
            formattedIndividualOperations,
            fiscalKey,
            simulationIdState.get()[0].simulationId
        );
        const savedData = await bonusRuleApi.getIndividualOperations(true);
        setState({ ...state, allIndividualOperations: savedData });
        return {
            valid: true,
        };
    };

    const sampleCSV = () => `OCNOELK\tREV_EX_ORD\t\tPERCENTAGE\t\t20
OCNOELK\tGM_EX_ORD\t\tPERCENTAGE\t\t80
OCNOELK\tMODELTYPECODE\tACC\tPERCENTAGE\tREV\t75
OCNOELK\tKITCHEN\t\tPERCENTAGE\t\t70
OCNOELK\tMODELTYPECODE\tFOO\tPERCENTAGE\t\t30
OCNOELK\tOUTLET\t<key value>\tPERCENTAGE\tREV\t40
OCNOELK\tB2B\t<key value>\tPERCENTAGE\tREV\t5
OCNOELK\tKITCHEN\t<key value>\tPERCENTAGE\tREV\t10
    `;

    // Convert table data to string
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) =>
                    `${row.operatingChain.value}\t${row.key.value}\t${
                        !row.keyValue.isDisabled ? row.keyValue.value : ''
                    }\t${row.valueType.value}\t${
                        row.revGMIndicator.isVisible
                            ? row.revGMIndicator.value
                            : ''
                    }\t${row.value}`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    const {
        optionValidator,
        composeValidators,
        validateIfRowAlreadyExist,
        returnNullOrValue,
    } = validators;
    const csvRowValidator = (currentData, ruleTypes) => async (row) => {
        const validators = [
            optionValidator('Invalid rule type', ruleTypes, 1),
            validateIfRowAlreadyExist(currentData),
        ];
        const validator = composeValidators(validators);
        return await validator(row);
    };

    const checkIfRowAlreadyExist = (uploadedRow, existingRow) => {
        return (
            uploadedRow.key === existingRow.key.value &&
            uploadedRow.operatingChain === existingRow.operatingChain.value &&
            returnNullOrValue(uploadedRow.keyValue) ===
                returnNullOrValue(existingRow.keyValue.value) &&
            returnNullOrValue(uploadedRow.revGMIndicator) ===
                returnNullOrValue(existingRow.revGMIndicator.value) &&
            uploadedRow.valueType === existingRow.valueType.value
        );
    };

    const addCsvData = (data) => {
        const createItem = (row) => {
            const item = {
                id: uuidv4(),
                key: row.items[1].toUpperCase(),
                keyValue: row.items[2].toUpperCase(),
                valueType: row.items[3].toUpperCase(),
                revGMIndicator: row.items[4].toUpperCase(),
                operatingChain: row.items[0],
                value: row.items[5].replace(',', '.'),
            };
            item.altId = item.id;
            item.id = item.id + '#' + 1;
            return item;
        };
        const input = data.data.map(createItem);
        const existingRows = tableData.map((tr) => {
            const updatedRow = input.find((i) => checkIfRowAlreadyExist(i, tr));
            if (updatedRow) {
                tr.value = updatedRow.value;
            }
            return tr;
        });
        const newRows = input
            .filter(
                (r) =>
                    existingRows.findIndex((ud) =>
                        checkIfRowAlreadyExist(r, ud)
                    ) < 0
            )
            .map(
                createRow(
                    state.ruleTypes,
                    state.keyValueArgs,
                    state.operatingChainOptions
                )
            );
        setTableData([...existingRows, ...newRows]);
    };

    const copyFiscalKey = (fiscalKeyData, operatingChainData) => {
        const chosenOpChains = operatingChainData.map((oc) => oc.value);

        const newChosenObjects = createNewIndividualOperationsObject(
            prepareTableData(state.allIndividualOperations, fiscalKey)
        ); // Table we are copying INTO
        const newCopiedObjects = createNewIndividualOperationsObject(
            prepareTableData(state.allIndividualOperations, fiscalKeyData.value)
        ); // Data we are copying
        if (!chosenOpChains[0]) {
            state.operatingChainOptions.forEach((opChain) =>
                chosenOpChains.push(opChain.value)
            );
        }
        const copiedObjectsFilteredByOC = newCopiedObjects.filter((el) =>
            chosenOpChains.includes(el.operatingChain)
        );

        const chosenPeriodRows = newChosenObjects.map(
            createRow(
                state.ruleTypes,
                state.keyValueArgs,
                state.operatingChainOptions
            )
        );
        const copiedPeriodRows = copiedObjectsFilteredByOC.map(
            createRow(
                state.ruleTypes,
                state.keyValueArgs,
                state.operatingChainOptions
            )
        );
        setTableData(prepareTableDataMerge(chosenPeriodRows, copiedPeriodRows));
    };

    return isLoaded ? (
        <Table
            data={rows}
            columns={columns}
            onUpdateData={updateData}
            onRemoveRow={deleteRow}
            onRemoveAllRows={deleteAllRows}
            onNewRow={newRow}
            onSaveData={saveData}
            components={{
                filter: (
                    <Filter
                        filter={filterSpec}
                        onChange={setFilterSpec}
                        operatingChainOptions={state.operatingChainOptions}
                    />
                ),
            }}
            sampleCSV={sampleCSV}
            rowsCSV={rowsCSV}
            csvRowValidator={csvRowValidator(tableData, state.ruleTypes)}
            csvColumns={[
                'Operating Chain',
                'Rule Type',
                'Rule value',
                'PERCENTAGE/FIXED',
                'REV/GM',
                'Value',
            ]}
            addCsvData={addCsvData}
            copyFiscalKey={copyFiscalKey}
            showCopyFiscal={true}
            onCopyFrom={copyFiscalKey}
            filterSpec={filterSpec}
            fiscalKey={fiscalKey}
        />
    ) : (
        <div className="w-full text-center">
            <Loader />
        </div>
    );
}
