/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect } from 'react';
import Modal from 'react-modal';
import CreatableSelect from 'react-select/creatable';
import { v4 as uuidv4 } from 'uuid';
import { Table, buttons, Loader } from '@/components';
import {
    columnTypes,
    removeRow,
    removeAllRows,
} from '@/components/Table/index';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';
import { updateRowGeneral } from '@/pages/bonusRuleGeneral/common';
import OperatingChainsDeleteModal from '@/pages/settings/OperatingChainsDeleteModal';

const columnSpecs = {
    operatingChain: columnTypes.text('Operating chain', 'operatingChain'),
    description: columnTypes.input(
        'Operating chain description',
        'description'
    ),
    realOrFake: columnTypes.realOrFake(),
    bonusDeduction: columnTypes.decimal(
        'Bonus Deduction',
        'bonusDeduction',
        0.01,
        2
    ),
};

const createRow = (dataRow) => {
    const row = {
        id: dataRow.id,
        operatingChain: columnSpecs.operatingChain.cell(dataRow.operatingChain),
        description: columnSpecs.description.cell(dataRow.description),
        isFake: columnSpecs.realOrFake.cell(dataRow.isFake),
        bonusDeduction: columnSpecs.bonusDeduction.cell(dataRow.bonusDeduction),
    };
    return row;
};

const OperatingChains = () => {
    const { apiFactory } = useDependencies();
    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasData, setHasData] = useState(undefined);
    const [operatingChains, setOperatingChains] = useState([]);
    const [showAddRowModal, setShowAddRowModal] = useState(false);
    const [deletedRows, setDeletedRows] = useState([]);
    const [operatingChainOptions, setOperatingChainOptions] = useState([]);
    const [deletingStores, setDeletingStores] = useState([]);
    const [deletingOpchain, setDeletingOpchain] = useState();
    const [showDeleteOpchainModal, setShowDeleteOpchainModal] = useState(false);
    const { bonusSettingsApi, masterdataApi } = apiFactory;
    const columns = useMemo(() => {
        const fixedColumns = [
            columnSpecs.operatingChain.column(),
            columnSpecs.description.column(),
            columnSpecs.realOrFake.column(),
            columnSpecs.bonusDeduction.column(),
        ];
        return fixedColumns;
    }, []);

    useEffect(() => {
        async function getData() {
            const [operatingChains, operatingChainOptionsData] =
                await Promise.all([
                    bonusSettingsApi.getOperatingChains(),
                    masterdataApi.getOperatingChains(),
                ]);
            setOperatingChainOptions(
                operatingChainOptionsData.map((op) => ({
                    label: op.description,
                    value: op.operatingChain,
                }))
            );
            setOperatingChains(operatingChains);
            setHasData((operatingChains && operatingChains.length > 0) ? true : false);
        }
        getData();
    }, [bonusSettingsApi, masterdataApi]);

    useEffect(() => {
        const tableData = operatingChains.map((d) => createRow(d));
        setTableData(tableData);
    }, [operatingChains]);

    useEffect(() => {
        if ((hasData && tableData.length > 0) || hasData === false) {
            setIsLoaded(true);
        }
    }, [hasData, tableData]);

    const saveData = async () => {
        const requestData = tableData.map((row) => ({
            description: columnSpecs.description.value(row),
            bonusDeduction: columnSpecs.bonusDeduction.value(row),
            isFake: columnSpecs.realOrFake.value(row),
            operatingChain: columnSpecs.operatingChain.value(row),
            simulationId: simulationIdState.get()[0].simulationId,
        }));
        if (deletedRows.length !== 0) {
            const removeIds = deletedRows.map((row) => {
                return {
                    opchainName: row.operatingChain.value,
                    simulationId: simulationIdState.get()[0].simulationId,
                };
            });
            await bonusSettingsApi.deleteOperatingChains({ data: removeIds });
        }
        await bonusSettingsApi.saveOperatingChains(requestData);
        setDeletedRows([]);
        const updatedOperatingChains =
            await bonusSettingsApi.getOperatingChains(true);
        setOperatingChains(updatedOperatingChains);
        return {
            valid: true,
        };
    };
    const deleteRow = async (id) => {
        const storeSettings = await bonusSettingsApi.getStores();

        const chosenRow = tableData.find((y) => y.id === id);
        const storesWithOpchain = storeSettings.filter((period) => {
            return period.data.some(
                (obj) => obj.bonusOpChain === chosenRow.operatingChain.value
            );
        });
        setDeletingStores(storesWithOpchain);
        setDeletingOpchain(chosenRow.operatingChain.value);
        if (storesWithOpchain.length > 0) {
            setShowDeleteOpchainModal(true);
        } else {
            setDeletedRows([
                ...deletedRows,
                tableData.find((y) => y.id === id),
            ]);
            setTableData(removeRow(id, tableData));
        }
    };

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData));
    };

    const updateData = (id, columnName, value) =>
        setTableData(updateRowGeneral(id, columnName, value, tableData));

    const showAddRow = () => setShowAddRowModal(true);
    const closeAddRow = () => setShowAddRowModal(false);
    const addRow = (data) => {
        const newTableData = [
            ...tableData,
            createRow({ ...data, operatingChain: data.operatingChain.value }),
        ];
        setTableData(newTableData);
        closeAddRow();
    };

    const rows = useMemo(() => {
        return [...tableData];
    }, [tableData]);

    return (
        <div className="flex w-full h-full flex-col">
            <div className="content w-full flex-1 flex flex-row">
                {isLoaded ? (
                    <>
                        <Table
                            data={rows}
                            onNewRow={showAddRow}
                            onUpdateData={updateData}
                            columns={columns}
                            onSaveData={saveData}
                            onRemoveRow={deleteRow}
                            onRemoveAllRows={deleteAllRows}
                            componentName="OperatingChains"
                        />
                        <Modal
                            isOpen={showAddRowModal}
                            className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
                            overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                            onRequestClose={(e) => {
                                e.stopPropagation();
                                setShowAddRowModal(false);
                            }}
                            shouldCloseOnOverlayClick={true}
                        >
                            <AddRowForm
                                onClose={closeAddRow}
                                onSave={addRow}
                                selectedOperatingChains={operatingChains}
                                operatingChainOptions={operatingChainOptions}
                            />
                        </Modal>
                        <Modal
                            isOpen={showDeleteOpchainModal}
                            className="w-3/12 h-auto rounded-lg"
                            overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                            onRequestClose={(e) => {
                                e.stopPropagation();
                                setShowDeleteOpchainModal(false);
                            }}
                            shouldCloseOnOverlayClick={true}
                        >
                            <OperatingChainsDeleteModal
                                deletingStores={deletingStores}
                                opchain={deletingOpchain}
                                onClose={() => setShowDeleteOpchainModal(false)}
                            />
                        </Modal>
                    </>
                ) : (
                    <div className="w-full text-center">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
};

const AddRowForm = ({
    onSave,
    onClose,
    selectedOperatingChains,
    operatingChainOptions,
}) => {
    const [state, setState] = useState({
        id: uuidv4(),
        isFake: 0,
        bonusDeduction: 0.0,
    });
    const selectedOpChainValues = selectedOperatingChains.map(
        (op) => op.operatingChain
    );
    const opChainOptions = operatingChainOptions.filter(
        (op) => selectedOpChainValues.indexOf(op.value) < 0
    );

    const handleChange = (name) => (value) => {
        setState({ ...state, [name]: value });
    };

    const handleInputChange = (e) => {
        handleChange(e.target.name)(e.target.value);
    };
    const selectStyles = {
        menu: (provided, state) => ({
            ...provided,
            width: state.selectProps.width,
        }),
    };

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <form className="flex flex-col space-y-2 w-full">
                <div className="w-full">
                    <label className="text-gray-50">Operating chain</label>
                    <CreatableSelect
                        isClearable
                        options={opChainOptions}
                        styles={selectStyles}
                        onChange={handleChange('operatingChain')}
                        handleInputChange={handleInputChange}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Description</label>
                    <input
                        name="description"
                        className="px-2 w-full rounded-md h-9 "
                        onBlur={handleInputChange}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Real or fake</label>
                    <div>
                        <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                <div className="flex items-center pl-3">
                                    <input
                                        id="horizontal-list-radio-real"
                                        type="radio"
                                        value="Real"
                                        checked={state.isFake === 0}
                                        name="list-radio"
                                        onChange={() =>
                                            setState({
                                                ...state,
                                                isFake: 0,
                                            })
                                        }
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                    />
                                    <label
                                        htmlFor="horizontal-list-radio-real"
                                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Real{' '}
                                    </label>
                                </div>
                            </li>
                            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                <div className="flex items-center pl-3">
                                    <input
                                        id="horizontal-list-radio-fake"
                                        type="radio"
                                        value="Fake"
                                        name="list-radio"
                                        onChange={() =>
                                            setState({
                                                ...state,
                                                isFake: 1,
                                            })
                                        }
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                    />
                                    <label
                                        htmlFor="horizontal-list-radio-fake"
                                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Fake
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Division factor</label>
                    <input
                        name="bonusDeduction"
                        type="number"
                        step=".01"
                        className="px-2 w-full rounded-md h-9 "
                        onBlur={handleInputChange}
                    />
                </div>
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Add
                        disabled={state.operatingChain ? false : true}
                        onAdd={(e) => {
                            e.preventDefault();
                            onSave(state);
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

export default OperatingChains;
