import React, { useState, useMemo } from 'react';
import {
    competitionColumnTypes,
    toggleRow,
    toggleAllRows,
} from '../../components/DisplayTable/index';
import DisplayTable from '../../components/DisplayTable';

import { toast } from 'react-toastify';
import { buttons } from '@/components';
import { useDependencies } from '@/DependencyProvider';
import { isAuthorized, pages } from '@/utils';
import { useAuth } from '@/auth';

const CompetitionList = ({
    competitionList,
    openCompetitionDetailsModal,
    updateCompetitionList,
    setPageLoading,
    showCreateCompetition,
}) => {
    const [data, setData] = useState([]);
    const auth = useAuth();
    const roles = auth.roles;
    const { apiFactory } = useDependencies();

    const formatCustomPeriod = (item) => {
        const startDate = `${item.startDate
            .toString()
            .slice(6)}.${item.startDate.toString().slice(4, 6)}.${item.startDate
            .toString()
            .slice(2, 4)}`;
        const endDate = `${item.endDate.toString().slice(6)}.${item.endDate
            .toString()
            .slice(4, 6)}.${item.endDate.toString().slice(2, 4)}`;
        return startDate + ' - ' + endDate;
    };

    useMemo(() => {
        const updatedData = competitionList.map((item) => {
            return {
                ...item,
                system: item.isAdmin ? 'Bonus Manager' : 'Blueberry',
                period: item.period ? item.period : formatCustomPeriod(item),
            };
        });
        setData(updatedData);
    }, [competitionList]);

    const authorized = isAuthorized(pages.COMPETITIONPAGE, roles);

    const standardBtnClass =
        'w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';

    const columns = useMemo(() => {
        const showDetailsButton = (row) => {
            return (
                <buttons.Button
                    className={standardBtnClass}
                    text="Competition details"
                    onClick={() => {
                        openCompetitionDetailsModal(row);
                    }}
                />
            );
        };

        const deleteCompetitionButton = (row) => {
            return (
                <buttons.Delete
                    // enabled={!isDeleting}
                    text=""
                    customClass="flex justify-center"
                    onDelete={() => deleteRow(row)}
                />
            );
        };

        const fixedColumns = [
            competitionColumnTypes.competitionName.column(),
            competitionColumnTypes.competitionPeriod.column(),
            competitionColumnTypes.operatingChain.column(),
            competitionColumnTypes.mainParameter.column(),
            competitionColumnTypes.numOfDisplayParameters.column(),
            competitionColumnTypes.participants.column(),
            competitionColumnTypes.numOfInvites.column(),
            competitionColumnTypes.numOfAccepted.column(),
            competitionColumnTypes.createdIn.column(),
            competitionColumnTypes.createdAt.column(),
            competitionColumnTypes.buttonColumn.column(
                'show-details-button-column',
                showDetailsButton,
                authorized
            ),
            competitionColumnTypes.buttonColumn.column(
                'delte-button-column',
                deleteCompetitionButton,
                true
            ),
        ];
        return [...fixedColumns];
    }, [authorized, openCompetitionDetailsModal]);

    const deleteRow = async (row) => {
        deleteCompetitions(row.original.id);
    };

    const deleteCompetitions = async (id) => {
        setPageLoading(true);
        const { competitionsApi } = apiFactory;

        try {
            const deleteResult = await competitionsApi.deleteCompetition({
                competitionId: id,
            });
            if (deleteResult.status !== 200) {
                toast.error(deleteResult.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (ex) {
            toast.error(ex?.response?.data || ex.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        updateCompetitionList();
        setPageLoading(false);
    };

    const toggleItem = (id) => {
        setData(toggleRow(id, data));
    };

    const toggleAll = (ids, shouldToggle) => {
        setData(toggleAllRows(ids, shouldToggle, data));
    };

    const removeSelected = () => {
        data.forEach(async (obj) => {
            if (obj.selected) {
                await deleteCompetitions(obj.id);
            }
        });
    };

    return (
        <DisplayTable
            columns={columns}
            tableData={data}
            competition={true}
            onToggleItem={toggleItem}
            onRemoveSelected={removeSelected}
            onToggleAll={toggleAll}
            isAuthorized={authorized}
            showCreateCompetition={showCreateCompetition}
        />
    );
};

export default CompetitionList;
