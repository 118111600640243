import { memoizedMakeReq } from './apiCommon';

const masterdataApi = (axios) => ({
    getBonusPeriods: async (args) => {
        const currentDate = new Date();
        const twelveMonthsFromNow = new Date(
            currentDate.setMonth(currentDate.getMonth() + 12)
        );
        const maxTime = twelveMonthsFromNow.getTime();
        const { includeEmpty } = { includeEmpty: false, ...args };
        const periods = await memoizedMakeReq(
            axios,
            'masterdata/bonus-periods'
        )();
        const optionMap = periods
            .filter((y) => new Date(y.startDate).getTime() < maxTime)
            .sort((x, y) => y.periodId - x.periodId)
            .reduce((acc, period) => {
                if (!acc[period.bonusYear]) {
                    acc[period.bonusYear] = [];
                }
                acc[period.bonusYear].push({
                    label: period.periodId,
                    value: period.periodId,
                    period: period,
                });
                return acc;
            }, {});
        const options = Object.keys(optionMap)
            .sort((x, y) => y - x)
            .map((key) => ({
                label: key,
                options: optionMap[key],
            }));
        return includeEmpty
            ? [
                  { label: '', options: [{ value: '', label: 'General' }] },
                  ...options,
              ]
            : options;
    },
    getEmployees: memoizedMakeReq(axios, 'masterdata/employees'),
    getStores: memoizedMakeReq(axios, 'masterdata/stores'),
    getOperatingChains: memoizedMakeReq(axios, 'masterdata/operating-chains'),
});

export default masterdataApi;
