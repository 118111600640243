/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect } from 'react';
import { Table, Loader } from '@/components';
import {
    columnTypes,
    removeRow,
    filters,
    removeAllRows,
} from '@/components/Table/index';
import { v4 as uuidv4 } from 'uuid';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';
import { updateRowGeneral } from '@/pages/bonusRuleGeneral/common';
import { validators } from '@/utils';

const columnSpecs = {
    operatingChain: columnTypes.select('Operating chain', 'operatingChain'),
    modelType: columnTypes.select('Model type', 'modelType'),
    modelCodes: columnTypes.multiSelect('CGMs', 'modelCodes'),
};

const createRow = (
    dataRow,
    modelCodeOptions,
    modelTypeOptions,
    operatingChainOptions
) => {
    const modelCodesCell = columnSpecs.modelCodes.cell(
        dataRow.modelCodes.map((mc) => ({ value: mc.modelCode })),
        modelCodeOptions,
        true
    );
    const row = {
        id: dataRow.id,
        operatingChain: columnSpecs.operatingChain.cell(
            dataRow.operatingChain,
            operatingChainOptions
        ),
        modelType: columnSpecs.modelType.cell(
            dataRow.modelType,
            modelTypeOptions
        ),
        modelCodes: modelCodesCell,
    };
    return row;
};

const Filter = (props) => {
    return (
        <>
            <filters.OperatingChainFilter {...props} />
        </>
    );
};

const ModelTypes = () => {
    const { apiFactory } = useDependencies();
    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasData, setHasData] = useState(undefined);
    const [modelCodeOptions, setModelCodeOptions] = useState([]);
    const [modelTypeOptions, setModelTypeOptions] = useState([]);
    const [operatingChainOptions, setOperatingChainOptions] = useState([]);
    const [modelTypeSettings, setModelTypeSettings] = useState([]);
    const [filterSpec, setFilterSpec] = useState({ operatingChain: null });
    const { bonusSettingsApi, articleMasterdataApi } = apiFactory;

    useEffect(() => {
        async function getData() {
            const [modelTypeSettings, modelCodes, modelTypes, operatingChains] =
                await Promise.all([
                    bonusSettingsApi.getModelTypes(),
                    articleMasterdataApi.getModels(),
                    articleMasterdataApi.getModelTypes(),
                    bonusSettingsApi.getOperatingChains(),
                ]);
            const modelCodeOptions = modelCodes.map((mc) => ({
                label: `${mc.id} - ${mc.description}`,
                value: mc.id,
            }));
            const modelTypeOptions = modelTypes.map((mt) => ({
                label: `${mt.id} - ${mt.description}`,
                value: mt.id,
            }));
            const operatingChainOptions = operatingChains.map((op) => ({
                label: `${op.operatingChain} - ${op.description}`,
                value: op.operatingChain,
            }));
            setModelCodeOptions(modelCodeOptions);
            setOperatingChainOptions(operatingChainOptions);
            setModelTypeOptions(modelTypeOptions);
            setModelTypeSettings(modelTypeSettings.data);
            setHasData((modelTypeSettings.data && modelTypeSettings.data > 0) ? true : false);
        }
        getData();
    }, [articleMasterdataApi, bonusSettingsApi]);

    useEffect(() => {
        if (modelTypeSettings) {
            const tableData = modelTypeSettings
                .map((d) => {
                    return createRow(
                        d,
                        modelCodeOptions,
                        modelTypeOptions,
                        operatingChainOptions
                    );
                })
                .filter((obj) =>
                    operatingChainOptions.find(
                        (o) => o.value === obj.operatingChain.value
                    )
                );
            setTableData(tableData);
        }
    }, [
        modelCodeOptions,
        modelTypeOptions,
        modelTypeSettings,
        operatingChainOptions,
    ]);

    useEffect(() => {
        if ((hasData && tableData.length > 0) || hasData === false) {
            setIsLoaded(true);
        }
    }, [hasData, tableData]);

    const columns = useMemo(() => {
        const fixedColumns = [
            columnSpecs.operatingChain.column(),
            columnSpecs.modelType.column(),
            columnSpecs.modelCodes.column(),
        ];
        return fixedColumns;
    }, []);

    const rows = useMemo(() => {
        const filter = filters.composeFilters([filters.operatingChain]);
        const filtered = tableData.filter(filter(filterSpec));
        return [...filtered];
    }, [tableData, filterSpec]);

    const saveData = async () => {
        const data = tableData.map((row) => ({
            id: row.id,
            modelCodes: columnSpecs.modelCodes
                .value(row)
                .map((item) => ({ modelCode: item.value })),
            modelType: columnSpecs.modelType.value(row),
            operatingChain: columnSpecs.operatingChain.value(row),
        }));
        const requestData = {
            data: data,
            simulationId: simulationIdState.get()[0].simulationId,
        };
        await bonusSettingsApi.saveModelTypes(requestData);
        const updatedModelTypeSettings = await bonusSettingsApi.getModelTypes(
            true
        );
        setModelTypeSettings(updatedModelTypeSettings.data);
        return {
            valid: true,
        };
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData, filterSpec.operatingChain));
    };

    const updateData = (id, columnName, value) => {
        const adjustedValue =
            columnName === 'modelCodes'
                ? value.map((v) => ({ value: v }))
                : value;
        setTableData(
            updateRowGeneral(id, columnName, adjustedValue, tableData)
        );
    };

    const addRow = () => {
        const newRow = {
            id: uuidv4(),
            modelCodes: [],
            modelType: '',
            operatingChain: '',
        };
        const newTableData = [
            ...tableData,
            createRow(
                newRow,
                modelCodeOptions,
                modelTypeOptions,
                operatingChainOptions
            ),
        ];
        setTableData(newTableData);
    };

    const sampleCSV = () => {
        return `OCNOELK\tCON\t54512
OCNOELK\tCON\t54520
OCSEELG\tCON\t54512`;
    };

    // Convert table data to string
    const makeRowsCSV = (rows) => {
        if (rows.length > 0) {
            let rowStrings = [];
            rows.forEach((row) => {
                const rowPerModelCode = row.modelCodes.value.map(
                    (modelCode) => {
                        return `${row.operatingChain.value}\t${row.modelType.value}\t${modelCode.value}`;
                    }
                );
                rowStrings = rowStrings.concat(rowPerModelCode);
            });
            return rowStrings.join('\n');
        }
    };

    const rowsCSV = makeRowsCSV(rows);

    const operatingChainModelTypeValidator = (currentData) => (row) => {
        const operatingChain = row[0];
        const modelType = row[1];
        const exists =
            currentData.findIndex(
                (d) =>
                    d.operatingChain.value === operatingChain &&
                    d.modelType.value === modelType
            ) >= 0;
        return {
            isValid: true,
            text: exists ? 'Exists - will update' : '',
        };
    };

    const { optionValidator, composeValidators } = validators;
    const csvRowValidator =
        (currentData, operatingChains, modelTypes, modelCodes) =>
        async (row) => {
            const validators = [
                optionValidator('Invalid operating chain', operatingChains, 0),
                optionValidator('Invalid model type', modelTypes, 1),
                optionValidator('Invalid model code', modelCodes, 2),
                operatingChainModelTypeValidator(currentData),
            ];
            const validator = composeValidators(validators);
            return await validator(row);
        };

    const addCsvData = (data) => {
        const structured = data.data.map((row) => ({
            operatingChain: row.items[0],
            modelType: row.items[1],
            modelCode: row.items[2],
        }));
        const groupedData = structured.reduce((acc, curr) => {
            const key = `${curr.operatingChain}-${curr.modelType}`;
            const currentModelCodes = acc[key] ? acc[key].modelCodes : [];
            return {
                ...acc,
                [key]: {
                    ...curr,
                    id: uuidv4(),
                    modelCodes: [
                        ...currentModelCodes,
                        { modelCode: curr.modelCode },
                    ],
                },
            };
        }, {});
        const rows = Object.values(groupedData).map((row) =>
            createRow(
                row,
                modelCodeOptions,
                modelTypeOptions,
                operatingChainOptions
            )
        );
        const updatedData = tableData.map((row) => {
            const updatedRow = rows.find(
                (newRow) =>
                    newRow.operatingChain.value === row.operatingChain.value &&
                    newRow.modelType.value === row.modelType.value
            );
            return updatedRow ?? row;
        });
        const newRows = rows.filter((row) =>
            tableData.findIndex(
                (y) =>
                    y.operatingChain.value === row.operatingChain.value &&
                    y.modelType.value === row.modelType.value
            )
        );
        const newTableData = [...updatedData, ...newRows];
        setTableData(newTableData);
    };

    const copyOpchains = (fromOpChains, toOpChain) => {
        var manipulatedData = tableData.reduce(function (filtered, row) {
            if (
                fromOpChains.find((op) => op.value === row.operatingChain.value)
            ) {
                filtered.push({
                    id: uuidv4(),
                    modelCodes: row.modelCodes.value.map((mc) => ({
                        modelCode: mc.value,
                    })),
                    modelType: row.modelType.value,
                    operatingChain: toOpChain.value,
                });
            }
            return filtered;
        }, []);
        const manipulatedTableData = manipulatedData.map((d) => {
            return createRow(
                d,
                modelCodeOptions,
                modelTypeOptions,
                operatingChainOptions
            );
        });
        const newTableData = [...tableData, ...manipulatedTableData];
        setTableData(newTableData);
    };

    return (
        <div className="flex w-full h-full flex-col">
            <div className="content w-full flex-1 flex flex-row">
                {isLoaded ? (
                    <>
                        <Table
                            data={rows}
                            onNewRow={addRow}
                            onUpdateData={updateData}
                            columns={columns}
                            onSaveData={saveData}
                            onRemoveRow={deleteRow}
                            onRemoveAllRows={deleteAllRows}
                            onCopyOpchainFrom={copyOpchains}
                            showCopyOpchain={true}
                            componentName={'ModelTypes'}
                            components={{
                                filter: (
                                    <Filter
                                        filter={filterSpec}
                                        onChange={setFilterSpec}
                                        operatingChainOptions={
                                            operatingChainOptions
                                        }
                                    />
                                ),
                            }}
                            csvColumns={[
                                'Operating chain',
                                'Model type',
                                'Model code',
                            ]}
                            sampleCSV={sampleCSV}
                            csvRowValidator={csvRowValidator(
                                tableData,
                                operatingChainOptions,
                                modelTypeOptions,
                                modelCodeOptions
                            )}
                            addCsvData={addCsvData}
                            rowsCSV={rowsCSV}
                        />
                    </>
                ) : (
                    <div className="w-full text-center">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModelTypes;
