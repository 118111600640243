import React from 'react';
import {
    SelectCell,
    InputCell,
    DateCell,
    TextCell,
    NumberCell,
    ToggleCell,
    MultiSelectCell,
    DecimalCell,
    NumberCellSorting,
} from '@/components';
import { ruleMetricOptions, staffTypeOptions, rewardTypeOptions, valueTypes } from '@/utils';
import './reactTableCustomStyles.css';

const inputFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <input
            className="custom-react-table-input"
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder="Search..."
        />
    );
};

const CustomFilter = (rows, property, filterValue) => {
    const val =
        property === 'key' ||
        property.includes('key') ||
        property.includes('keyValue')
            ? 'label'
            : 'value';
    return rows.filter(function (el) {
        if (
            el.values[property][val] &&
            el.values[property][val]
                .toUpperCase()
                .includes(filterValue.toUpperCase())
        ) {
            return el;
        }
        if (
            el.values[property].value &&
            el.values[property].value
                .toUpperCase()
                .includes(filterValue.toUpperCase())
        ) {
            return el;
        }
    });
};

const ruleType = {
    column: (accessor = 'key') => ({
        Header: 'Rule Type',
        accessor: accessor,
        extraClasses: 'min-w-36',
        Cell: SelectCell,
        Filter: inputFilter,
        filter: CustomFilter,
    }),
    cell: (value, ruleTypes) => {
        const labelArray = ruleTypes.filter((el) => {
            if (!el.value || !value) {
                return;
            }
            if (el.value.toUpperCase() === value.toUpperCase()) {
                return el;
            }
        });
        return {
            value: value,
            options: () => ruleTypes,
            label: labelArray.length > 0 ? labelArray[0].label : '',
        };
    },
    value: (row, accessor = 'key') => (row[accessor] ? row[accessor] : null),
};

const keyValue = {
    getOptions: (optionsAlternatives, key) => {
        const lookupMap = {
            MODELTYPECODE: optionsAlternatives.getModelTypes,
            CATEGORYCODE: optionsAlternatives.getCategories,
            ARTICLECODE: optionsAlternatives.getArticles,
            MODELCODE: optionsAlternatives.getModels,
            BRANDCODE: optionsAlternatives.getBrands,
            GROUPCODE: optionsAlternatives.getGroups,
        };
        const keyValueOptions = lookupMap[key] || (() => []);
        return keyValueOptions;
    },
    column: (addLabel = false, accessor = 'keyValue') => ({
        Header: 'Rule Value',
        accessor: accessor,
        extraClasses: 'min-w-52',
        Cell: SelectCell,
        Filter: addLabel ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (keyValueValue, key = undefined, keyValueArgs, addLabel = false) => {
        const optionLookup = {
            ...{
                getModelTypes: () => [],
                getCategories: () => [],
                getArticles: () => [],
                getModels: () => [],
                getBrands: () => [],
                getGroups: () => [],
            },
            ...keyValueArgs,
        };

        const keyValueOptions = keyValue.getOptions(optionLookup, key);
        const isAsync =
            ['ARTICLECODE', 'MODELCODE', 'BRANDCODE', 'GROUPCODE'].indexOf(
                key
            ) > -1;
        let labelArray;
        if (addLabel) {
            labelArray = keyValueOptions().filter((el) => {
                if (!el.value || !keyValueValue) {
                    return;
                }
                if (el.value.toUpperCase() === keyValueValue.toUpperCase()) {
                    return el;
                }
            });
        } else {
            labelArray = [];
        }
        return {
            value: keyValueValue,
            options: keyValueOptions,
            isVisible: isAsync || keyValueOptions().length > 0,
            isAsync: isAsync,
            key: key,
            label: labelArray.length > 0 ? labelArray[0].label : '',
        };
    },
    value: (row, accessor = 'keyValue') => (row[accessor] ? row[accessor] : null),
};

const valueType = {
    column: (addFilter = false) => ({
        Header: 'Percentage/Fixed',
        accessor: 'valueType',
        extraClasses: 'min-w-32',
        Cell: SelectCell,
        Filter: addFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value, key = undefined, keyValue = undefined) => {
        const enabled =
            keyValue || ['OUTLET', 'B2B', 'KITCHEN'].indexOf(key) > -1;
        let actualValue = value ? value : valueTypes.options[0].value;
        key === 'CUSTOMER_RECRUITMENT' ? (actualValue = 'FIXED') : '';
        return {
            value: actualValue,
            isDisabled: !enabled,
            options: () => valueTypes.options,
        };
    },
    value: (row) => {
        if (row.valueType && !row.valueType.value) {
            return row.valueType;
        } else if (row.valueType && row.valueType.value) {
            return row.valueType.value;
        } else {
            return null;
        }
    },
};

const operatingChain = {
    column: () => ({
        Header: 'Operating Chain',
        accessor: 'operatingChain',
        extraClasses: 'min-w-36',
        Cell: SelectCell,
    }),
    cell: (value, operatingChainOptions) => {
        return {
            value: value,
            options: () => operatingChainOptions,
            label: operatingChainOptions.filter((el) => el.value === value),
        };
    },
    value: (row) => (row.operatingChain ? row.operatingChain : null),
};

const operatingChainType = {
    column: () => ({
        Header: 'Operating Chain',
        accessor: 'operatingChain',
        extraClasses: 'min-w-36',
        Cell: SelectCell,
    }),
    cell: (value, operatingChainOptions) => {
        return {
            value: value,
            options: () => operatingChainOptions,
            label: operatingChainOptions.filter((el) => el.value === value),
        };
    },
    value: (row, accessor = 'key') => (row[accessor] && row[accessor].value ? row[accessor].value : 'null'),
};

const staffType = {
    column: (accessor = 'staff') => ({
        Header: 'Staff Type',
        accessor: accessor,
        extraClasses: 'min-w-32',
        Cell: SelectCell,
        Filter: inputFilter,
        filter: CustomFilter,
    }),
    cell: (value) => ({
        value: value,
        options: () => staffTypeOptions,
    }),
    value: (row) => (row.key && row.key.value ? row.key.value : 'null'),
};

const serviceType = {
    column: (accessor) => ({
        Header: 'Service Type',
        // accessor: "serviceTypeValue",
        accessor: accessor,
        extraClasses: 'min-w-40',
        Cell: SelectCell,
    }),
    cell: (value, options) => ({
        value: value,
        options: () => options,
    }),
    value: (row) => (row.key && row.key.value ? row.key.value : 'null'),
};

const pointType = {
    column: () => ({
        Header: 'Point Type',
        accessor: 'pointType',
        extraClasses: 'min-w-40',
        Cell: SelectCell,
    }),
    cell: (value, pointTypes) => ({
        value: value,
        options: () => pointTypes,
    }),
    value: (row) => (row.value.value ? row.value.value : 'null'),
};

const homeTechCodeValue = {
    column: (addFilter = false) => ({
        Header: 'Department',
        accessor: 'homeTechCode',
        extraClasses: 'min-w-32',
        Cell: SelectCell,
        Filter: addFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value) => ({
        value: value,
        options: () => [
            { label: 'HOME', value: 'HOME' },
            { label: 'TECH', value: 'TECH' },
            { label: 'OTHER', value: 'OTHER' },
        ],
    }),
    value: (row) => (row.key && row.key.value ? row.key.value : 'null'),
};

const operatingChainValueTwo = {
    column: () => ({
        Header: 'Value',
        accessor: 'value',
        extraClasses: 'w-28',
        Cell: NumberCellSorting,
    }),
    cell: (value) => value,
    value: (row) => {
        return row.key && row.key.value ? row.key.value : 'null';
    },
};

const revGMIndicator = {
    revGMOptions: [
        { label: 'Rev', value: 'REV' },
        { label: 'GM', value: 'GM' },
    ],
    column: (addFilter = true) => ({
        Header: 'Rev/GM',
        accessor: 'revGMIndicator',
        extraClasses: 'min-w-36',
        Cell: SelectCell,
        Filter: addFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value, key, keyValue, valueType) => {
        const keyWithRevGM = ['OUTLET', 'B2B', 'KITCHEN'];
        const isVisible =
            valueType === valueTypes.percentage &&
            (!!keyValue || keyWithRevGM.indexOf(key) > -1);
        const actualValue = value
            ? value
            : revGMIndicator.revGMOptions[0].value;
        return {
            value: isVisible ? actualValue : undefined,
            isVisible: isVisible,
            options: () => revGMIndicator.revGMOptions,
        };
    },
    value: (row) => (row.revGMIndicator ? row.revGMIndicator : null),
};

const ruleMetric = {
    ruleMetricOptions,
    column: (addFilter = true) => ({
        Header: 'Rule Metric',
        accessor: 'ruleMetric',
        extraClasses: 'min-w-36',
        Cell: SelectCell,
        Filter: addFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value) => (
        {
            value,
            options: () => ruleMetric.ruleMetricOptions,
        }
    ),
    value: (row) => (row.ruleMetric ? row.ruleMetric : null),
};

const rewardType = {
    rewardTypeOptions,
    column: (addFilter = true) => ({
        Header: 'Reward type',
        accessor: 'rewardType',
        extraClasses: 'min-w-36',
        Cell: SelectCell,
        Filter: addFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value) => (
        {
            value,
            options: () => rewardType.rewardTypeOptions,
        }
    ),
    value: (row) => (row.rewardType ? row.rewardType : null),
};

const operatingChainValue = {
    column: (description, accessor, operatingChain, homeTechCode) => ({
        Header: description,
        accessor: accessor,
        operatingChain: operatingChain,
        homeTechCode: homeTechCode,
        Cell: NumberCell,
    }),
    cell: (value) => {
        return {
            ...value,
        };
    },
    value: (row, operatingChain) => {
        const [actualOperatingChain, actualValue, homeTechCode] = row[
            operatingChain
        ].value
            ? [
                  row[operatingChain] || operatingChain,
                  row[operatingChain].value,
                  row[operatingChain].homeTechCode,
              ]
            : [operatingChain.split('_')[0], 0, operatingChain.split('_')[1]];

        const value = {
            operatingChain: actualOperatingChain,
            value: parseFloat(actualValue),
            homeTechCode: homeTechCode,
        };
        return value;
    },
};

const dateCell = (description, accessor) => ({
    column: (addFilter = false) => ({
        Header: description,
        accessor: accessor,
        extraClasses: 'w-40',
        Cell: DateCell,
        Filter: addFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value) => {
        return {
            value: value,
        };
    },
    value: (row) => (row[accessor] ? row[accessor] : null),
});

const startDate = dateCell('Start date', 'startDate');
const endDate = dateCell('End date', 'endDate');

const inputCell = (description, accessor, isDisabled) => ({
    column: (addFilter = false) => ({
        Header: description,
        accessor: accessor,
        Cell: InputCell,
        extraClasses: accessor === 'comment' ? 'min-w-56' : '',
        Filter: addFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value) => ({
        value: value,
        isDisabled: isDisabled,
    }),
    value: (row) =>
        row[accessor] && row[accessor].value ? row[accessor].value : null,
});

const numberCell = (description, accessor, isDisabled) => ({
    column: () => ({
        Header: description,
        accessor: accessor,
        extraClasses: accessor === 'paymentMonth' ? 'w-20' : '',
        Cell: NumberCell,
        disableSortBy: true,
    }),
    cell: (value) => ({
        value: value ? value.toString().replace(',', '.') : value,
        isDisabled: isDisabled,
    }),
    value: (row) =>
        row[accessor] && row[accessor].value ? row[accessor].value : 0,
});

const textCell = (description, accessor, hasFilter = false) => ({
    column: () => ({
        Header: description,
        accessor: accessor,
        Cell: TextCell,
        Filter: hasFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value) => ({
        value: value,
    }),
    value: (row) =>
        row[accessor] && row[accessor].value ? row[accessor].value : '',
});

const textCellStoreId = (description, accessor, hasFilter = false) => ({
    column: () => ({
        Header: description,
        accessor: accessor,
        Cell: TextCell,
        Filter: hasFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value) => ({
        value: value,
    }),
    value: (row) =>
        row[accessor] && row[accessor].value !== ''
            ? row[accessor].value
            : null,
});

const realOrFake = () => ({
    column: () => ({
        Header: 'Real/Fake',
        accessor: 'isFake',
        Cell: TextCell,
    }),
    cell: (value) => ({
        value: value === 1 ? 'Fake' : 'Real',
    }),
    value: (row) => (row.isFake && row.isFake.value === 'Real' ? 0 : 1),
});

const payoutColumn = {
    column: (thresholdDefinition) => ({
        Header: `${thresholdDefinition.lowerBound}% - ${thresholdDefinition.upperBoundNotEqual}%`,
        accessor: thresholdDefinition.id,
        definition: thresholdDefinition,
        Cell: NumberCell,
        disableSortBy: true,
    }),
    cell: (value) => ({
        value: value.toString().replace(',', '.'),
    }),
    value: (row, thresholdId) =>
        row[thresholdId] ? row[thresholdId].value : 0,
};

const pointsLowerBound = numberCell('Points lower bound', 'pointsFrom');
const pointsUpperBound = numberCell(
    'Points upper bound (not equal)',
    'pointsTo'
);

const employeeId = textCell('Employee id', 'employeeId', true);
const username = textCell('Username', 'username', true);
const name = textCell('Name', 'name', true);
const storeId = textCellStoreId('Store id', 'storeId', true);
const factor = numberCell('Factor Points', 'factor');
const fiscalKeyFrom = inputCell('Period from', 'fiscalKeyFrom');
const fiscalKeyTo = inputCell('Period to', 'fiscalKeyTo');
const fiscalKey = inputCell('Period', 'fiscalKey');
const comment = inputCell('Comment', 'comment');
const points = numberCell('Points', 'points');
const payment = numberCell('Payment Month', 'month');
const limit = numberCell('Limit', 'limit');
const rewardValue = numberCell('Reward Value', 'rewardValue');

const operatingChainForStore = textCell('Operating chain', 'operatingChain');
const ownOrFranchise = textCell('Own store / Franchise', 'ownOrFranchise');
const storeName = textCell('Store name', 'storeName');
const target = numberCell('Target', 'target');
const vsLastYear = numberCell('VS Last Year', 'vsLastYear');
const employeePoints = numberCell('Value', 'value');

const text = (description, accessor, hasFilter = false) => ({
    column: () => ({
        Header: description,
        accessor: accessor,
        Cell: TextCell,
        Filter: hasFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value) => ({
        value: value,
    }),
    value: (row) =>
        row[accessor] && row[accessor].value ? row[accessor].value : null,
});

const toggle = (description, accessor) => ({
    column: () => ({
        Header: description,
        accessor: accessor,
        Cell: ToggleCell,
    }),
    cell: (value) => ({
        value: value,
    }),
    value: (row) =>
        row[accessor] && row[accessor].value ? row[accessor].value : false,
});

const decimal = (
    description,
    accessor,
    stepValue = 1,
    numberOfDecimals = 1
) => ({
    column: () => ({
        Header: description,
        accessor: accessor,
        Cell: DecimalCell,
    }),
    cell: (value) => ({
        value: value,
        stepValue,
        numberOfDecimals,
    }),
    value: (row) =>
        row[accessor] && row[accessor].value ? row[accessor].value : 0,
});

const select = (description, accessor, hasFilter = false) => ({
    column: () => ({
        Header: description,
        accessor: accessor,
        extraClasses: 'min-w-36',
        Cell: SelectCell,
        Filter: hasFilter ? inputFilter : '',
        filter: CustomFilter,
    }),
    cell: (value, options, enableCopyValues = false) => ({
        value: value,
        options: () => options,
        enableCopyValues: enableCopyValues,
    }),
    value: (row) => row[accessor].value,
});

const multiSelect = (description, accessor) => ({
    column: () => ({
        Header: description,
        accessor: accessor,
        containerClasses: 'max-w-5xl',
        Cell: MultiSelectCell,
    }),
    cell: (value, options, enableCopyValues = false) => ({
        value: value,
        options: options,
        enableCopyValues: enableCopyValues,
    }),
    value: (row) => row[accessor].value,
});

const input = inputCell;

export {
    inputFilter,
    ruleType,
    valueType,
    keyValue,
    revGMIndicator,
    ruleMetric,
    limit,
    rewardType,
    rewardValue,
    operatingChainValue,
    startDate,
    endDate,
    employeeId,
    username,
    name,
    factor,
    fiscalKeyFrom,
    fiscalKeyTo,
    fiscalKey,
    comment,
    storeId,
    points,
    pointsLowerBound,
    pointsUpperBound,
    payoutColumn,
    storeName,
    ownOrFranchise,
    target,
    vsLastYear,
    operatingChain,
    operatingChainForStore,
    operatingChainType,
    operatingChainValueTwo,
    homeTechCodeValue,
    payment,
    staffType,
    serviceType,
    pointType,
    employeePoints,
    realOrFake,
    text,
    input,
    toggle,
    decimal,
    select,
    multiSelect,
};
