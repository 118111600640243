import {
    memoizedMakeReqwithId,
    memoizedMakeReq,
    postRequest,
} from './apiCommon';

const getOperatingChainsWithSimId = (axios) => async (value) =>
    await memoizedMakeReq(axios, 'settings/operating-chains?simId=' + value)();

// prettier-ignore
const settingsApi = (axios) => ({
    getMeasures: memoizedMakeReqwithId(axios, 'settings/measures'),
    getOperatingChains: memoizedMakeReqwithId(axios, 'settings/operating-chains'),
    getOperatingChainsWithSimId: getOperatingChainsWithSimId(axios, 'settings/operating-chains'),
    saveOperatingChains: postRequest(axios, 'settings/operating-chains'),
    deleteOperatingChains: postRequest(axios, 'settings/delete-operating-chains'),
    getStores: memoizedMakeReqwithId(axios, 'settings/stores'),
    saveStores: postRequest(axios, 'settings/stores'),
    getModelTypes: memoizedMakeReqwithId(axios, 'settings/model-types'),
    saveModelTypes: postRequest(axios, 'settings/model-types'),
    getNegativeTransactions: memoizedMakeReqwithId(axios, 'settings/negative-transaction-allowances'),
    saveNegativeTransactions: postRequest(axios, 'settings/negative-transaction-allowances'),
});

export default settingsApi;
