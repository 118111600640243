/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { Table, buttons, MultiSelect, Loader } from '@/components';
import {
    columnTypes,
    removeRow,
    removeAllRows,
} from '@/components/Table/index';
import { validators } from '@/utils';

import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';
import {
    createNewRowsObjectSettingsStore,
    prepareTableData,
    prepareTableDataMerge,
    updateRowGeneral,
} from '@/pages/bonusRuleGeneral/common';

const columnSpecs = {
    storeId: columnTypes.text('Store id', 'storeId', true),
    storeName: columnTypes.text('Store name', 'storeName', true),
    operatingChain: columnTypes.select(
        'Operating chain',
        'operatingChain',
        true
    ),
    ownOrFranchise: columnTypes.text('Own store/Franchise', 'ownOrFranchise'),
};

const createRow = (dataRow, storeOptions, operatingChainOptions) => {
    const storeOption = storeOptions.find((so) => so.value == dataRow.storeId);

    const opChain = dataRow.bonusOpChain
        ? dataRow.bonusOpChain
        : storeOption.operatingChain;
    const row = {
        id: uuidv4(),
        storeId: columnSpecs.storeId.cell(dataRow.storeId),
        storeName: columnSpecs.storeName.cell(storeOption.label),
        operatingChain: columnSpecs.operatingChain.cell(
            opChain,
            operatingChainOptions
        ),
        ownOrFranchise: columnSpecs.ownOrFranchise.cell(
            storeOption.ownOrFranchise
        ),
    };
    return row;
};
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Stores = () => {
    const { apiFactory } = useDependencies();
    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasData, setHasData] = useState(undefined);
    const [showAddRowModal, setShowAddRowModal] = useState(false);
    const [storeOptions, setStoreOptions] = useState([]);
    const [storeSettings, setStoreSettings] = useState([]);
    const [operatingChainOptions, setOperatingChainOptions] = useState([]);
    const [periodOptions, setPeriodOptions] = useState([]);
    const { bonusSettingsApi, masterdataApi } = apiFactory;
    const columns = useMemo(() => {
        const fixedColumns = [
            columnSpecs.storeId.column(),
            columnSpecs.storeName.column(),
            columnSpecs.operatingChain.column(),
            columnSpecs.ownOrFranchise.column(),
        ];
        return fixedColumns;
    }, []);
    const query = useQuery();
    const navigate = useNavigate();

    const [queryParams, setQueryParams] = useState({
        fiscalKey: query.get('fiscalKey') ?? '',
    });

    useEffect(() => {
        async function getData() {
            const [storeSettings, storeMasterData, periods, operatingChains] =
                await Promise.all([
                    bonusSettingsApi.getStores(),
                    masterdataApi.getStores(),
                    masterdataApi.getBonusPeriods({ includeEmpty: true }),
                    bonusSettingsApi.getOperatingChains(),
                ]);
            const storeOptions = storeMasterData.map((s) => ({
                label: s.departmentName,
                value: s.storeId,
                operatingChain: s.operatingChain,
                ownOrFranchise: s.isFranchise ? 'FRANCHISE' : 'OWN',
            }));
            const operatingChainOptions = operatingChains.map((op) => ({
                label: `${op.operatingChain} - ${op.description}`,
                value: op.operatingChain,
            }));

            setOperatingChainOptions(operatingChainOptions);
            setStoreOptions(storeOptions);
            setStoreSettings(storeSettings);
            setPeriodOptions(periods);
            setHasData((storeSettings && storeSettings.length > 0) ? true : false);
        }
        getData();
    }, [bonusSettingsApi, masterdataApi]);

    useEffect(() => {
        if (storeSettings) {
            const found = storeSettings.find(
                (el) => el.fiscalKey === queryParams.fiscalKey
            );
            let tableData = [];
            if (found) {
                tableData = found.data.map((d) => {
                    return createRow(d, storeOptions, operatingChainOptions);
                });
            }
            setTableData(tableData);
        }
    }, [storeOptions, storeSettings, operatingChainOptions, queryParams]);

    useEffect(() => {
        if ((hasData && tableData.length > 0) || hasData === false) {
            setIsLoaded(true);
        }
    }, [hasData, tableData]);

    const updateQuery = (queryParams) => {
        setQueryParams(queryParams);
        navigate({
            search: createQueryString(queryParams),
        });
    };

    const createQueryString = ({ fiscalKey }) => {
        return `?fiscalKey=${fiscalKey || ''}`;
    };

    const saveData = async () => {
        const data = tableData.map((row) => ({
            bonusOpChain: columnSpecs.operatingChain.value(row),
            storeId: columnSpecs.storeId.value(row),
        }));
        const requestData = {
            data,
            simulationId: simulationIdState.get()[0].simulationId,
            fiscalKey: queryParams.fiscalKey,
        };
        await bonusSettingsApi.saveStores(requestData);
        const updateStoreSettings = await bonusSettingsApi.getStores(true);
        setStoreSettings(updateStoreSettings);
        return {
            valid: true,
        };
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData));
    };

    const updateData = (id, columnName, value) =>
        setTableData(updateRowGeneral(id, columnName, value, tableData));

    const showAddRow = () => setShowAddRowModal(true);
    const closeAddRow = () => setShowAddRowModal(false);
    const addRow = (data) => {
        const newRows = data.stores.map((s) =>
            createRow(
                {
                    storeId: s.value,
                    bonusOpChain: data.opChain.value /* active: data.active  */,
                },
                storeOptions,
                operatingChainOptions
            )
        );
        const newTableData = [...tableData, ...newRows];
        setTableData(newTableData);
        closeAddRow();
    };

    const rows = useMemo(() => {
        return [...tableData];
    }, [tableData]);

    const periodChanged = (selectedValue) => {
        updateQuery({ ...queryParams, fiscalKey: selectedValue.value });
    };

    const getFiscalKeyOption = (fiscalKeyParam, options) => {
        if (options) {
            return (
                options
                    .flatMap((o) => o.options)
                    .find((fk) => fk.value == fiscalKeyParam) ?? { value: '' }
            );
        }
        return { value: '' };
    };

    const sampleCSV = () => `2001\tOCDKELG
2036\tOCNOELK
    `;

    const addCsvData = (data) => {
        const newRows = data.data.map((row) => {
            return createRow(
                {
                    storeId: row.items[0],
                    bonusOpChain: row.items[1] /* active: data.active  */,
                },
                storeOptions,
                operatingChainOptions
            );
        });

        const newTableData = [...tableData, ...newRows];
        setTableData(newTableData);
    };

    const { composeValidators } = validators;
    const csvRowValidator = () => async (row) => {
        const validators = [checkStore(row), checkOpChain(row)];

        const validator = composeValidators(validators);
        return await validator(row);
    };

    const checkStore = () => async (row) => {
        const found = storeOptions.find((element) => element.value === row[0]);
        return {
            isValid: found ? true : false,
            text: !found && "Store doesn't exist",
        };
    };
    const checkOpChain = () => async (row) => {
        const found = operatingChainOptions.find(
            (element) => element.value === row[1]
        );
        return {
            isValid: found ? true : false,
            text: !found && "Operating chain doesn't exist",
        };
    };

    const copyFiscalKey = (fiscalKeyData) => {
        const newChosenObjects = createNewRowsObjectSettingsStore(
            prepareTableData(storeSettings, queryParams.fiscalKey)
        ); // Table we are copying INTO
        const newCopiedObjects = createNewRowsObjectSettingsStore(
            prepareTableData(storeSettings, fiscalKeyData.value)
        ); // Data we are copying

        const chosenPeriodRows = newChosenObjects.map(
            (row) => createRow(row, storeOptions, operatingChainOptions)
            // createRow = (dataRow, storeOptions, operatingChainOptions)
        );
        const copiedPeriodRows = newCopiedObjects.map((row) =>
            createRow(row, storeOptions, operatingChainOptions)
        );

        setTableData(prepareTableDataMerge(chosenPeriodRows, copiedPeriodRows));
    };

    return (
        <div className="flex w-full h-full flex-col">
            <div className="pt-8 w-full flex flex-col gap-4 justify-center items-center">
                <div>
                    <label className="font-bold">Period: </label>
                    <Select
                        options={periodOptions}
                        onChange={periodChanged}
                        className="w-48 inline-block text-gray-900"
                        value={getFiscalKeyOption(
                            queryParams.fiscalKey,
                            periodOptions
                        )}
                    />
                </div>
            </div>
            <div className="content w-full flex-1 flex flex-row">
                {isLoaded ? (
                    <>
                        <Table
                            data={rows}
                            onNewRow={showAddRow}
                            onUpdateData={updateData}
                            columns={columns}
                            onSaveData={saveData}
                            onRemoveRow={deleteRow}
                            onRemoveAllRows={deleteAllRows}
                            addCsvData={addCsvData}
                            csvRowValidator={csvRowValidator(tableData)}
                            csvColumns={['Store ID', 'Operating Chain']}
                            sampleCSV={sampleCSV}
                            showCopyFiscalSettingsStore={true}
                            onCopyFrom={copyFiscalKey}
                            fiscalKey={
                                queryParams.fiscalKey
                                    ? queryParams.fiscalKey
                                    : ''
                            }
                        />
                        <Modal
                            isOpen={showAddRowModal}
                            className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
                            overlayClassName="flex justify-center items-center fixed top-0 right-0 left-0 bottom-0 bg-gray-900 bg-opacity-80"
                            onRequestClose={(e) => {
                                e.stopPropagation();
                                setShowAddRowModal(false);
                            }}
                            shouldCloseOnOverlayClick={true}
                        >
                            <AddRowForm
                                onClose={closeAddRow}
                                onSave={addRow}
                                storeSettings={storeSettings}
                                storeOptions={storeOptions}
                                opChainOptions={operatingChainOptions}
                            />
                        </Modal>
                    </>
                ) : (
                    <div className="w-full text-center">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
};

const AddRowForm = ({
    onSave,
    onClose,
    storeSettings,
    storeOptions,
    opChainOptions,
}) => {
    const [state, setState] = useState({
        id: uuidv4(),
        stores: [],
        opChain: '',
    });

    let storeSettingsIds;
    let filteredStoreOptions;
    if (storeSettings) {
        storeSettingsIds = storeSettings.map((ss) => ss.storeId);
        filteredStoreOptions = storeOptions.filter(
            (so) => storeSettingsIds.indexOf(so.value) < 0
        );
    }

    const handleChange = (name) => (value) => {
        setState({ ...state, [name]: value });
    };

    const formatLabel = (props) => {
        const { value, label } = props;
        return `${label} (${value})`;
    };

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <form className="flex flex-col space-y-2 w-full">
                <div className="w-full">
                    <label className="text-gray-50">Stores to add</label>
                    <MultiSelect
                        onChange={handleChange('stores')}
                        options={
                            filteredStoreOptions
                                ? filteredStoreOptions
                                : storeOptions
                        }
                        formatOptionLabel={formatLabel}
                        allowSelectAll={true}
                        value={state.stores}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Operating Chain</label>
                    <Select
                        onChange={handleChange('opChain')}
                        options={opChainOptions}
                        formatOptionLabel={formatLabel}
                        value={state.opChain}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Add
                        onAdd={(e) => {
                            e.preventDefault();
                            onSave(state);
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

export default Stores;
