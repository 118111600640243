import React, { useState, useMemo, useContext } from 'react';

export const RenderContext = React.createContext();

export function useRender() {
    return useContext(RenderContext);
}

export function RenderProvider({ children }) {
    const [value, setValue] = useState(0);
    return (
        <RenderContext.Provider
            value={useMemo(() => ({ value, setValue }), [value, setValue])}
        >
            {children}
        </RenderContext.Provider>
    );
}
