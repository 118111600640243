import React from 'react';
import * as FaIcons from 'react-icons/fa';

export const Button = ({ className, onClick, disabled, text, icon }) => {
    return (
        <button
            className={
                disabled
                    ? 'opacity-50 ' + className + ' cursor-not-allowed	'
                    : className
            }
            onClick={onClick}
            disabled={disabled}
        >
            {icon && {
                ...icon,
                props: { className: 'h-6 w-6 fill-current inline-block' },
            }}
            <span className="align-bottom"> {text}</span>
        </button>
    );
};

export const Save = ({ onSave, text = 'Save', enabled = true }) => {
    return (
        <button
            className={
                !enabled ? 'btn-save opacity-50 cursor-not-allowed' : 'btn-save'
            }
            onClick={onSave}
            disabled={!enabled}
        >
            <FaIcons.FaSave className="h-6 w-6 fill-current inline-block" />
            <span className="align-bottom"> {text}</span>
        </button>
    );
};

export const Delete = ({
    onDelete,
    text = 'Delete',
    customClass = '',
    enabled = true,
}) => {
    return (
        <span className={customClass}>
            <button
                className={'btn-delete'}
                onClick={onDelete}
                disabled={!enabled}
            >
                <FaIcons.FaRegTrashAlt className="h-6 w-6 fill-current inline-block" />
                <span className="align-bottom"> {text}</span>
            </button>
        </span>
    );
};

export const Add = ({ onAdd, text = 'Add', disabled = false }) => {
    const Icon = (
        <FaIcons.FaPlus className="h-6 w-6 fill-current inline-block" />
    );
    return (
        <Button
            className="btn-add"
            onClick={onAdd}
            text={text}
            disabled={disabled}
            icon={Icon}
        />
    );
};

export const AddCsv = ({ onAdd, text = 'Add csv', display = true }) => {
    return (
        <button
            className={`${display ? '' : 'hidden'} btn-add inline-block`}
            onClick={onAdd}
        >
            <FaIcons.FaFileCsv className="h-6 w-6 fill-current inline-block" />
            <span className="align-bottom"> {text}</span>
        </button>
    );
};

export const SaveAsCSV = ({ onSave, text = 'Save as CSV' }) => {
    return (
        <button className="btn-add inline-block" onClick={onSave}>
            <FaIcons.FaFileDownload className="h-6 w-6 fill-current inline-block" />
            <span className="align-bottom"> {text}</span>
        </button>
    );
};

export const AddColumn = ({ onAdd, text = 'Add column' }) => {
    return (
        <button className="btn-add" onClick={onAdd}>
            <FaIcons.FaColumns className="h-6 w-6 fill-current inline-block" />
            <span className="align-bottom"> {text}</span>
        </button>
    );
};

export const Cancel = ({ onCancel, text = 'Cancel' }) => {
    return (
        <button className="btn-cancel" onClick={onCancel}>
            <FaIcons.FaHistory className="h-6 w-6 fill-current inline-block" />
            <span className="align-bottom"> {text}</span>
        </button>
    );
};

export const Copy = ({ onClick, text = 'Cancel', enabled = true, type = undefined }) => {
    return (
        <button
            className={
                !enabled ? 'btn-copy opacity-50 cursor-not-allowed' : 'btn-copy'
            }
            onClick={onClick}
            disabled={!enabled}
            type={type}
        >
            <FaIcons.FaCopy className="h-6 w-6 fill-current inline-block" />
            <span className="align-bottom"> {text}</span>
        </button>
    );
};

export const Primary = ({ onClick, text, icon, customClass }) => {
    return (
        <button
            className={customClass + ' btn-primary inline-block'}
            onClick={onClick}
        >
            {selectIcon(icon)}
            <span className="align-bottom"> {text}</span>
        </button>
    );
};

const selectIcon = (value) => {
    switch (value) {
        case 'download':
            return (
                <FaIcons.FaFileDownload className="h-6 w-6 fill-current inline-block" />
            );
        default:
            return;
    }
};

export const Validate = ({ onClick, text = 'Validate' }) => {
    const Icon = (
        <FaIcons.FaQuestion className="h-6 w-6 fill-current inline-block" />
    );
    return (
        <Button
            className="btn-add"
            onClick={onClick}
            text={text}
            disabled={false}
            icon={Icon}
        />
    );
};
