import { DateTime } from 'luxon';
import React from 'react';
import * as FaIcons from 'react-icons/fa';
import ContentCard from './ContentCard';
import { buttons } from '../../components/index';

const iconStyle = 'text-gray-500 h-10 w-10 m-0';

const CompetitionDetails = ({
    state,
    downloadParticipantsCSV,
    downloadInvitesCSV,
}) => {
    const formatDate = (dateInt) => {
        const dateStr = dateInt.toString();
        return `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(
            6
        )}`;
    };

    let period;

    if (state.period) {
        period = state.period;
    } else {
        period = `${formatDate(state.startDate)} - ${formatDate(
            state.endDate
        )}`;
    }

    const compDetails = [
        {
            header: 'Competition Name',
            content: state.name,
            icon: <FaIcons.FaInfoCircle className={iconStyle} />,
        },
        {
            header: 'Created By',
            content: state.createdBy,
            icon: <FaIcons.FaUser className={iconStyle} />,
        },
        {
            header: 'Operating Chain',
            content: state.opchain,
            icon: <FaIcons.FaStoreAlt className={iconStyle} />,
        },
        {
            header: 'Created At',
            content: DateTime.fromISO(state.createdAt).toFormat('ff'),
            icon: <FaIcons.FaCalendarAlt className={iconStyle} />,
        },
        {
            header: 'Period',
            content: period,
            icon: <FaIcons.FaCalendarWeek className={iconStyle} />,
        },
        {
            header: 'Number of Participants',
            content: state.numOfAccepted,
            icon: <FaIcons.FaUserCheck className={iconStyle} />,
        },
        {
            header: 'Main Parameter',
            content: state.mainParameter,
            icon: <FaIcons.FaStar className={iconStyle} />,
        },
    ];

    if (state.displayParameters[0]) {
        compDetails.push({
            header: 'Display Parameters',
            content: state.displayParameters.join(', '),
            icon: <FaIcons.FaSearch className={iconStyle} />,
        });
    }

    return (
        <div className="w-full">
            <div className="flex justify-between mb-4 ">
                <h2 className="font-bold text-xl text-gray-50">Parameters</h2>
                <div>
                    <buttons.SaveAsCSV
                        text="Download Participants w/Points"
                        onSave={() => downloadParticipantsCSV()}
                    />
                    <span className="ml-4">
                        <buttons.SaveAsCSV
                            text="Download Invites"
                            onSave={() => downloadInvitesCSV()}
                        />
                    </span>
                </div>
            </div>
            <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-4">
                {compDetails.map((comp, i) => {
                    return (
                        <ContentCard
                            header={comp.header}
                            content={comp.content}
                            icon={comp.icon}
                            key={i}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default CompetitionDetails;
