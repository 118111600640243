import { memoizedMakeReq } from './apiCommon';

const articleMasterdataApi = (axios) => ({
    getCategories: memoizedMakeReq(axios, 'article-masterdata/categories'),
    getModelTypes: memoizedMakeReq(axios, 'article-masterdata/model-types'),
    getGroups: memoizedMakeReq(axios, 'article-masterdata/groups'),
    getModels: memoizedMakeReq(axios, 'article-masterdata/models'),
    getBrands: memoizedMakeReq(axios, 'article-masterdata/brands'),
});

export default articleMasterdataApi;
