/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { Table, buttons, MultiSelect, Loader } from '@/components';
import {
    columnTypes,
    removeRow,
    removeAllRows,
    getName,
    getUserName,
} from '@/components/Table/index';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';

const createRow = (dataRow, employees) => {
    const row = {
        id: dataRow.id,
        employeeId: columnTypes.employeeId.cell(dataRow.employeeId),
        username: columnTypes.username.cell(getUserName(dataRow, employees)),
        name: columnTypes.name.cell(getName(dataRow, employees)),
        storeId: columnTypes.storeId.cell(dataRow.storeId),
        points: columnTypes.points.cell(dataRow.points),
        comment: columnTypes.comment.cell(dataRow.comment),
    };
    return row;
};

const formatEmployeLabel = (props) => {
    const { value, label, username } = props;
    return (
        <div className="flex flex-row">
            <div>{value} </div>
            <div className="flex flex-1 flex-col">
                <div className="ml-2">{label}</div>
                <div className="ml-2">({username})</div>
            </div>
        </div>
    );
};

const selectStyles = {
    menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
    }),
};

const EmployeeValueLabel = (props) => {
    return <div>{props.data.label}</div>;
};

const csvRowValidator = (currentData, employees, stores) => (row) => {
    const [employeeId, storeId] = row;
    const invalidStore = !stores.find((store) => store.value === storeId);
    const employeeStoreExists =
        currentData.filter(
            (d) =>
                d.employeeId.value === employeeId && d.storeId.value == storeId
        ).length > 0;
    const invalidEmployeeId = !employees.find(
        (employee) => employee.value === employeeId
    );
    const text = [
        employeeStoreExists ? 'Employee-store combination exists' : '',
        invalidEmployeeId ? 'Invalid employee id' : '',
        invalidStore ? 'Missing store' : '',
    ].filter((m) => m !== '');
    const isValid = [
        invalidStore,
        employeeStoreExists,
        invalidEmployeeId,
    ].reduce((acc, curr) => acc == curr, false);
    return {
        isValid: isValid,
        text: isValid ? ['ok'] : text,
    };
};

const AddEmployeeForm = ({ onSave, onClose, employees, storeOptions }) => {
    const [state, setState] = useState({});

    const handleInputChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (key) => (value) => {
        setState({ ...state, [key]: value });
    };

    const handleStoresSelected = (selected) => {
        setState({ ...state, stores: selected });
    };

    const loadOptions = (inputValue) => {
        const lowerInput = inputValue.toLowerCase();
        const options = employees
            .filter(
                (employee) =>
                    employee.value.toLowerCase().includes(lowerInput) ||
                    employee.label.toLowerCase().includes(lowerInput) ||
                    employee.username.toLowerCase().includes(lowerInput)
            )
            .slice(0, 20);
        return new Promise((resolve) => resolve(options));
    };

    const handleSave = (e) => {
        e.preventDefault();
        const stateToSave = {
            ...state,
            stores: state.stores.filter((s) => s.value != '*'),
        };
        onSave(stateToSave);
    };

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <form className="flex flex-col space-y-2 w-full">
                <div className="w-full">
                    <label className="text-gray-50">Employees</label>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        isMulti
                        onChange={handleSelectChange('employees')}
                        formatOptionLabel={formatEmployeLabel}
                        components={{
                            MultiValueLabel: EmployeeValueLabel,
                        }}
                        styles={selectStyles}
                        name="employees"
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Stores</label>
                    <MultiSelect
                        onChange={handleStoresSelected}
                        options={storeOptions}
                        allowSelectAll={true}
                        value={state.stores}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Points</label>
                    <input
                        name="points"
                        type="number"
                        step="1"
                        className="px-2 w-full rounded-md h-9 "
                        onBlur={handleInputChange}
                    />
                </div>
                <div className="w-full">
                    <label className="text-gray-50">Comment</label>
                    <input
                        name="comment"
                        className="px-2 w-full rounded-md h-9 "
                        onBlur={handleInputChange}
                    />
                </div>
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Add onAdd={handleSave} />
                </div>
            </form>
        </div>
    );
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const sortStrings = (a, b) => {
    const upperA = a.toUpperCase();
    const upperB = b.toUpperCase();
    if (upperA < upperB) return -1;
    if (upperA > upperB) return 1;
    return 0;
};

const EmployeeCorrections = () => {
    const { apiFactory } = useDependencies();
    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showAddEmployee, setShowAddEmployee] = useState(false);
    const [state, setState] = useState({});
    const navigate = useNavigate();
    const query = useQuery();
    const [queryParams, setQueryParams] = useState({
        fiscalKey: query.get('fiscalKey') || '',
    });
    const { masterdataApi, employeeCorrectionsApi } = apiFactory;

    const getFiscalKeyOption = (param, options) => {
        return options
            ? options.flatMap((o) => o.options).find((fk) => fk.value === param)
            : null ?? { value: '' };
    };

    const columns = useMemo(() => {
        const fixedColumns = [
            columnTypes.employeeId.column(),
            columnTypes.username.column(),
            columnTypes.name.column(),
            columnTypes.storeId.column(),
            columnTypes.points.column(),
            columnTypes.comment.column(),
        ];
        return fixedColumns;
    }, []);

    useEffect(() => {
        async function getData() {
            const data = state.employeeCorrections
                .filter((r) => r.fiscalKey === queryParams.fiscalKey)
                .flatMap((r) => r.data);
            const tableData = data.map((d) =>
                createRow(d, state.employeeOptions)
            );
            setTableData(tableData);
        }
        if (queryParams.fiscalKey && state.employeeCorrections) {
            getData();
        }
    }, [queryParams, state]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const [employees, periodOptions, stores, employeeCorrections] =
                await Promise.all([
                    masterdataApi.getEmployees(),
                    masterdataApi.getBonusPeriods(),
                    masterdataApi.getStores(),
                    employeeCorrectionsApi.getEmployeeCorrections(),
                ]);
            const employeeOptions = employees.map((employee) => ({
                ...employee,
                value: employee.employeeId,
                label: `${employee.firstname} ${employee.lastname}`,
            }));
            const storeOptions = stores
                .map((store) => ({
                    value: store.storeId,
                    label: `${store.departmentName} (${store.storeId})`,
                }))
                .sort((a, b) => sortStrings(a.label, b.label));
            if (mounted) {
                setState({
                    employeeOptions,
                    storeOptions,
                    periodOptions,
                    employeeCorrections,
                });
                setIsLoaded(true);
            }
        }
        getData();
        return () => (mounted = false);
    }, [employeeCorrectionsApi, masterdataApi]);

    const openAddEmployee = () => setShowAddEmployee(true);
    const closeAddEmployee = () => setShowAddEmployee(false);
    const addEmployees = (data) => {
        const newRows = data.employees.flatMap((employee) =>
            data.stores.map((store) => {
                return {
                    id: uuidv4(),
                    employeeId: employee.value,
                    username: employee.username,
                    name: employee.label,
                    storeId: store.value,
                    points: data.points,
                    comment: data.comment,
                };
            })
        );
        const newTableData = [
            ...tableData,
            ...newRows.map((d) => createRow(d)),
        ];
        setTableData(newTableData);
        setShowAddEmployee(false);
    };
    const saveData = async () => {
        const transform = (tableData) => {
            const data = tableData.map((row) => {
                return {
                    id: row.id,
                    employeeId: columnTypes.employeeId.value(row),
                    comment: columnTypes.comment.value(row),
                    points: columnTypes.points.value(row),
                    storeId: columnTypes.storeId.value(row),
                };
            });
            return {
                data: data,
                fiscalKey: queryParams.fiscalKey,
                simulationId: simulationIdState.get()[0].simulationId,
            };
        };
        const data = transform(tableData);
        await employeeCorrectionsApi.saveEmployeeCorrections(data);
        const savedData = await employeeCorrectionsApi.getEmployeeCorrections(
            true
        );
        setState({ ...state, employeeCorrections: savedData });
        return {
            valid: true,
        };
    };

    const addCsvData = (rows) => {
        const newRows = rows.data.map((row) => ({
            id: uuidv4(),
            employeeId: row.items[0],
            storeId: row.items[1],
            points: row.items[2],
            comment: row.items[3],
        }));
        const newTableData = [
            ...tableData,
            ...newRows.map((d) => createRow(d, state.employeeOptions)),
        ];
        setTableData(newTableData);
    };

    const periodChanged = (selectedValue) => {
        const newQueryParams = {
            ...queryParams,
            fiscalKey: selectedValue.value,
        };
        setQueryParams(newQueryParams);
        navigate({
            search: `?fiscalKey=${selectedValue.value || ''}`,
        });
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData));
    };

    const rows = useMemo(() => {
        return [...tableData];
    }, [tableData]);

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row[columnName] = { ...row[columnName], ...{ value } };
        setTableData(newTableData);
    };

    const sampleCSV = () => `112950\t2036\t1111\tShe deserves it
210098\t3004\t2222\tYou get points and you get points`;

    // Convert table data to string
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) =>
                    `${row.employeeId.value}\t${row.username.value}\t${row.name.value}\t${row.storeId.value}\t${row.points.value}\t${row.comment.value}`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    return (
        <div className="flex w-full h-full flex-col">
            <div className="text-center py-8 w-full">
                <label className="font-bold">Period: </label>
                <Select
                    options={state.periodOptions}
                    onChange={periodChanged}
                    className="w-48 inline-block text-gray-900"
                    value={getFiscalKeyOption(
                        queryParams.fiscalKey,
                        state.periodOptions
                    )}
                />
            </div>
            <div className="content w-full flex-1 flex flex-row">
                {queryParams.fiscalKey ? (
                    isLoaded ? (
                        <>
                            <Table
                                data={rows}
                                onNewRow={openAddEmployee}
                                columns={columns}
                                onSaveData={saveData}
                                csvRowValidator={csvRowValidator(
                                    tableData,
                                    state.employeeOptions,
                                    state.storeOptions
                                )}
                                onRemoveRow={deleteRow}
                                onRemoveAllRows={deleteAllRows}
                                addCsvData={addCsvData}
                                onUpdateData={updateData}
                                sampleCSV={sampleCSV}
                                rowsCSV={rowsCSV}
                                csvColumns={[
                                    'Employee id',
                                    'Store id',
                                    'Points',
                                    'Comment',
                                ]}
                            />
                            <Modal
                                isOpen={showAddEmployee}
                                className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
                                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-0 bottom-0 bg-gray-900 bg-opacity-80"
                                onRequestClose={(e) => {
                                    e.stopPropagation();
                                    setShowAddEmployee(false);
                                }}
                                shouldCloseOnOverlayClick={true}
                            >
                                <AddEmployeeForm
                                    onClose={closeAddEmployee}
                                    onSave={addEmployees}
                                    employees={state.employeeOptions}
                                    storeOptions={state.storeOptions}
                                />
                            </Modal>
                        </>
                    ) : (
                        <div className="w-full text-center">
                            <Loader />
                        </div>
                    )
                ) : (
                    <div className="w-full text-center">
                        <h3 className="text-3xl font-bold text-gray-50">
                            Select fiscal key
                        </h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmployeeCorrections;
