import React, { useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
import { MultiSelect, ErrorMsg, buttons } from '@/components';
import { useDependencies } from '@/DependencyProvider';
import ContentCard from '../competition/ContentCard';
import * as FaIcons from 'react-icons/fa';
// import { RenderContext } from '@/renderContext';
// import { saveSimulationId } from '@/utils';

const PickSimulationOpchains = ({
    operatingChains,
    updateSimulationList,
    onClose,
    chosenRowData,
}) => {
    const [selectedOpChains, setSelectedOpChains] = useState([]);
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    const { apiFactory } = useDependencies();
    const { simulationApi } = apiFactory;

    const runSimulation = async () => {
        const selectedOpChainValues = selectedOpChains.map(
            (opch) => opch.value
        );
        await simulationApi.runSimulation({
            simulationId: chosenRowData.original.simulationId,
            opchainCodes: selectedOpChainValues,
        });
        updateSimulationList();
    };

    const iconStyle = 'text-gray-500 h-10 w-10 m-0';

    const contentCards = [
        {
            header: 'Simulation Name',
            content: chosenRowData.original.name,
            icon: <FaIcons.FaInfoCircle className={iconStyle} />,
        },
        {
            header: 'Period(s)',
            content: chosenRowData.original.periods.join(', '),
            icon: <FaIcons.FaCalendarWeek className={iconStyle} />,
        },
    ];

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <form className="flex flex-col w-full">
                <h3 className="font-bold text-center h-10 text-xl text-gray-50 mb-2">
                    Choose operating chains for simulation
                </h3>
                <div className="flex flex-col gap-4 sm:items-start">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-2">
                        {contentCards.length > 0
                            ? contentCards.map((card, i) => {
                                  return (
                                      <ContentCard
                                          header={card.header}
                                          content={card.content}
                                          icon={card.icon}
                                          key={i}
                                          isEditMode={false}
                                      />
                                  );
                              })
                            : null}
                    </div>
                    <label
                        htmlFor="opChains"
                        className="text-gray-50 font-medium"
                    >
                        Operating Chains
                    </label>
                    <span className="flex flex-col w-full">
                        <MultiSelect
                            onChange={(opChains) =>
                                setSelectedOpChains(opChains)
                            }
                            options={operatingChains}
                            menuPortalTarget={document.body}
                        />
                        <ErrorMsg
                            condition={
                                showErrorMsg && selectedOpChains.length === 0
                            }
                            errorMsg="This field is required"
                        />
                    </span>
                </div>
                <div className="pt-5">
                    <div className="flex justify-end">
                        <buttons.Cancel
                            text="Cancel"
                            onCancel={onClose}
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        />
                        <buttons.Button
                            text="Run"
                            onClick={runSimulation}
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PickSimulationOpchains;
