/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
    NavLink,
    Routes,
    Route,
    Navigate,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {
    TotalPoints,
    StorePoints,
    StoreOperations,
    SalesIndividual as SalesIndividual,
    OperationsIndividual,
    CustomerRecruitment,
    RecurringSales,
} from './bonusRuleGeneral';
import DateSpecific from './bonusRuleGeneral/DateSpecific';
import { useDependencies } from '@/DependencyProvider';

const bonusRulesRoutes = [
    {
        title: 'Sales staff',
        path: 'sales-staff',
        component: () => <DateSpecific staffType="sales" />,
        modes: ['DATE_SPECIFIC'],
    },
    {
        title: 'Operations staff',
        path: 'operations-staff',
        component: () => <DateSpecific staffType="operations" />,
        modes: ['DATE_SPECIFIC'],
    },
    {
        title: 'Total points',
        path: 'total-points',
        component: (fiscalKey) => <TotalPoints fiscalKey={fiscalKey} />,
        modes: ['GENERAL', 'PERIOD_SPECIFIC'],
    },
    {
        title: 'Store points',
        path: 'store-points',
        component: (fiscalKey) => <StorePoints fiscalKey={fiscalKey} />,
        modes: ['GENERAL', 'PERIOD_SPECIFIC'],
    },
    {
        title: 'Store Operations',
        path: 'store-operations',
        component: (fiscalKey) => <StoreOperations fiscalKey={fiscalKey} />,
        modes: ['GENERAL', 'PERIOD_SPECIFIC'],
    },
    {
        title: 'Sales Individual',
        path: 'sales-individual',
        component: (fiscalKey) => <SalesIndividual fiscalKey={fiscalKey} />,
        modes: ['GENERAL', 'PERIOD_SPECIFIC'],
    },
    {
        title: 'Operations Individual',
        path: 'operations-individual',
        component: (fiscalKey) => (
            <OperationsIndividual fiscalKey={fiscalKey} />
        ),
        modes: ['GENERAL', 'PERIOD_SPECIFIC'],
    },
    {
        title: 'Recurring Sales',
        path: 'recurring-sales',
        component: (fiscalKey) => <RecurringSales fiscalKey={fiscalKey} />,
        modes: ['GENERAL', 'PERIOD_SPECIFIC'],
    },
    {
        title: 'Customer Recruitment',
        path: 'customer-recruitment',
        component: (fiscalKey) => <CustomerRecruitment fiscalKey={fiscalKey} />,
        modes: ['GENERAL', 'PERIOD_SPECIFIC'],
    },
];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function BonusRules(props) {
    const { apiFactory } = useDependencies();
    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();
    const [fiscalKeyOptions, setFiscalKeyOptions] = useState([]);
    const fiscalKeyQueryParam = query.get('fiscalKey') || '';

    useEffect(() => {
        async function getPeriods() {
            const { masterdataApi } = apiFactory;
            const options = await masterdataApi.getBonusPeriods();
            const optionsWithDefault = [
                { label: 'General', value: '' },
                ...options,
            ];
            setFiscalKeyOptions(optionsWithDefault);
        }
        getPeriods();
    }, [apiFactory]);

    const fiscalKeyChanged = (selectedValue) => {
        navigate({
            search: `?fiscalKey=${selectedValue.value}`,
        });
    };

    const routes = bonusRulesRoutes.filter(
        (route) => route.modes.indexOf(props.mode) > -1
    );

    const routePaths = routes.reduce(
      (acc, item) =>  [...acc, item.path],
      []
    );

    if (!routePaths.includes(location.pathname.split("/").pop())) {
        return <Navigate to={routes[0].path} />;
    }

    const selectedFiscalKey = fiscalKeyOptions
        .flatMap((o) => o.options || o)
        .find((fk) => fk.value === fiscalKeyQueryParam) ?? { value: '' };

    return (
        <div className="flex w-full h-full flex-col">
            {props.mode === 'PERIOD_SPECIFIC' && (
                <div className="text-center py-8 w-full">
                    <label className="font-bold">Period: </label>
                    <Select
                        options={fiscalKeyOptions}
                        onChange={fiscalKeyChanged}
                        className="w-48 inline-block text-gray-900"
                        value={selectedFiscalKey}
                    />
                </div>
            )}
            <div className="header w-full flex bg-gray-700">
                <div className="flex flex-row m-auto">
                    {routes.map((item, index) => {
                        return (
                            <NavLink
                                end
                                to={`${item.path}${location.search}`}
                                className={
                                    ({ isActive }) =>
                                        "py-4 pl-4 pr-4 text-gray-100 hover:text-gray-300 hover:bg-gray-600" +
                                        (isActive
                                            ? " border-solid border-blue-500 border-b-2 text-blue-500"
                                            : ""
                                        )
                                }
                                key={index}
                            >
                                <div>{item.title}</div>
                            </NavLink>
                        );
                    })}
                </div>
            </div>
            <div className="content w-full flex-1 flex flex-row">
                <Routes>
                    {bonusRulesRoutes.map((item, index) => {
                        return (
                            <Route
                                key={index}
                                path={item.path}
                                element={item.component(selectedFiscalKey.value)}
                            />
                        );
                    })}
                </Routes>
            </div>
        </div>
    );
}

export default BonusRules;
