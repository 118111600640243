import React, { useState } from 'react';
import * as BsIcons from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import { useAuth } from '@/auth';
import { isAuthorizedForSidebar, isAdmin } from '@/utils';
import { simulationIdState } from '@/simulationIdState';
import { constants } from '@/utils';
import { buttons } from '@/components';
import Modal from 'react-modal';
import BonusCalculationModal from '@/components/BonusCalculationModal';

function InnerLink(item, index) {
    return (
        <NavLink
            to={item.path}
            key={index}
            className={({ isActive }) => "text-gray-100 block pl-14 hover:bg-gray-500 py-1" + (isActive ? " bg-gray-500" : "")}
        >
            {item.title}
        </NavLink>
    );
}

function Navbar() {
    const [openedNavs, setOpenedNavs] = useState({});
    const [showBonusCalcModal, setShowBonusCalcModal] = useState(false);

    const toggleNav = (index, evt) => {
        setOpenedNavs({ ...openedNavs, [index]: !openedNavs[index] });
        evt.preventDefault();
    };
    const auth = useAuth();
    const roles = auth.roles;
    const isSimulation =
        simulationIdState.get()[0].simulationId === constants.PRODUCTION
            ? false
            : true;
    const filtered = isSimulation
        ? SidebarData.filter((item) => item.title !== 'Competition')
        : SidebarData;

    const showCalculationBtn = () => {
        const d = new Date();
        const hour = d.getHours();
        if (hour >= 9 && hour <= 22) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className="nav-bar bg-gray-800 fixed inset-0 flex z-40 mt-12 flex-none w-60">
            <ul className="nav-list flex flex-col flex-1">
                {filtered.map((item, index) => {
                    const hasSubNav = item.subNav && item.subNav.length > 0;
                    const showNav = openedNavs[index];
                    const authorized = isAuthorizedForSidebar(
                        item.authRoles,
                        roles
                    );
                    return (
                        <React.Fragment key={index}>
                            {authorized && (
                                <li className="nav-item w-full border-b border-solid border-gray-600 flex flex-col">
                                    <NavLink
                                        end
                                        to={item.path}
                                        onClick={(e) =>
                                            !hasSubNav || toggleNav(index, e)
                                        }
                                        className={({ isActive }) => "relative text-gray-100 flex p-4" + (isActive ? " bg-gray-500" : "")}

                                    >
                                        {item.icon(
                                            'h-6 w-6 fill-current inline mr-4'
                                        )}
                                        <span className="flex-1 align-baseline leading-normal">
                                            {item.title}
                                        </span>
                                        {showNav ? (
                                            <BsIcons.BsChevronDown className="h-6 w-6 inline-block text-left" />
                                        ) : (
                                            hasSubNav && (
                                                <BsIcons.BsChevronRight className="h-6 w-6 inline-block text-left" />
                                            )
                                        )}
                                    </NavLink>
                                    {hasSubNav && showNav && (
                                        <div className="nav-sub">
                                            {item.subNav.map(InnerLink)}
                                        </div>
                                    )}
                                </li>
                            )}
                        </React.Fragment>
                    );
                })}
                {isAdmin(roles) && showCalculationBtn() && (
                    <buttons.Primary
                        text="Run Bonus Calculation"
                        className="hover:underline mt-4"
                        customClass="mt-12"
                        onClick={() => setShowBonusCalcModal(true)}
                    />
                )}
            </ul>
            <Modal
                isOpen={showBonusCalcModal}
                className="bg-gray-700 w-full max-w-lg h-auto rounded-lg"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowBonusCalcModal(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <BonusCalculationModal
                    onClose={() => setShowBonusCalcModal(false)}
                />
            </Modal>
        </div>
    );
}

export default Navbar;
