import { simulationIdState } from '@/simulationIdState';
import { constants, roles, pages } from '@/utils';

export const isAuthorized = (componentName, userRoles) => {
    const isSimulation =
        simulationIdState.get()[0].simulationId === constants.PRODUCTION
            ? false
            : true;
    if (userRoles.includes(roles.ADMIN)) {
        return true;
    } else if (
        userRoles.includes(roles.SIMULATION) &&
        userRoles.includes(roles.HR)
    ) {
        if (
            (isSimulation && componentName !== pages.OPERATINGCHAINS) ||
            componentName === pages.EMPLOYEEFACTORS ||
            componentName === pages.SIMULATIONPAGE
        ) {
            return true;
        }
    } else if (userRoles.includes(roles.SIMULATION)) {
        if (
            (isSimulation && componentName !== pages.OPERATINGCHAINS) ||
            componentName === pages.SIMULATIONPAGE
        ) {
            return true;
        }
    } else if (userRoles.includes(roles.HR)) {
        if (componentName === pages.EMPLOYEEFACTORS) {
            return true;
        }
    } else {
        return false;
    }
};

export const isAuthorizedForSidebar = (sideBarRoles, authRoles) => {
    return sideBarRoles.some((role) => authRoles.includes(role));
};
export const isAdmin = (userRoles) => {
    return userRoles.includes(roles.ADMIN);
};
