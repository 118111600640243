import React, { useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MultiSelect, ErrorMsg, buttons } from '@/components';
import { useDependencies } from '@/DependencyProvider';
import { RenderContext } from '@/renderContext';
import { saveSimulationId } from '@/utils';

const CreateSimulation = ({
    periods,
    setPageLoading,
    updateSimulationList,
    onClose,
    operatingChains,
}) => {
    const { apiFactory } = useDependencies();
    const { simulationApi } = apiFactory;
    const { setValue } = useContext(RenderContext);
    const [simulationName, setSimulationName] = useState();
    const [selectedPeriods, setSelectedPeriods] = useState([]);
    const [selectedOpchains, setSelectedOpChains] = useState([]);
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    useEffect(() => {
        setSelectedOpChains(operatingChains);
    }, [operatingChains]);

    const createSimulation = async (event) => {
        event.preventDefault();
        if (simulationName && selectedPeriods.length > 0) {
            const id = uuidv4();
            localStorage.setItem('simulationName', simulationName);
            const simulationData = {
                simulationId: id,
                name: simulationName,
                periods:
                    selectedPeriods.length > 0
                        ? selectedPeriods.map((period) => period.value)
                        : [],
                opchainCodes: selectedOpchains.map((op) => op.value),
            };
            setPageLoading(true);
            await simulationApi.createSimulation(simulationData);
            saveSimulationId(id);
            updateSimulationList();
            setValue((value) => value + 1);
        } else {
            setShowErrorMsg(true);
        }
    };

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <form className="flex flex-col w-full">
                <h3 className="font-bold text-center h-10 text-xl text-gray-50 mb-2">
                    Create new simulation
                </h3>
                <div className="w-full flex flex-col gap-4">
                    <div className="flex flex-col gap-4 sm:items-start">
                        <label
                            htmlFor="simulation-name"
                            className="text-gray-50 font-medium"
                        >
                            Simulation name
                        </label>
                        <span className="flex flex-col w-full">
                            <input
                                type="text"
                                name="simulation-name"
                                id="simulation-name"
                                onChange={(e) =>
                                    setSimulationName(e.target.value)
                                }
                                className="h-full w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md p-1 grow h-38px"
                            />
                            <ErrorMsg
                                condition={showErrorMsg && !simulationName}
                                errorMsg="This field is required"
                            />
                        </span>
                    </div>
                    <div className="flex flex-col gap-4 sm:items-start">
                        <label
                            htmlFor="periods"
                            className="text-gray-50 font-medium"
                        >
                            Period
                        </label>
                        <span className="flex flex-col w-full">
                            <MultiSelect
                                onChange={(periods) =>
                                    setSelectedPeriods(periods)
                                }
                                options={periods}
                                menuPortalTarget={document.body}
                            />
                            <ErrorMsg
                                condition={
                                    showErrorMsg && selectedPeriods.length === 0
                                }
                                errorMsg="This field is required"
                            />
                        </span>
                    </div>
                    <div className="flex flex-col gap-4 sm:items-start">
                        <label
                            htmlFor="opchains"
                            className="font-medium text-gray-50"
                        >
                            Operating chains
                        </label>
                        <span className="flex flex-col w-full">
                            <MultiSelect
                                name="opchains"
                                onChange={(opChains) =>
                                    setSelectedOpChains(opChains)
                                }
                                options={operatingChains}
                                menuPortalTarget={document.body}
                                value={selectedOpchains}
                            />
                            <ErrorMsg
                                condition={
                                    showErrorMsg &&
                                    selectedOpchains?.length === 0
                                }
                                errorMsg="This field is required"
                            />
                        </span>
                    </div>
                </div>
                <div className="pt-5">
                    <div className="flex justify-end">
                        <buttons.Cancel
                            text="Cancel"
                            onCancel={onClose}
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        />
                        <buttons.Button
                            text="Create"
                            onClick={createSimulation}
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateSimulation;
