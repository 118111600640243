import React, { useState, useMemo } from 'react';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import * as buttons from './buttons';
import * as FaIcons from 'react-icons/fa';
import Select from 'react-select';
import { customSelectDropdown } from './style';

const DisplayTable = ({
    columns,
    tableData,
    onToggleItem,
    competition = false,
    showPageOptions = true,
    onRemoveSelected,
    onToggleAll,
    isAuthorized,
    showCreateCompetition,
    showDeleteSelectedButton = true,
}) => {
    const [data, setData] = useState([]);

    useMemo(() => {
        setData(tableData);
    }, [tableData]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 50,
                sortBy: [{ id: 'dateCreate', desc: true }],
            },
            autoResetSortBy: false,
            autoResetFilters: false,
        },
        useFilters,
        useSortBy,
        usePagination
    );

    let tableStyles;

    if (competition === false) {
        tableStyles =
            'divide-y divide-gray-200 mb-48 bg-white w-full sm:px-6 lg:px-8 rounded-md';
    } else {
        tableStyles =
            'divide-y divide-gray-200 mb-4 bg-white w-full sm:px-6 lg:px-8 rounded-md';
    }

    const allNotChecked =
        tableData.findIndex(
            (y) => y.selected == null || y.selected === false
        ) >= 0;

    const toggleAll = (shouldToggle) => {
        onToggleAll(
            tableData.map((r) => r.id),
            shouldToggle
        );
    };

    return (
        <>
            <div className="flex flex-row justify-between">
                {competition && isAuthorized && (
                    <span>
                        <buttons.Button
                            onClick={showCreateCompetition}
                            text="Create new competition"
                            className={
                                'mt-6 mb-6 w-max inline-flex items-center px-4 py-2 border border-transparent text-m font-bold rounded-md shadow-sm text-gray-200 hover:text-white bg-blue-500 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            }
                        />
                    </span>
                )}
               {onToggleItem && showDeleteSelectedButton && (
                    <span className="flex flex-col flex-wrap items-center justify-end my-2 space-x-2 space-y-2 text-gray-800 md:flex-row">
                        <buttons.Delete
                            onDelete={onRemoveSelected}
                            text="Delete Selected"
                        />
                    </span>
                )}
            </div>
            <table {...getTableProps()} className={tableStyles}>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                            {onToggleItem && (
                                <th className="px-3 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                                    <input
                                        type="checkbox"
                                        checked={!allNotChecked}
                                        onChange={() =>
                                            toggleAll(allNotChecked)
                                        }
                                        disabled={!onToggleAll}
                                    />
                                </th>
                            )}
                            {headerGroup.headers.map((column, i) => (
                                <th
                                    key={i}
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    className="px-3 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase rounded-md bg-gray-50"
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                    <div>
                                        {column.canFilter
                                            ? column.render('Filter')
                                            : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody
                    {...getTableBodyProps()}
                    className="bg-white divide-y divide-gray-200"
                >
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr
                                key={i}
                                {...row.getRowProps()}
                                className="rounded-md"
                            >
                                {onToggleItem && (
                                    <td className="px-3 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase rounded-md bg-gray-50">
                                        <input
                                            type="checkbox"
                                            checked={row.original.selected}
                                            onChange={() =>
                                                onToggleItem(row.original.id)
                                            }
                                        />
                                    </td>
                                )}
                                {row.cells.map((cell, i) => {
                                    return (
                                        <td
                                            key={i}
                                            {...cell.getCellProps()}
                                            className="p-3 mx-1 text-sm font-medium text-gray-900 whitespace-normal rounded-md flex-nowrap min-w-min"
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="flex flex-col flex-wrap items-center justify-center pt-4 pb-4 mb-4 ml-2 text-white ">
                <span className="mb-4">
                    <buttons.Button
                        text=""
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                        icon={<FaIcons.FaAngleDoubleLeft />}
                        className="ml-2 btn-sm-add"
                        style={{ paddinf: '0.25rem' }}
                    />
                    <buttons.Button
                        text=""
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        icon={<FaIcons.FaAngleLeft />}
                        className="mx-2 btn-sm-add"
                    />
                    <span className="pl-1 text-lg text-gray-100">
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <buttons.Button
                        text=""
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        icon={<FaIcons.FaAngleRight />}
                        className="ml-2 btn-sm-add"
                    />
                    <buttons.Button
                        text=""
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                        icon={<FaIcons.FaAngleDoubleRight />}
                        className="mx-2 btn-sm-add"
                    />
                </span>
                {showPageOptions && (
                    <span className="flex items-end pr-2 text-lg text-gray-100 mb-52">
                        <span className="ml-2">
                            Go to page:{' '}
                            <input
                                type="number"
                                className="px-2 mx-1 text-black"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value
                                        ? Number(e.target.value) - 1
                                        : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>{' '}
                        <Select
                            options={[
                                { value: 10, label: 'Show 10' },
                                { value: 25, label: 'Show 25' },
                                { value: 50, label: 'Show 50' },
                                { value: 100, label: 'Show 100' },
                            ]}
                            onChange={(e) => {
                                setPageSize(Number(e.value));
                            }}
                            className="inline-block w-48 text-gray-900 "
                            classNamePrefix="select-sm"
                            styles={customSelectDropdown(22)}
                            value={50}
                            placeholder={'Show ' + pageSize}
                        >
                            show
                        </Select>
                    </span>
                )}
            </div>
        </>
    );
};

export default DisplayTable;
