import React, { useEffect, useState } from 'react';
import * as buttons from '@/components/buttons';

const OperatingChainsDeleteModal = ({ deletingStores, opchain, onClose }) => {
    const [periods, setPeriods] = useState([]);

    useEffect(() => {
        const periods = deletingStores.map((obj) =>
            obj.fiscalKey ? obj.fiscalKey : 'General'
        );
        setPeriods(periods);
    }, [deletingStores, opchain]);

    const listItems = periods.map((period) => (
        <li key={period} className="list-none text-gray-50 py-1 px-3 font-bold">
            {period}
        </li>
    ));

    return (
        <div className="bg-gray-700 p-6 rounded-lg text-center">
            <div className="flex flex-col space-y-2">
                <h2 className="font-bold text-start h-10 text-xl text-red-500">
                    Cannot Delete Operating Chain {opchain}
                </h2>
                <p className="text-gray-50 py-2">
                    You have to delete stores connecting to this operating chain
                    before deleting it
                </p>
                <p className="text-gray-50 py-2 font-bold">
                    Periods that have {opchain}:
                </p>
                {listItems}
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                </div>
            </div>
        </div>
    );
};

export default OperatingChainsDeleteModal;
