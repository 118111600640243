import React from 'react';

export const formatEmployeeLabel = (props) => {
    const { storeId, label, username, employeeId } = props;
    return (
        <div className="flex flex-row justify-between gap-4">
            <div className="flex flex-0 flex-col mr-2">
                <div className="ml-2">
                    <span className="text-gray-500">Empl. ID </span>
                    {employeeId}
                </div>
                <div className="ml-2">
                    <span className="text-gray-500">Store ID </span>
                    {storeId}
                </div>
            </div>
            <div className="flex flex-col border-l-2"></div>
            <div className="flex flex-1 flex-col">
                <div className="ml-2">{label}</div>
                <div className="ml-2">({username})</div>
            </div>
        </div>
    );
};

export const EmployeeValueLabel = (props) => {
    return <div>{props.data.label}</div>;
};

export const selectStyles = {
    menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
        marginTop: 0,
        zIndex: 9999,
    }),
};

export const sampleCSV = () => {
    return `248020\t1001
248365\t2002
168597\t3003`;
};

export const activeParticipantsSelect = 'flex flex-col w-full h-auto';
export const activeCSV = 'flex flex-col pt-4 w-full';
export const selectedTab =
    'bg-gray-50 py-1 px-2 text-gray-700 font-bold rounded-lg';
export const unselectedTab =
    'text-gray-50 py-1 px-2 font-medium underline decoration-solid decoration-2 underline-offset-2 cursor-pointer';

export const filterEmployeeOptions = (input, employees) => {
    return employees
        .filter((employee) => {
            return (
                employee.storeId.toLowerCase().includes(input) ||
                employee.employeeId.toLowerCase().includes(input) ||
                employee.label.toLowerCase().includes(input) ||
                employee.username.toLowerCase().includes(input)
            );
        })
        .slice(0, 20);
};

export const isValidResponse = (row, filteredEmployees) => {
    const participant = {
        username: row[0].toUpperCase(),
        storeId: row[1],
    };

    const existingEmployee = filteredEmployees.filter(
        (empl) =>
            empl.username === participant.username &&
            empl.storeId === participant.storeId
    );

    if (existingEmployee.length === 1) {
        return { isValid: true, text: ['OK'] };
    } else {
        return {
            isValid: false,
            text: ['Invalid employee username or store ID'],
        };
    }
};

export const getCompleteSelectedParticipants = (
    state,
    newParticipants,
    filteredEmployees
) => {
    const csvParticipants = state.data.map((participant) => {
        return {
            employeeId: participant.items[0],
            storeId: participant.items[1],
        };
    });

    const newSelectedParticipants = [...newParticipants, ...csvParticipants];

    const completeNewSelectedParticipants = newSelectedParticipants.flatMap(
        (participant) =>
            filteredEmployees.filter(
                (empl) =>
                    empl.employeeId === participant.employeeId &&
                    empl.storeId === participant.storeId
            )
    );

    return completeNewSelectedParticipants;
};
