import React, { useState } from 'react';

const DateCell = ({
    value,
    row: {
        original: { id: rowId },
    },
    column: { id },
    onUpdateData,
}) => {
    const [config] = useState({ ...{ isDisabled: false }, ...value });
    const [changed, setChanged] = useState(false);
    const [inputValue, setInputValue] = useState(config.value);
    const onChange = (e) => {
        setChanged(true);
        setInputValue(e.target.value);
    };

    const onBlur = (e) => {
        if (changed) {
            onUpdateData(rowId, id, inputValue);
            setChanged(false);
        }
        e.target.type = 'date';
    };

    const selectCellOnDoubleClick = (e) => {
        if (e.detail > 1) {
            e.target.type = 'text';
            setTimeout(() => {
                e.target.select();
            });
        }
    };

    const handleClick = (e) => {
        selectCellOnDoubleClick(e);
    };

    const extraClasses = config.isDisabled ? 'disabled:opacity-50' : '';
    const className = `border-solid border-gray-200 border-2 w-full p-1 ${extraClasses}`;
    return (
        <div className="whitespace-nowrap w-full">
            <input
                value={inputValue}
                onChange={onChange}
                onBlur={onBlur}
                onClick={handleClick}
                className={className}
                disabled={config.isDisabled}
                type="date"
            />
        </div>
    );
};

export default DateCell;
