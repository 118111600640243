import React, { useState, useEffect, useMemo } from 'react';
import { Table, Loader } from '@/components';
import { v4 as uuidv4 } from 'uuid';
import { valueTypes, validators } from '@/utils';
import {
    columnTypes,
    removeRow,
    toggleRow,
    toggleAllRows,
    removeSelected,
    filters,
} from '@/components/Table/index';
import {
    saveRecurring,
    prepareTableData,
    prepareTableDataMerge,
} from './common';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';

const createKeyValueArgs = ({ searchArticles, searchModels, searchGroups }) => {
    return {
        getArticles: searchArticles,
        getModels: searchModels,
        getGroups: searchGroups,
    };
};

const createRow =
    (
        ruleTypes,
        keyValueArgs,
        operatingChainOptions,
        recurringParametsOptions
    ) =>
    (recurringSalesEntry) => {
        if (recurringSalesEntry) {
            const row = {
                id: recurringSalesEntry.id,
                selected: false,
                operatingChain: columnTypes.operatingChainType.cell(
                    recurringSalesEntry.operatingChain,
                    operatingChainOptions
                ),
                key: recurringSalesEntry.key,
                keyValue: columnTypes.serviceType.cell(
                    recurringSalesEntry.keyValue,
                    recurringParametsOptions
                ), //? SERVICE TYPE
                staff: columnTypes.staffType.cell(recurringSalesEntry.staff), //? STAFF
                settingsKey: columnTypes.ruleType.cell(
                    recurringSalesEntry.settingsKey,
                    ruleTypes
                ), //? RULE TYPE
                //? RULE VALUE
                settingsValue: columnTypes.keyValue.cell(
                    recurringSalesEntry.settingsValue,
                    recurringSalesEntry.settingsKey,
                    keyValueArgs
                ),
                //? Percentage/fixed
                valueType: columnTypes.valueType.cell(
                    recurringSalesEntry.valueType,
                    recurringSalesEntry.settingsKey,
                    recurringSalesEntry.settingsValue
                ),
                //? Rev/GM indicator
                revGMIndicator: columnTypes.revGMIndicator.cell(
                    recurringSalesEntry.revGMIndicator,
                    recurringSalesEntry.key,
                    recurringSalesEntry.keyValue,
                    recurringSalesEntry.valueType
                ),
                month: columnTypes.payment.cell(recurringSalesEntry.month),
                value: columnTypes.operatingChainValueTwo.cell(
                    recurringSalesEntry.value
                ),
            };
            return row;
        }
    };

const Filter = (props) => {
    return (
        <>
            <filters.OperatingChainFilter {...props} />
        </>
    );
};

export default function RecurringSales({ fiscalKey }) {
    const staffType = 'operations';

    const { apiFactory } = useDependencies();
    const [isLoaded, setIsLoaded] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [state, setState] = useState({});
    const [filterSpec, setFilterSpec] = useState({ operatingChain: null });

    const getOptions = async (getter) => {
        const response = await getter();
        return response.map((c) => ({
            value: c.id,
            label: `${c.id} - ${c.description}`,
        }));
    };

    useEffect(() => {
        if (state.data && state.ruleTypes && state.keyValueArgs) {
            const recurringSales = prepareTableData(state.data, fiscalKey);

            const unsortedRecurringSales = recurringSales
                .map(
                    createRow(
                        state.ruleTypes,
                        state.keyValueArgs,
                        state.operatingChainOptions,
                        state.recurringParametsOptions
                    )
                )
                .filter((obj) => obj.operatingChain.label.length > 0);

            const sortedByMonth = unsortedRecurringSales.sort((a, b) =>
                +b.month.value > +a.month.value
                    ? -1
                    : +a.month.value > +b.month.value
                    ? 1
                    : 0
            );
            const sortedByValueType = sortedByMonth.sort((a, b) =>
                b.valueType.value > a.valueType.value
                    ? -1
                    : a.valueType.value > b.valueType.value
                    ? 1
                    : 0
            );
            const sortedByRuleType = sortedByValueType.sort((a, b) =>
                b.settingsKey.value > a.settingsKey.value
                    ? -1
                    : a.settingsKey.value > b.settingsKey.value
                    ? 1
                    : 0
            );
            const sortedByRuleValue = sortedByRuleType.sort((a, b) =>
                b.settingsValue.value > a.settingsValue.value
                    ? -1
                    : a.settingsValue.value > b.settingsValue.value
                    ? 1
                    : 0
            );
            const sortedByStaff = sortedByRuleValue.sort((a, b) =>
                b.staff.value > a.staff.value
                    ? -1
                    : a.staff.value > b.staff.value
                    ? 1
                    : 0
            );
            const sortedByOperatingChain = sortedByStaff.sort((a, b) =>
                b.operatingChain.label[0].label >
                a.operatingChain.label[0].label
                    ? -1
                    : a.operatingChain.label[0].label >
                      b.operatingChain.label[0].label
                    ? 1
                    : 0
            );

            setTableData(sortedByOperatingChain);
            setIsLoaded(true);
        }
    }, [fiscalKey, state]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const {
                bonusSettingsApi,
                articleMasterdataApi,
                bonusRuleApi,
                searchApi,
            } = apiFactory;

            const [operatingChains, models, groups, data, recurringParameters] =
                await Promise.all([
                    bonusSettingsApi.getOperatingChains(),
                    getOptions(articleMasterdataApi.getModels),
                    getOptions(articleMasterdataApi.getGroups),
                    bonusRuleApi.getRecurringPayments(),
                    bonusRuleApi.getRecurringParameters(),
                ]);

            const recurringParametsOptions = recurringParameters.map(
                (param) => {
                    return {
                        label: param.serviceTypeName,
                        value: param.serviceTypeCode,
                    };
                }
            );

            const ruleTypes = [
                { value: 'GROUPCODE', label: 'Group' },
                { value: 'MODELCODE', label: 'Model' },
                { value: 'ARTICLECODE', label: 'Article Number' },
            ];

            const createFilteredOptions = (list, includeValue) => (input) => {
                const lowerInput = (
                    input && typeof input === 'string' ? input : ''
                ).toLowerCase();
                const filtered = list
                    .filter(
                        (b) =>
                            b.value.toLowerCase().includes(lowerInput) ||
                            b.label.toLowerCase().includes(lowerInput)
                    )
                    .sort((y) => y.value.length)
                    .slice(0, 10);
                if (
                    includeValue &&
                    filtered.map((y) => y.value).indexOf(input) < 0
                ) {
                    return [{ value: input, label: input }, ...filtered];
                }
                return filtered;
            };
            const searchModels = createFilteredOptions(models, true);
            const searchGroups = createFilteredOptions(groups, true);
            const keyValueArgs = createKeyValueArgs({
                searchArticles: searchApi.searchArticles,
                searchModels,
                searchGroups,
            });
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            mounted
                ? setState({
                      keyValueArgs,
                      ruleTypes,
                      operatingChains,
                      data,
                      operatingChainOptions,
                      recurringParametsOptions,
                  })
                : '';
        }
        getData();
        return () => (mounted = false);
    }, [apiFactory, staffType]);

    const columns = useMemo(() => {
        if (state.operatingChains) {
            const fixedColumns = [
                columnTypes.operatingChainType.column(),
                columnTypes.serviceType.column('keyValue'),
                columnTypes.staffType.column(),
                columnTypes.ruleType.column('settingsKey'),
                columnTypes.keyValue.column(true, 'settingsValue'),
                columnTypes.valueType.column(true),
                columnTypes.revGMIndicator.column(),
                columnTypes.payment.column(),
                columnTypes.operatingChainValueTwo.column(),
            ];
            return [...fixedColumns];
        } else {
            return [];
        }
    }, [state]);

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row.id = uuidv4();
        row[columnName] = { ...row[columnName], ...{ value } };
        if (columnName === 'settingsKey') {
            row['settingsValue'] = columnTypes.keyValue.cell(
                '',
                value,
                state.keyValueArgs
            );
            row['valueType'] = columnTypes.valueType.cell('', value);
        }
        if (columnName === 'settingsValue') {
            const keyCell = row['settingsKey'];
            row['valueType'] = columnTypes.valueType.cell(
                '',
                keyCell.value,
                value
            );
        }
        if (columnName === 'value') {
            row[columnName] = parseFloat(value).toFixed(2);
        }
        if (
            ['key', 'settingsValue', 'valueType', 'revGMIndicator'].indexOf(
                columnName
            ) > -1
        ) {
            const settingsValue =
                columnName === 'key' ? value : row['key'].value;
            const keyValueValue =
                columnName === 'keyValue' ? value : row['keyValue'].value;
            const valueTypeValue =
                columnName === 'valueType' ? value : row['valueType'].value;
            const revGMIndicatorValue =
                columnName === 'revGMIndicator'
                    ? value
                    : row['revGMIndicator'].value;
            row['revGMIndicator'] = columnTypes.revGMIndicator.cell(
                revGMIndicatorValue,
                settingsValue,
                keyValueValue,
                valueTypeValue
            );
        }
        setTableData(newTableData);
    };

    const newRow = () => {
        const operatingChains = columns
            .filter(
                (c) =>
                    ['id', 'key', 'keyValue', 'valueType'].indexOf(c.accessor) <
                    0
            )
            .map((c) => ({ operatingChain: c.accessor, value: 0 }));
        const total = {
            id: uuidv4(),
            valueType: valueTypes.percentage,
            key: 'SERVICETYPECODE',
            keyValue: '',
            CGM: '',
            operatingChains: operatingChains,
            value: 0,
        };
        const row = createRow(
            state.ruleTypes,
            state.keyValueArgs,
            state.operatingChainOptions,
            state.recurringParametsOptions
        )(total);
        const newTableData = [...tableData, row];
        setTableData(newTableData);
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteSelected = () => setTableData(removeSelected(tableData));

    const toggleItem = (id) => setTableData(toggleRow(id, tableData));

    const toggleAll = (ids, shouldToggle) => {
        setTableData(toggleAllRows(ids, shouldToggle, tableData));
    };

    const saveData = async () => {
        const newTable = tableData.reduce((filtered, recurringSale) => {
            if (
                recurringSale.key &&
                recurringSale.keyValue.value &&
                recurringSale.operatingChain.value
            ) {
                const obj = {
                    id: recurringSale.id,
                    key: recurringSale.key,
                    keyValue: recurringSale.keyValue.value,
                    operatingChain: recurringSale.operatingChain.value,
                    month: recurringSale.month.value,
                    revGMIndicator: recurringSale.revGMIndicator.value,
                    settingsKey: recurringSale.settingsKey.value,
                    settingsValue: recurringSale.settingsValue.value,
                    staff: recurringSale.staff.value,
                    value: recurringSale.value,
                    valueType: recurringSale.valueType.value,
                };
                filtered.push(obj);
            }
            return filtered;
        }, []);

        const bonusRuleApi = apiFactory.bonusRuleApi;
        await saveRecurring(bonusRuleApi.saveRecurringPayments)(
            newTable,
            fiscalKey,
            simulationIdState.get()[0].simulationId
        );
        const savedData = await bonusRuleApi.getRecurringPayments(true);
        setState({ ...state, data: savedData });
        return {
            valid: true,
        };
    };

    const rows = useMemo(() => {
        const filter = filters.composeFilters([filters.operatingChain])(
            filterSpec
        );
        const filtered = tableData.filter(filter);
        return [...filtered];
    }, [filterSpec, tableData]);

    /* Convert table data to string */
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) =>
                    `${row.operatingChain.value}\t${row.keyValue.value}\t${
                        row.staff.value
                    }\t${row.settingsKey.value}\t${row.settingsValue.value}\t${
                        row.valueType.value
                    }\t${
                        row.revGMIndicator.isVisible
                            ? row.revGMIndicator.value
                            : ''
                    }\t${row.month.value}\t${row.value}`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    const sampleCSV = () => {
        return `OCNOELK\tRECINSUR\tSALES\tMODELCODE\t58312\tPERCENTAGE\tGM\t1\t37
OCNOELK\tRECINSUR\tOPERATIONS\tGROUPCODE\t530\tPERCENTAGE\tGM\t1\t37
OCNOELK\tRECINSTA\tSALES\tARTICLECODE\t23192\tPERCENTAGE\tREV\t1\t37
`;
    };

    const { returnNullOrValue } = validators;

    const checkIfRowAlreadyExist = (uploadedRow, existingRow) => {
        return (
            uploadedRow.key === existingRow.key &&
            uploadedRow.operatingChain === existingRow.operatingChain.value &&
            uploadedRow.month === existingRow.month.value &&
            uploadedRow.settingsKey === existingRow.settingsKey.value &&
            uploadedRow.settingsValue === existingRow.settingsValue.value &&
            uploadedRow.staff === existingRow.staff.value &&
            returnNullOrValue(uploadedRow.keyValue) ===
                returnNullOrValue(existingRow.keyValue.value) &&
            returnNullOrValue(uploadedRow.revGMIndicator) ===
                returnNullOrValue(existingRow.revGMIndicator.value) &&
            uploadedRow.valueType === existingRow.valueType.value
        );
    };

    const addCsvData = (data) => {
        const createItem = (row) => {
            const item = {
                id: uuidv4(),
                selected: false,
                operatingChain: row.items[0],
                key: 'SERVICETYPECODE',
                keyValue: row.items[1].toUpperCase(),
                staff: row.items[2].toUpperCase(),
                settingsKey: row.items[3].toUpperCase(),
                settingsValue: row.items[4],
                valueType: row.items[5].toUpperCase(),
                revGMIndicator: returnNullOrValue(row.items[6].toUpperCase()),
                month: row.items[7],
                value: row.items[8].replace(',', '.'),
            };
            /*item.id = item.id + '#' + 1*/
            return item;
        };
        const input = data.data.map(createItem);

        const existingRows = tableData.map((tr) => {
            const updatedRow = input.find((i) => checkIfRowAlreadyExist(i, tr));
            if (updatedRow) {
                tr.value = updatedRow.value;
            }
            return tr;
        });

        const newRows = input
            .filter(
                (r) =>
                    existingRows.findIndex((ud) =>
                        checkIfRowAlreadyExist(r, ud)
                    ) < 0
            )
            .map(
                createRow(
                    state.ruleTypes,
                    state.keyValueArgs,
                    state.operatingChainOptions,
                    state.recurringParametsOptions
                )
            );
        setTableData([...existingRows, ...newRows]);
    };

    const operatingChainValidator = (row) => {
        const validOpChains = state.operatingChainOptions.map((oc) => oc.value);
        const isValid = validOpChains.indexOf(row[0]) >= 0;
        return {
            isValid: isValid,
            errorMessage: isValid
                ? ''
                : `Invalid operating chain, valid values: ${JSON.stringify(
                      validOpChains
                  )}`,
        };
    };

    const keyValidator = (ruleTypes) => (row) => {
        const key = row[3];
        const validValues = ruleTypes.map((rt) => rt.value);
        const validKey = validValues.indexOf(key) >= 0;
        return {
            isValid: validKey,
            errorMessage: validKey
                ? ''
                : 'Invalid rule type, valid values are: ' +
                  validValues.join(','),
        };
    };

    const valueTypeValidator = (row) => {
        const validValues = valueTypes.options.map((o) => o.value);
        const isValid = validValues.indexOf(row[5]) >= 0;
        return {
            isValid: isValid,
            errorMessage: isValid
                ? ''
                : `Invalid value type, valid values: ${JSON.stringify(
                      validValues
                  )}`,
        };
    };

    const revGMIndicatorValidator = (row) => {
        const newDataValue = [
            { label: 'Rev', value: 'REV' },
            { label: 'GM', value: 'GM' },
            { label: 'Rev incl comm', value: 'REVINC' },
        ];
        columnTypes.revGMIndicator.revGMOptions = newDataValue;
        const validValues = [
            ...columnTypes.revGMIndicator.revGMOptions.map((o) => o.value),
            '',
        ];
        const isValid = validValues.indexOf(row[6]) >= 0;
        return {
            isValid: isValid,
            errorMessage: isValid
                ? ''
                : `Invalid indicator, valid values: ${JSON.stringify(
                      validValues
                  )}`,
        };
    };

    const paymentMonthValidation = (cellIndex) => (row) => {
        const value = row[cellIndex];
        const number = Number(value);
        const isValid = !isNaN(number);
        return {
            isValid: isValid,
            errorMessage: isValid ? '' : 'The value is not a number',
        };
    };

    const operatingChainValueValidation = (cellIndex) => (row) => {
        const value = row[cellIndex].replace(',', '.');
        const number = Number(value);
        const isValid = !isNaN(number);
        return {
            isValid: isValid,
            errorMessage: isValid ? '' : 'The value is not a number',
        };
    };

    const csvRowValidator = async (row) => {
        const validators = [
            operatingChainValidator,
            keyValidator(state.ruleTypes),
            valueTypeValidator,
            revGMIndicatorValidator,
            paymentMonthValidation(7),
            operatingChainValueValidation(8),
        ];
        const validationResults = await Promise.all(
            validators.map((v) => v(row))
        );
        const result = validationResults.reduce(
            (acc, curr) => {
                return {
                    isValid: acc.isValid && curr.isValid,
                    text:
                        curr.errorMessage === ''
                            ? acc.text
                            : [...acc.text, curr.errorMessage],
                };
            },
            { isValid: true, text: [] }
        );
        return {
            isValid: result.isValid,
            text: result.isValid
                ? result.text.filter((y) => y != '')
                : result.text,
        };
    };

    const copyFiscalKey = (fiscalKeyData, operatingChainData) => {
        const chosenOpChains = operatingChainData.map((oc) => oc.value);

        const newChosenObjects = prepareTableData(state.data, fiscalKey); // Table we are copying INTO
        const newCopiedObjects = prepareTableData(
            state.data,
            fiscalKeyData.value
        ); // Data we are copying

        if (!chosenOpChains[0]) {
            state.operatingChainOptions.forEach((opChain) =>
                chosenOpChains.push(opChain.value)
            );
        }
        const copiedObjectsFilteredByOC = newCopiedObjects.filter((el) =>
            chosenOpChains.includes(el.operatingChain)
        );

        const chosenPeriodRows = newChosenObjects.map(
            createRow(
                state.ruleTypes,
                state.keyValueArgs,
                state.operatingChainOptions,
                state.recurringParametsOptions
            )
        );
        const copiedPeriodRows = copiedObjectsFilteredByOC.map(
            createRow(
                state.ruleTypes,
                state.keyValueArgs,
                state.operatingChainOptions,
                state.recurringParametsOptions
            )
        );
        setTableData(prepareTableDataMerge(chosenPeriodRows, copiedPeriodRows));
    };

    return isLoaded ? (
        <Table
            data={rows}
            columns={columns}
            componentName={'RecurringSales'}
            onUpdateData={updateData}
            onRemoveRow={deleteRow}
            onNewRow={newRow}
            onSaveData={saveData}
            onToggleItem={toggleItem}
            onDeleteSelected={deleteSelected}
            onToggleAll={toggleAll}
            showCopyFiscal={true}
            onCopyFrom={copyFiscalKey}
            copyFiscalKey={copyFiscalKey}
            sampleCSV={sampleCSV}
            rowsCSV={rowsCSV}
            addCsvData={addCsvData}
            csvRowValidator={csvRowValidator}
            csvColumns={[
                'Operating Chain',
                'Service Type',
                'Staff Type',
                'Rule Type',
                'Rule value',
                'PERCENTAGE/FIXED',
                'REV/GM',
                'Payment Month',
                'Value',
            ]}
            showDeleteAllBtn={false}
            components={{
                filter: (
                    <Filter
                        filter={filterSpec}
                        onChange={setFilterSpec}
                        operatingChainOptions={state.operatingChainOptions}
                    />
                ),
            }}
        />
    ) : (
        <div className="w-full text-center">
            <Loader />
        </div>
    );
}
