import React, { useEffect, useState } from 'react';
import * as buttons from '@/components/buttons';
import { MultiSelect, Loader } from '@/components';
import { useDependencies } from '@/DependencyProvider';
import { Switch } from '@headlessui/react';
import { toast } from 'react-toastify';

const BonusCalculationModal = ({ onClose }) => {
    const [selectedOpChains, setSelectedOpChains] = useState([]);
    const [operatingChains, setOperatingChains] = useState([]);
    const [enabled, setEnabled] = useState(false);

    const { apiFactory } = useDependencies();

    useEffect(() => {
        const getData = async () => {
            const { bonusSettingsApi } = apiFactory;
            const [operatingChains] = await Promise.all([
                bonusSettingsApi.getOperatingChains(),
            ]);
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            setOperatingChains(operatingChainOptions);
        };
        getData();
    }, [apiFactory]);

    const runCalculation = async (opchains) => {
        const { simulationApi } = apiFactory;

        var formattedOpchains = opchains.map((d) => `'${d.value}'`).join(', ');

        const d = new Date();
        const hour = d.getHours();
        if (hour >= 9 && hour <= 22) {
            const result = await simulationApi.runBonusCalculation({
                opchainCodes: formattedOpchains,
            });
            onClose();
            if (result.status === 200) {
                toast.success('Bonus calculation is executed', {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                });
            } else {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            toast.error('Can only run calculation between 9 am to 22 pm', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const onSwitchChange = (value) => {
        if (value) {
            setEnabled(value);
            setSelectedOpChains([{ label: 'All Chains', value: '%' }]);
        } else {
            setEnabled(value);
            setSelectedOpChains([]);
        }
    };

    const disabledSelect = 'opacity-50 pointer-events-none';

    return (
        <div className="bg-gray-700 p-6 rounded-lg text-center">
            <div className="flex flex-col space-y-2">
                <h2 className="font-bold text-start h-10 text-xl text-blue-500">
                    Run Bonus Calculation
                </h2>
                <div className="flex flex-col">
                    <label
                        htmlFor="all-opchains"
                        className="text-gray-50 font-medium text-left mb-1"
                    >
                        Select All Operating Chains
                    </label>
                    <Switch
                        checked={enabled}
                        onChange={onSwitchChange}
                        className={`${
                            enabled ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex h-8 w-14 items-center rounded-full`}
                    >
                        <span className="sr-only">Enable notifications</span>
                        <span
                            className={`${
                                enabled ? 'translate-x-7' : 'translate-x-1'
                            } inline-block h-5 w-6 transform rounded-full bg-white transition`}
                        />
                    </Switch>
                </div>
                <div className="text-gray-50 py-2 text-left">
                    <label
                        htmlFor="opChains"
                        className="text-gray-50 font-medium"
                    >
                        Select Operating Chains
                    </label>
                    <span
                        className={
                            enabled
                                ? disabledSelect + ' flex flex-col w-full mt-1'
                                : 'flex flex-col w-full mt-1'
                        }
                    >
                        <MultiSelect
                            onChange={(opChains) => {
                                setSelectedOpChains(opChains);
                            }}
                            options={operatingChains}
                            menuPortalTarget={document.body}
                            value={selectedOpChains}
                        />
                    </span>
                </div>

                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Save
                        onSave={() => runCalculation(selectedOpChains)}
                        text="Run"
                        enabled={selectedOpChains.length !== 0}
                    />
                </div>
            </div>
        </div>
    );
};

export default BonusCalculationModal;
