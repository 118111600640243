import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MultiSelect, ErrorMsg, buttons } from '@/components';
import { useDependencies } from '@/DependencyProvider';
import { RenderContext } from '@/renderContext';
import { saveSimulationId } from '@/utils';

const CopySimulation = ({
    periods,
    setPageLoading,
    updateSimulationList,
    onClose,
    simulation,
}) => {
    const { apiFactory } = useDependencies();
    const { simulationApi } = apiFactory;
    const { setValue } = useContext(RenderContext);
    const [simulationName, setSimulationName] = useState('');
    const [operatingChains, setOperatingChains] = useState([]);
    const [selectedPeriods, setSelectedPeriods] = useState([]);
    const [selectedOpchains, setSelectedOpChains] = useState([]);
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    useEffect(() => {
        const getData = async () => {
            const { bonusSettingsApi } = apiFactory;
            const operatingChains =
                await bonusSettingsApi.getOperatingChainsWithSimId(
                    simulation.simulationId
                );
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            setOperatingChains(operatingChainOptions);
        };

        if (simulation?.simulationId) getData();

        setSimulationName(simulation.name);
        setSelectedPeriods(
            simulation.periods.reduce(
                (acc, period) => [...acc, { label: period, value: period }],
                []
            )
        );
        setSelectedOpChains(
            simulation.opchainCodes?.reduce(
                (acc, opchain) => [...acc, { label: opchain, value: opchain }],
                []
            ) || []
        );
    }, [simulation, apiFactory]);

    const createSimulation = async (event) => {
        event.preventDefault();
        if (
            simulationName &&
            selectedPeriods.length > 0 &&
            selectedOpchains.length > 0
        ) {
            const id = uuidv4();
            localStorage.setItem('simulationName', simulationName);
            const simulationData = {
                simulationId: id,
                name: simulationName,
                periods:
                    selectedPeriods.length > 0
                        ? selectedPeriods.map((period) => period.value)
                        : [],
                opchainCodes: selectedOpchains
                    ? selectedOpchains.map((op) => op.value)
                    : [],
                copyFromSimulationId: simulation.simulationId,
            };
            setPageLoading(true);
            await simulationApi.copySimulation(simulationData);
            saveSimulationId(id);
            updateSimulationList();
            setValue((value) => value + 1);
        } else {
            setShowErrorMsg(true);
        }
    };

    return (
        <div className="p-6 bg-gray-700 rounded-lg">
            <form className="flex flex-col w-full">
                <h3 className="h-10 mb-2 text-xl font-bold text-center text-gray-50">
                    Copy simulation
                </h3>
                <div className="flex flex-col w-full gap-4">
                    <div className="flex flex-col gap-4 sm:items-start">
                        <label
                            htmlFor="simulation-name"
                            className="font-medium text-gray-50"
                        >
                            Simulation name
                        </label>
                        <span className="flex flex-col w-full">
                            <input
                                type="text"
                                name="simulation-name"
                                id="simulation-name"
                                value={simulationName}
                                onChange={(e) =>
                                    setSimulationName(e.target.value)
                                }
                                className="w-full h-full p-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm grow h-38px"
                            />
                            <ErrorMsg
                                condition={showErrorMsg && !simulationName}
                                errorMsg="This field is required"
                            />
                        </span>
                    </div>
                    <div className="flex flex-col gap-4 sm:items-start">
                        <label
                            htmlFor="periods"
                            className="font-medium text-gray-50"
                        >
                            Period
                        </label>
                        <span className="flex flex-col w-full">
                            <MultiSelect
                                name="periods"
                                onChange={(periods) =>
                                    setSelectedPeriods(periods)
                                }
                                options={periods}
                                menuPortalTarget={document.body}
                                value={selectedPeriods}
                            />
                            <ErrorMsg
                                condition={
                                    showErrorMsg && selectedPeriods.length === 0
                                }
                                errorMsg="This field is required"
                            />
                        </span>
                    </div>
                    <div className="flex flex-col gap-4 sm:items-start">
                        <label
                            htmlFor="opchains"
                            className="font-medium text-gray-50"
                        >
                            Operating chains
                        </label>
                        <span className="flex flex-col w-full">
                            <MultiSelect
                                name="opchains"
                                onChange={(opChains) =>
                                    setSelectedOpChains(opChains)
                                }
                                options={operatingChains || []}
                                menuPortalTarget={document.body}
                                value={selectedOpchains}
                            />
                            <ErrorMsg
                                condition={
                                    showErrorMsg &&
                                    selectedOpchains?.length === 0
                                }
                                errorMsg="This field is required"
                            />
                        </span>
                    </div>
                </div>
                <div className="pt-5">
                    <div className="flex justify-end">
                        <buttons.Cancel
                            text="Cancel"
                            onCancel={onClose}
                            className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-gray-200 bg-blue-500 border border-transparent rounded-md shadow-sm hover:text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        />
                        <buttons.Button
                            text="Create"
                            onClick={createSimulation}
                            className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-gray-200 bg-blue-500 border border-transparent rounded-md shadow-sm hover:text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CopySimulation;
