export const optionValidator = (errorMessage, options, index) => (row) => {
    const value = row[index];
    const isValid = options.map((y) => y.value).indexOf(value) >= 0;
    return {
        isValid: isValid,
        text: isValid ? '' : errorMessage,
    };
};

export const ruleTypeExistValidator = (tableData) => async (row) => {
    const ruleTypeExists =
        tableData.findIndex(
            (tr) =>
                tr.key.value === row[1] && tr.operatingChain.value === row[0]
        ) >= 0;
    return {
        isValid: true,
        text: ruleTypeExists
            ? 'Rule type and operating chain already exists - will update'
            : '',
    };
};

export const returnNullOrValue = (str) => {
    return !str || str.length === 0 ? null : str;
};

export const validateIfRecruitmentRowAlreadyExist =
    (tableData) => async (row) => {
        const rowExists =
            tableData.findIndex((existingRow) => {
                return (
                    row[0] === existingRow.operatingChain.value &&
                    row[1].toUpperCase() ===
                        existingRow.staff.value.toUpperCase()
                );
            }) >= 0;
        return {
            isValid: true,
            text: rowExists ? 'The row already exists - will update value' : '',
        };
    };

export const validateIfRowAlreadyExist = (tableData) => async (row) => {
    const rowExists =
        tableData.findIndex((existingRow) => {
            return (
                row[0] === existingRow.operatingChain.value &&
                row[1].toUpperCase() === existingRow.key.value &&
                returnNullOrValue(row[2].toUpperCase()) ===
                    returnNullOrValue(existingRow.keyValue.value) &&
                returnNullOrValue(row[4].toUpperCase()) ===
                    returnNullOrValue(existingRow.revGMIndicator.value) &&
                row[3].toUpperCase() === existingRow.valueType.value
            );
        }) >= 0;
    return {
        isValid: true,
        text: rowExists ? 'The row already exists - will update value' : '',
    };
};

export const composeValidators = (validators) => async (row) => {
    const validationResults = await Promise.all(validators.map((v) => v(row)));
    const validationResult = validationResults.reduce(
        (acc, curr) => {
            return {
                isValid: acc.isValid && curr.isValid,
                text: curr.text ? [...acc.text, curr.text] : acc.text,
            };
        },
        { isValid: true, text: [] }
    );
    return validationResult;
};
