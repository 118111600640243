import React, { useState, useEffect, useCallback } from 'react';
import { useDependencies } from '../DependencyProvider';
import CompetitionList from './competition/CompetitionList';
import CreateCompetition from './competition/CreateCompetition';
import ShowCompetitionDetails from './competition/ShowCompetitionDetails';
import availableParameters from './CompetitionParameters';
import Loader from '../components/Loader';
import Modal from 'react-modal';

const Competition = () => {
    const { apiFactory } = useDependencies();
    const [periods, setPeriods] = useState([]);
    const [operatingChains, setOperatingChains] = useState([]);
    const [competitionTable, setCompetitionTable] = useState([]);
    const [competitionsData, setCompetitionsData] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [competitionPageLoading, setCompetitionPageLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showCreateCompetition, setShowCreateCompetition] = useState(false);
    const [showCompetitionDetails, setShowCompetitionDetails] = useState(false);
    const [chosenCompetitionId, setChosenCompetitionId] = useState({});
    const [updateCompetitionTable, setUpdateCompetitionTable] = useState(false);
    const [validPeriodOptions, setValidPeriodOptions] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState();

    const updateTable = useCallback(async () => {
        setIsUpdating(true);
        const { competitionsApi } = apiFactory;
        const competitionList = await competitionsApi.getCompetitions(true);

        const newCompetitionTable = [];
        const filteredCompetitionData = competitionList.allCompetitions.map(
            (comp) => {
                const participants = competitionList.allParticipants.filter(
                    (part) => part.competitionId === comp.id
                );
                const numOfAccepted = participants.filter(
                    (part) => part.acceptedAt !== null
                ).length;
                const data = {
                    ...comp,
                    participants: participants,
                    numOfAccepted: numOfAccepted,
                    numOfInvites: participants.length,
                };
                newCompetitionTable.push({
                    ...comp,
                    participants: participants.map((part) => part.toUser),
                    numOfAccepted: numOfAccepted,
                    numOfInvites: participants.length,
                    selected: false,
                });
                return data;
            }
        );

        // Sorts competitions by descending createdBy date
        const sortedCompetitionTable = newCompetitionTable.sort(
            (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
        );

        setCompetitionTable(sortedCompetitionTable);
        setCompetitionsData(filteredCompetitionData);
        setUpdateCompetitionTable(false);
        setIsUpdating(false);
    }, [apiFactory]);

    useEffect(() => {
        if (updateCompetitionTable) {
            updateTable();
        }
    }, [updateTable, updateCompetitionTable]);

    useEffect(() => {
        const getData = async () => {
            setCompetitionPageLoading(true);
            const { masterdataApi, bonusSettingsApi, competitionsApi } =
                apiFactory;
            const [periodOptions, operatingChains, competitionList] =
                await Promise.all([
                    masterdataApi.getBonusPeriods(),
                    bonusSettingsApi.getOperatingChains(),
                    competitionsApi.getCompetitions(true),
                ]);
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));

            const newCompetitionTable = [];
            const filteredCompetitionsData =
                competitionList.allCompetitions.map((comp) => {
                    const participants = competitionList.allParticipants.filter(
                        (part) => part.competitionId === comp.id
                    );
                    const numOfAccepted = participants.filter(
                        (part) => part.acceptedAt !== null
                    ).length;
                    const data = {
                        ...comp,
                        participants: participants,
                        numOfAccepted: numOfAccepted,
                        numOfInvites: participants.length,
                    };
                    newCompetitionTable.push({
                        ...comp,
                        participants: participants.map((part) => part.toUser),
                        numOfAccepted: numOfAccepted,
                        numOfInvites: participants.length,
                        selected: false,
                    });
                    return data;
                });

            // Sorts competitions by descending createdBy date
            const sortedCompetitionTable = newCompetitionTable.sort(
                (a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
            );
            setPeriods(periodOptions);
            setOperatingChains(operatingChainOptions);
            setParameters(availableParameters);
            setCompetitionTable(sortedCompetitionTable);
            setCompetitionsData(filteredCompetitionsData);
            setCompetitionPageLoading(false);
        };
        getData();
    }, [apiFactory]);

    useEffect(() => {
        if (periods.length > 0) {
            const periodsList = periods.flatMap((y) =>
                y.options.map((x) => x.period)
            );

            const today = new Date();
            const currPeriod = periodsList.filter(
                (p) =>
                    today >= new Date(p.startDate) &&
                    today <= new Date(p.endDate)
            )[0];
            setCurrentPeriod(currPeriod);
            const currentEndDate = new Date(currPeriod.endDate);

            const firstDateOfNextPeriod = new Date(
                currentEndDate.setDate(currentEndDate.getDate() + 1)
            );
            const nextPeriod = periodsList.filter(
                (p) =>
                    firstDateOfNextPeriod >= new Date(p.startDate) &&
                    firstDateOfNextPeriod <= new Date(p.endDate)
            )[0];

            const validPeriods = [currPeriod, nextPeriod];

            const validPOptions = validPeriods.map((period) => {
                return {
                    label: period.periodId,
                    value: period.periodId,
                    startDate: period.startDate.slice(0, 10),
                    endDate: period.endDate.slice(0, 10),
                };
            });

            setValidPeriodOptions(validPOptions);
        }
    }, [periods]);

    const setPageLoading = async (value) => {
        setCompetitionPageLoading(value);
    };

    const updateCompetitionList = async () => {
        setUpdateCompetitionTable(true);
    };

    const openCompetitionDetailsModal = async (row) => {
        setShowCompetitionDetails(true);
        setChosenCompetitionId(row.original.id);
    };

    const handleShowCreateCompetition = () => {
        setShowCreateCompetition(true);
    };

    return (
        <>
            {!competitionPageLoading ? (
                <div className="flex flex-col w-full items-center">
                    <div className="flex flex-col justify-start w-11/12">
                        <CompetitionList
                            competitionList={competitionTable}
                            setPageLoading={setPageLoading}
                            openCompetitionDetailsModal={
                                openCompetitionDetailsModal
                            }
                            updateCompetitionList={updateCompetitionList}
                            showCreateCompetition={handleShowCreateCompetition}
                        />
                    </div>
                    <Modal
                        isOpen={showCreateCompetition}
                        className="w-8/12 h-4/5 rounded-lg"
                        overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                        onRequestClose={(e) => {
                            e.stopPropagation();
                            setShowCreateCompetition(false);
                        }}
                        shouldCloseOnOverlayClick={true}
                    >
                        <CreateCompetition
                            periods={periods}
                            operatingChains={operatingChains}
                            updateCompetitionList={updateCompetitionList}
                            setPageLoading={setPageLoading}
                            setIsUpdating={setIsUpdating}
                            onClose={() => setShowCreateCompetition(false)}
                            parameters={parameters}
                            validPeriodOptions={validPeriodOptions}
                        />
                    </Modal>
                    <Modal
                        isOpen={showCompetitionDetails}
                        className="w-8/12 h-4/6 rounded-lg overflow-y-auto"
                        overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                        onRequestClose={(e) => {
                            e.stopPropagation();
                            setShowCompetitionDetails(false);
                        }}
                        shouldCloseOnOverlayClick={true}
                    >
                        <ShowCompetitionDetails
                            chosenCompetitionId={chosenCompetitionId}
                            allCompetitions={competitionsData}
                            onClose={() => {
                                setShowCompetitionDetails(false);
                                setChosenCompetitionId(null);
                            }}
                            currentPeriod={currentPeriod}
                            updateCompetitionList={updateCompetitionList}
                            setIsSaving={(value) => setIsSaving(value)}
                            validPeriodOptions={validPeriodOptions}
                        />
                    </Modal>
                    <Modal
                        isOpen={isUpdating || isSaving}
                        className="w-8/12 h-auto rounded-lg focus:outline-none"
                        overlayClassName="flex justify-center items-center fixed top-0 right-0 left-60 bottom-0 bg-gray-900 bg-opacity-80"
                    >
                        (<Loader text={isSaving ? 'Saving' : 'Updating'} />)
                    </Modal>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default Competition;
